import {useEffect, useState} from "react";
import flattenObject from "../hx/FlattenObj";
import axios from "axios";
import {getAllASN} from "../../Apis/Asn_apis";
import ls from "localstorage-slim";
import BaseTable from "./BaseTable";
import { Descriptions, Tag, Divider, List } from 'antd';

const ASN = ({PO_Number}) => {
    const [flattenedData, setFlattenedData] = useState([]);
    const [getDebitNoteList, setDebitNoteList] = useState([]);

    useEffect(() => {
        axios.get(getAllASN + encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            console.log(res, "oneasdjfhakjshdfja")
            // const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }, [PO_Number]);
    const humanReadableKeys = {
        "ASN_Number": "ASN Number",
        "deliveryScheduleId": "Delivery Schedule ID",
        "eWayBillNo": "eWay Bill Number",
        "vehicleNumber": "Vehicle Number",
        "expectedDateOfArrival": "Expected Date of Arrival",
        "vendorDetails.contactperson": "Vendor Contact Person",
        "vendorDetails._id": "Vendor Details ID",
        "shippingDetails.carrier": "Shipping Carrier",
        "shippingDetails.shippingAddress": "Shipping Address",
        "shippingDetails._id": "Shipping Details ID",
        "GRNstatus": "GRN Status"
    };

    return(
        <>
            <h3>ASN</h3>
            <div>
                {getDebitNoteList.map((item) => (
                    <div key={item._id} style={{marginBottom: '20px'}}>
                        <Descriptions title={"ASN No. "+item.ASN_Number} bordered>
                            <Descriptions.Item label="LOT/ITEM ID">
                                <List
                                    size="small"
                                    bordered
                                    dataSource={item.dataScheduleId}
                                    renderItem={(id) => <List.Item>{id}</List.Item>}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Delivery ScheduleID">
                                {item.deliveryScheduleId}
                            </Descriptions.Item>
                            <Descriptions.Item label="eWayBill No">{item.eWayBillNo}</Descriptions.Item>
                            <Descriptions.Item label="Vehicle Number">{item.vehicleNumber}</Descriptions.Item>
                            <Descriptions.Item
                                label="Expected Date of Arrival">{item.expectedDateOfArrival}</Descriptions.Item>
                            <Descriptions.Item label="GRN Status">
                                <Tag color={item.GRNstatus === 'Completed' ? 'green' : 'red'}>
                                    {item.GRNstatus}
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipping Details">
                                <p>Carrier: {item.shippingDetails.carrier}</p>
                                <p>Shipping Address: {item.shippingDetails.shippingAddress}</p>
                            </Descriptions.Item>
                            <Descriptions.Item label="Document Attachments">
                                <List
                                    size="small"
                                    bordered
                                    dataSource={item.documentAttachments}
                                    renderItem={(attachment) => <List.Item>{attachment}</List.Item>}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider/>
                    </div>
                ))}
            </div>
            {/*{ flattenedData.length > 0 && <BaseTable data={flattenedData} title={"ASN"}/> }*/}
        </>
    )
}

export default ASN;


import { createSlice } from "@reduxjs/toolkit";

export const statusSlice = createSlice({
  name: "allStatus",
  initialState: {
    viewDraw: false,
    editDraw: false,
    POViewId: "",
    editPoItems: false,
    editPoIndex:"",
  },
  reducers: {
    addViewDrawerStatus: (state, action) => {
      state.viewDraw = action.payload;
    },
    addEditDrawerStatus: (state, action) => {
      state.editDraw = action.payload;
    },
    addPOViewId: (state, action) => {
      state.POViewId = action.payload;
    },
    addPOItemEditStatus: (state, action) => {
      state.editPoItems = action.payload;
    },
    addEditPoIndex: (state, action) => {
      state.editPoIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addViewDrawerStatus, addPOViewId, addEditDrawerStatus,addPOItemEditStatus,addEditPoIndex } =
  statusSlice.actions;

export default statusSlice.reducer;

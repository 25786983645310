import React, { useEffect, useState } from 'react';
import { Table, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import axios from 'axios';
import { AUTH_TOKEN } from '../../utils/authHeader';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

const ListDebitNote = () => {
  const [listData, setListData] = useState([]);
  const [loadSet, setLoadSet] = useState(false);
  const [debitNoteData, setDebitNoteData] = useState([]);

  const columns = [
    {
      title: 'Debit Note Number',
      dataIndex: 'debitNoteNumber',
      key: 'debitNoteNumber',
    },
    {
      title: 'GRN Number',
      dataIndex: 'GRN_Number',
      key: 'GRN_Number',
    },
    {
      title: 'PO Number',
      dataIndex: 'PO_Number',
      key: 'PO_Number',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      key: 'contactPerson',
    },
  ];

  useEffect(() => {
    axios
      .get(
        'https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders',
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN()}`,
          },
        }
      )
      .then((res) => {
        console.log('GET:ALL-P-Order', res);
        setListData(res.data.data);
      })
      .catch((err) => {
        console.error('GET:ALL-P-Order', err);
      });
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      setLoadSet(true);
      const poNumbers = listData.map((po) => po.PO_Number);
      console.log("poNumbers Debit", poNumbers)

      const promises = poNumbers.map((poNumber) =>
        axios.get(
          `https://shf5w46ojd.execute-api.ap-south-1.amazonaws.com/dev/api/debitNote/viewAllDebitNotesList/${encodeURIComponent(poNumber)}`,
          {
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN()}`,
            },
          }
        )
      );
      console.log(promises, "promises Debit")

      Promise.allSettled(promises)
        .then((results) => {
          const fulfilledResponses = results
            .filter((result) => result.status === 'fulfilled')
            .map((result) => result.value.data);

          const debitNoteData = fulfilledResponses.flat();

          console.log('Debit Note Data:', debitNoteData);
          setDebitNoteData(debitNoteData);
        })
        .catch((error) => {
          console.log('Error fetching credit notes', error);
          setDebitNoteData([]);
        })
        .finally(() => {
          setLoadSet(false);
        });
    }
  }, [listData]);

  return (
    <>
      <main style={{ backgroundColor: '#80' }}>
        {loadSet && <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          width: "100%",
          zIndex: 100,
          background: "#00000099"
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>}
        <div className="flex" style={{ backgroundColor: '#80' }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: '80%', backgroundColor: '#80' }}
          >
            <Navbar title={'Debit Note / View All Debit Notes'} />

            <section
              className="lists_table"
              style={{
                background:
                  'white',
                boxShadow:
                  'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                height: '80vh',
                width: '98%',
                margin: '1rem auto',
                padding: '1rem',
              }}
            >
              <h4 style={{ textAlign: 'center' }}> Debit Note </h4>
              <Divider />
              <Table dataSource={debitNoteData} columns={columns} />
            </section>
          </section>
        </div>
      </main>
    </>
  );
};

export default ListDebitNote;

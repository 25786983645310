import { createSlice } from "@reduxjs/toolkit";

export const editItemsData = createSlice({
  name: "editItemsData",
  initialState: {
    itemsDatas:[],
    deleteStatus:false,
    specificItem:"",
  },
  reducers: {
    addAccountAssignmentCategory: (state, action) => {
      state.itemsDatas = action.payload;
    },
    addItemInArray: (state, action) => {
      // if(state.itemsDatas?.length!=0){
        state.itemsDatas.push(action.payload);
      // }
    },
    deleteItemInArray: (state, action) => {
      if(state.itemsDatas?.length!=0){
        state.itemsDatas.splice(action.payload,1);
        state.deleteStatus=true;
      }
    },
    editChange: (state, action) => {
      if(state.itemsDatas?.length!=0){
        state.itemsDatas[action.payload.editPoIndex] = action.payload.data;
      }
    },
    addSpecific: (state, action) => {
      state.specificItem=action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addAccountAssignmentCategory,addSpecific,addItemInArray,deleteItemInArray,editChange } =
editItemsData.actions;

export default editItemsData.reducer;

import React, {useEffect, useState} from "react";
import ls from "localstorage-slim";
import axios from "axios";
import {Table} from "antd";
import { getAllCreditNote } from "../../Apis/CreditNote_apis";

const ViewCreditNote = ({PO_Number}) => {
    const [getDebitNoteList, setDebitNoteList] = useState([]);

    useEffect(() => {
        axios.get(getAllCreditNote+encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", {decrypt: true}),
            },
        }).then(res => {
            console.log("res2debit", res);
            setDebitNoteList(res.data);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }, [PO_Number])

    

    return(
        <>
            {PO_Number}
                <div key={"CREDNOTE"}>
                    <Table
                        style={{ marginTop: "1rem" }}
                        dataSource={getDebitNoteList}
                        columns={columns}
                    />
                </div>
        </>
    )
}

const columns = [
    {
        title: 'creditNote Number',
        dataIndex: 'creditNoteNumber',
        key: 'creditNoteNumber',
    },
    {
        title: 'ASN_Number',
        dataIndex: 'ASN_Number',
        key: 'ASN_Number',
    },
    {
        title: 'Issue Date',
        dataIndex: 'issueDate',
        key: 'issueDate',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Contact Person',
        dataIndex: 'contactperson',
        key: 'contactperson',
        render: (text, record) => record.contactPerson?.contactperson,
    }
];


export default ViewCreditNote;
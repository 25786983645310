import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import axios from "axios";
import { Select, Input, Button, DatePicker, notification, Form, Radio, Card, Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import ls from "localstorage-slim";
import { createRawDS } from "../../Apis/DS_apis";
import { getElement } from "bootstrap/js/src/util";
import { Divider } from 'antd';

function RawMaterialDS() {
  const [listData, setListData] = useState([]);
  const [poList, setPoList] = useState([]);
  const [poNumber, setPoNumber] = useState("");
  const [totalItems, setTotalItems] = useState([]);
  const [lotwise, setLotWise] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [endDSDetails, setEndDSDetails] = useState([]);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [selectedItemQuatity, setSIQ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    PO_Number: "",
    fulfillmentType: "lotwise", // Default to "lotwise"
    fulfillment: {
      lotwise: [{ item: "", description: "", quantity: "", scheduledDeliveryDate: null, lot: "", lot_number_from: "", lot_number_end: "", lot_code: "", packing_size: "", price: "", amount: "" }],
      itemwise: [{ item: "", itemNumber: "", quantity: "", scheduledDeliveryDate: null, description: "", unitPrice: "", total: "" }],
    },
  });
  useEffect(() => {
    const allFields = form.getFieldsValue();
    console.log('All Form fields and values:', allFields);
  }, [formData])

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description
    });
  };
  //done/////////////////
  const location = useLocation();

  useEffect(() => {
    console.log(location, "location");
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      setFormData(prevState => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
      setDisabled(true)
      const element = document.getElementById('po-list');
      console.log(element)
      if (element) {
        element.disabled = true;
      }
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization:
              "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map((po) => ({
            value: po.PO_Number,
            label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
          }));
          setPoList(dropdownData);
        }
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      });
  }, []);

  useEffect(() => {
    const itemList = listData?.filter((data) => {
      console.log("raw data", data);
      console.log("raw data po", formData.PO_Number, data.PO_Number);
      return formData.PO_Number == data.PO_Number ? data : "";
    });

    setItemsList(itemList);
    console.log(itemList, "itemList");

    const datas = itemList?.map((asd) => {
      console.log("asd:", asd);

      const mappedRawMaterial = asd.rawmaterial?.rawItems?.map((rawItem) => {
        console.log("rawMaterial:", rawItem);
        return {
          label: rawItem?.itemName,
          quantity: rawItem?.itemQuantity,
          value: rawItem?.itemName,
        };
      });

      return mappedRawMaterial;
    });

    setItemsList(datas[0]);
  }, [formData.PO_Number, poList]);


  const sendRawMaterial = async () => {
    try {
      setLoading(true);
      let sendData = {
        "PO_Number": formData.PO_Number,
        "rawmaterial": [],
        "certificate": "CertificateXYZ"
      };

      if (formData.fulfillmentType === 'lotwise') {
        for (let i = 0; i < formData.fulfillment.lotwise.length; i++) {
          let item = formData.fulfillment.lotwise[i];
          sendData.rawmaterial.push({
            "item": item.item,
            "scheduledDeliveryDate": item.scheduledDeliveryDate,
            "fulfillment": {
              "lotwise": [
                {
                  "lot_number_from": item.lot_number_from,
                  "lot_number_end": item.lot_number_end,
                  "lot_code": item.lot_code,
                  "packing": item.packing_size,
                  "packing_size": item.packing_size,
                  "delivery_date": item.scheduledDeliveryDate,
                  "quantity": item.quantity,
                  "price": item.price,
                  "description": item.description,
                  "itemName": item.item,
                }
              ]
            }
          });
        }
      } else if (formData.fulfillmentType === 'itemwise') {
        for (let i = 0; i < formData.fulfillment.itemwise.length; i++) {
          let item = formData.fulfillment.itemwise[i];
          sendData.rawmaterial.push({
            "item": item.item,
            "scheduledDeliveryDate": item.scheduledDeliveryDate,
            "fulfillment": {
              "itemwise": [
                {
                  "itemNumber": item.itemNumber,
                  "itemName": item.item,
                  "delivery_date": item.scheduledDeliveryDate,
                  "price": item.unitPrice,
                  "quantity": item.quantity,
                  "description": item.description
                }
              ]
            }
          });
        }
      }

      console.log("send data", sendData);
      console.log("Form Data raw:", formData);


      const response = await axios.post(createRawDS, sendData, {
        headers: {
          Authorization: "Bearer " + ls.get("token", { decrypt: true }),
        }
      }).then(res => {
        console.log(res)
        openNotificationWithIcon("success", "SUBMITTED Successfully", "RAW deliver schedule saved in database for PO: " + res.data.PO_Number)
        // Reset form fields after successful submission
        form.resetFields();
        setFormData({
          PO_Number: "",
          fulfillmentType: "lotwise", // Default to "lotwise"
          fulfillment: {
            lotwise: [{ item: "", description: "", quantity: "", scheduledDeliveryDate: null, lot: "", lot_number_from: "", lot_number_end: "", lot_code: "", packing_size: "", price: "", amount: "" }],
            itemwise: [{ item: "", itemNumber: "", quantity: "", scheduledDeliveryDate: null, description: "", unitPrice: "", total: "" }],
          },
        });
      })
    }
    // Log the form data for testing purposes
    catch (err) {
      console.error(err);
      openNotificationWithIcon("error", "could not submit form", "could not RAW Deliveryschedule IN DATABASE")
      return {};
 

    }
    finally {
      setLoading(false); // Set loading back to false after API call completes
    }

  };

  const handlePOSelection = async (selectedPO, index) => {
    const headers = {
      Authorization: "Bearer " + ls.get("token", { decrypt: true }),
    };

    try {
      // Fetch details for the selected purchase order
      console.log('Fetching purchase order details...');
      const purchaseOrderResponse = await fetch(
        `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders`,
        { headers }
      );

      if (purchaseOrderResponse.ok) {
        // Purchase order details fetched successfully
        const purchaseOrderDetails = await purchaseOrderResponse.json();
        console.log('Purchase Order details:', purchaseOrderDetails);

        // Check if purchaseOrderDetails.data is an array
        if (Array.isArray(purchaseOrderDetails.data)) {
          // Fetch end delivery schedule details for the selected purchase order
          console.log('Fetching end delivery schedule details...');
          const endDSResponse = await fetch(
            `https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/getByPO/${selectedPO}`,
            { headers }
          );


          if (endDSResponse.ok) {
            // End delivery schedule details fetched successfully
            const endDSDetails = await endDSResponse.json();
            console.log('End Delivery Schedule details:', endDSDetails, endDSDetails.length);


            // Log purchase order items for reference
            console.log('Purchase Order Items details:', purchaseOrderDetails.data);
            if (endDSDetails.length === 0) {
              // No end delivery schedule details, handle separately
              const matchingPOItem = purchaseOrderDetails.data.find(poItem => poItem.PO_Number === selectedPO);
              console.log("matchingPOItem endDSDetails.length === 0", matchingPOItem);
              if (matchingPOItem && Array.isArray(matchingPOItem.items)) {
                console.log('Matching PO Items details endDSDetails.length === 0:', matchingPOItem.items);
                const selectedItem = formData.fulfillment[formData.fulfillmentType][index];
                console.log("selectedItem endDSDetails.length === 0", selectedItem.item, formData.fulfillmentType, index);
                if (!selectedItem) {
                  console.error('Error: selectedItem is undefined. Cannot proceed.endDSDetails.length === 0)');
                  return;
                }

                const selectedPOItem = matchingPOItem.rawmaterial.rawItems.find(poItem => poItem.itemName === selectedItem.item);
                console.log("selectedPOItem endDSDetails.length === 0", selectedPOItem);
                if (!selectedPOItem) {
                  console.error('Error: Selected item not found in the purchase order details.endDSDetails.length === 0');
                  return;
                }
                console.log("selectedItem endDSDetails.length === 0", selectedItem, formData.fulfillmentType, index);
                console.log("matchingPOItem endDSDetails.length === 0", matchingPOItem);
                console.log('Matching PO Items details endDSDetails.length === 0:', matchingPOItem.items);


                const totalQuantityInPO = selectedPOItem.itemQuantity;
                console.log("totalQuantityInPO endDSDetails.length === 0", totalQuantityInPO);
                const remainingQuantity = totalQuantityInPO;
                console.log("remainingQuantity endDSDetails.length === 0", remainingQuantity)
                setRemainingQuantity(remainingQuantity);

              } else {
                console.error('Error: Purchase order items or quantity not found in the response details.endDSDetails.length === 0');
                return;
              }
            }

            // Calculate remaining quantity for each item in the purchase order
            const updatedEndDSDetails = endDSDetails.map((endDSItem, index) => { // <-- index passed here
              const matchingPOItem = purchaseOrderDetails.data.find(poItem => poItem.PO_Number === selectedPO);

              // Log details for each iteration
              console.log('End DS Item details:', endDSItem);
              console.log('Matching PO Item details:', matchingPOItem);

              if (matchingPOItem && Array.isArray(matchingPOItem.items)) {
                console.log('Matching PO Items details:', matchingPOItem.items);

                // Find the selected item from the form (you need to adjust this based on your data structure)
                const selectedItem = formData.fulfillment[formData.fulfillmentType][index].item;
                console.log("selectedItem detail:", selectedItem)
                // Check if selectedItem is present in the purchase order items
                const selectedPOItem = matchingPOItem.rawmaterial.rawItems.find(poItem => poItem.itemName === selectedItem);
                console.log("selectedPOItem detail:", selectedPOItem)
                if (!selectedPOItem) {
                  console.error('Error: Selected item not found in the purchase order details.');
                  return endDSItem;
                }

                // Calculate totalQuantityInPO and remainingQuantity for the selected item separately
                const totalQuantityInPO = selectedPOItem.itemQuantity;
                console.log("totalQuantityInPO detail:", totalQuantityInPO)
                if (endDSDetails.length === 0) {
                  // No end delivery schedule details, set remainingQuantity to totalQuantityInPO
                  const remainingQuantity = totalQuantityInPO;
                  setRemainingQuantity(remainingQuantity);
                  return endDSItem; // Prevent further processing
                }

                //   return endDSItem; // Prevent further processing
                // }

                // Calculate totalEndDSQuantity for the selected item
                const totalEndDSQuantity = endDSDetails.reduce((total, endDSItem) => {
                  const itemTotal = endDSItem.rawmaterial.reduce((totalItems, currentItem) => {
                    if (currentItem.item === selectedItem) { // Check if the item matches the selected item
                      const fulfillmentItemwiseQuantity = currentItem.fulfillment?.itemwise?.reduce((total, item) => total + item.quantity, 0) || 0;
                      const lotwiseQuantity = currentItem.fulfillment?.lotwise?.reduce((total, item) => total + item.quantity, 0) || 0;
                      const itemTotal = fulfillmentItemwiseQuantity + lotwiseQuantity;
                      console.log(`End DS Item ${currentItem.item} Quantity details:`, itemTotal);
                      return totalItems + itemTotal;
                    }
                    return totalItems;
                  }, 0);
                  console.log(`Total endDS Quantity for ${selectedItem} details:`, itemTotal);
                  return total + itemTotal;
                }, 0);


                console.log(`Total endDS Quantity for detail${endDSItem.PO_Number} details:`, totalEndDSQuantity);

                const remainingQuantity = totalQuantityInPO - totalEndDSQuantity;
                console.log("Remaining Quantity details:", remainingQuantity);

                // Set remainingQuantity in the component state for the selected item
                setRemainingQuantity(remainingQuantity);

                const enteredValue = form.getFieldValue(`quantity${index}`);
                if (enteredValue > remainingQuantity) {
                  // Display an alert and prevent further processing
                  alert(`Please enter a value less than or equal to the remaining quantity: ${remainingQuantity}`);
                  form.setFieldValue(`quantity${index}`, 0);
                  return endDSItem; // Prevent further processing
                }

                // Update the input field value for the selected item
                return { ...endDSItem, quantity: remainingQuantity > 0 ? remainingQuantity : 0 };
              } else {
                // Log an error if items or quantity are undefined
                console.error('Error: Purchase order items or quantity not found in the response details.');
              }
              return endDSItem;
            });

            // Update state with the updated end delivery schedule details
            console.log('Updated End Delivery Schedule details:', updatedEndDSDetails);
            setEndDSDetails(updatedEndDSDetails);
          } else {
            // Handle error response for end delivery schedule
            console.error('Error fetching end delivery schedule details. Status:', endDSResponse.status);
            // Additional error handling logic if needed
          }
        } else {
          // Handle the case where purchaseOrderDetails.data is not an array
          console.error('Error: Purchase order details are not in the expected format.');
        }
      } else {
        // Handle error response for purchase order
        console.error('Error fetching purchase order details. Status:', purchaseOrderResponse.status);
        // Additional error handling logic if needed
      }
    } catch (error) {
      // Handle fetch error (e.g., network issues)
      console.error('Error fetching data details:', error);
      // Additional error handling logic if needed
    }
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  const handleFulfillmentTypeChange = (e) => {
    // Reset the form fields
    form.resetFields();
    setFormData((prevData) => ({
      ...prevData,
      fulfillmentType: e.target.value,
    }));
  };


  // Function to update fulfillment data with the form field changes
  const handleFulfillmentChange = (index, field, value) => {
    if (value.quantity <= selectedItemQuatity) {
      console.log("why change?", value)
      setFormData((prevData) => {
        const newFulfillment = { ...prevData.fulfillment };
        newFulfillment[field][index] = value;
        return {
          ...prevData,
          fulfillment: newFulfillment,
        };
      });
    } else {
      console.log("nooooo")
      // setValue(`fulfillment.${formData.fulfillmentType}[${index}].${field}`, value);
      // window.alert(`please select under or equal to ${selectedItemQuatity}`)
    }

  };

  // Function to add a new row for lotwise or itemwise
  const handleAddRow = () => {
    setFormData((prevData) => {
      const newFulfillment = { ...prevData.fulfillment };
      newFulfillment[formData.fulfillmentType].push({
        [formData.fulfillmentType === "lotwise" ? "lot" : "item"]: "",
        quantity: "",
        scheduledDeliveryDate: null,
      });
      return {
        ...prevData,
        fulfillment: newFulfillment,
      };
    });
  };

  // Function to remove a row for lotwise or itemwise
  const handleRemoveRow = (index) => {
    setFormData((prevData) => {
      const newFulfillment = { ...prevData.fulfillment };
      newFulfillment[formData.fulfillmentType].splice(index, 1);
      return {
        ...prevData,
        fulfillment: newFulfillment,
      };
    });
  };

  return (
    <>
      {contextHolder}
      <main style={{ backgroundColor: "#80" }}>
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          {/* Wrapper goes here */}
          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >
            <Navbar />

            {/* po lists table goes here */}
            <section
              className="lists_table"
              style={{
                background: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                width: "98%",
                margin: "1rem auto",
                padding: "1rem",
              }}
            >
              {/* dropdown */}
              <h4 style={{ textAlign: 'center' }}>Raw Material Deliverey Schedule</h4>
              <Divider />
              {/* <label htmlFor="po-list" style={{ marginLeft: "1rem" }}>Purchase Order:</label> */}
              <Form.Item htmlFor="po-list" label="Purchase Order">
                {disabled ? (
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    defaultValue="Search Buyer Name"
                    // style={{
                    //   width: 120,
                    //   marginLeft: "1rem",
                    //   marginTop: "1rem"
                    // }}
                    value={formData.PO_Number}
                    disabled
                  />
                ) : (
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    placeholder="Search Buyer Name"
                    // style={{
                    //   width: 200,
                    //   marginLeft: "1rem",
                    //   marginTop: "1rem"
                    // }}
                    value={formData.PO_Number}
                    onChange={(e) => {
                      setFormData((prevState) => ({ ...prevState, PO_Number: e }));
                      handlePOSelection(e);
                    }}
                    options={poList}
                  />
                )}
              </Form.Item>

              {/* end material */}
              <div style={{ width: "90%", margin: "auto", marginTop: "2rem", textAlign: 'center' }}>
                {/* <h1>Raw Material Deliverey Schedule</h1> */}

                {/* Itemwise or Lotwise Fields */}
                <Form.Item label="Fulfillment Type">
                  <Radio.Group
                    value={formData.fulfillmentType}
                    onChange={handleFulfillmentTypeChange}
                  >
                    <Radio.Button value="lotwise">Lotwise</Radio.Button>
                    <Radio.Button value="itemwise">Itemwise</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form
                  form={form}
                  onFinish={sendRawMaterial}
                >
                  {formData.fulfillmentType === "lotwise" ? (

                    <Card
                      bordered={true}
                      style={{ border: "1px solid lightgrey" }}
                    >

                      <Form.Item label="Lotwise">
                        {formData.fulfillment[formData.fulfillmentType].map((lot, index) => (
                          <div key={index}>
                            <Row gutter={16}>
                              {/* Left side (4 columns) */}
                              <Col span={12}>

                                {/* 1 */}
                                <Form.Item
                                  name={`item_${index}`}
                                  rules={[
                                    { required: true, message: 'Please select raw Item Name..!' },
                                  ]}
                                >
                                  <Select
                                    defaultValue={lot.value || undefined}  // Adjust the property based on your data structure
                                    options={itemsList}
                                    onChange={(selectedItem) => {
                                      const selectedValue = selectedItem; // Use the correct property for the unique item identifier
                                      console.log(itemsList, selectedValue, "selevctedValue");
                                      const selectedQuantity = itemsList.find(item => item.value === selectedValue)?.quantity || 0;

                                      console.log("qty", selectedQuantity);
                                      // Update quantity value based on the selected item
                                      setSIQ(selectedQuantity)

                                      handleFulfillmentChange(
                                        index,
                                        formData.fulfillmentType,
                                        {
                                          ...lot,
                                          item: selectedItem, // Adjust the property based on your data structure
                                        }
                                      );
                                      // Call handlePOSelection to set remaining quantity logic
                                      handlePOSelection(formData.PO_Number, index);
                                    }}
                                  />
                                </Form.Item>


                                {/* 3 */}
                                <Form.Item
                                  name={`lot_number_from${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Lot number from..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="text"
                                    placeholder="Lot Number From"
                                    value={lot.lot_number_from}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        lot_number_from: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* 4 */}
                                <Form.Item
                                  name={`lot_number_end${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter lot number end..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="text"
                                    placeholder="Lot Number To"
                                    value={lot.lot_number_end}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        lot_number_end: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* 5 */}


                                {/* Add other left side inputs here */}
                              </Col>

                              {/* Right side (5 columns) */}
                              <Col span={12}>
                                {/* 6 */}
                                <Form.Item
                                  name={`quantity${index}`}
                                  rules={[
                                    { required: true, message: 'Please enter Quantity..!' },
                                  ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="number"
                                    placeholder="Total Quantity"
                                    value={lot.quantity}
                                    maxLength={remainingQuantity}
                                    onChange={(e) => {
                                      console.log(e.target.value, remainingQuantity);
                                      const enteredValue = parseInt(e.target.value, 10);

                                      if (remainingQuantity === 0) {
                                        window.alert(`There is no quantity available for this item..!!`);
                                        // Optionally reset the input field or handle as needed
                                        form.setFieldValue(`quantity${index}`, formData.fulfillment.lotwise[index].quantity)
                                      } else if (enteredValue > remainingQuantity) {
                                        window.alert(`Available Quantity is ${remainingQuantity}..! Please enter a value under or equal to ${remainingQuantity}..!`);
                                        // Optionally reset the input field or handle as needed
                                        // form.setFieldValue(`quantity${index}`, remainingQuantity > 0 ? remainingQuantity : 0);
                                        form.setFieldValue(`quantity${index}`, formData.fulfillment.lotwise[index].quantity)
                                      } else {
                                        console.log("justTest", e);
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            quantity: enteredValue,
                                            amount: enteredValue * lot.price,
                                          }
                                        );
                                      }
                                    }}

                                  />
                                </Form.Item>
                                {/* 7 */}
                                {/* 2 */}
                                <Form.Item
                                  name={`packing_size_${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Packing Size..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="text"
                                    placeholder="Pack/Size"
                                    value={lot.packing_size}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        packing_size: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={`lot_code${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter lot code..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="text"
                                    placeholder="Lot Code/Batch Number"
                                    value={lot.lot_code}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        lot_code: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                  name={`price${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Rate..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="number"
                                    placeholder="Rate"
                                    value={lot.price}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        price: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item> */}
                                {/* <Input
                                  // style={{ marginBottom: "1rem" }}
                                  type="number"
                                  placeholder="Amount"
                                  value={lot.amount}
                                  onChange={(e) =>
                                    handleFulfillmentChange(index, formData.fulfillmentType, {
                                      ...lot,
                                      amount: e.target.value,
                                    })
                                  }
                                /> */}
                                {/* 8 */}
                                <Form.Item
                                  name={`scheduledDeliveryDate${index}`}
                                  rules={[
                                    { required: true, message: 'Please select Delivery Date..!' },
                                  ]}
                                >
                                  <DatePicker

                                    style={{ marginRight: "0.5rem" }}
                                    onChange={(date) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        scheduledDeliveryDate: date.$d.toString(),
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* Add other right side inputs here */}
                              </Col>
                              <Col span={24}>
                                <Form.Item>
                                  <Input
                                    style={{ marginTop: "0.5rem" }}
                                    type="text"
                                    name={`description${index}`}
                                    placeholder="Description"
                                    value={lot.description}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...lot,
                                        description: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            {/* remove button */}
                            <Button type="primary" danger onClick={() => handleRemoveRow(index)} style={{ marginBottom: "1rem", marginTop: '0.5rem' }}>
                              Remove
                            </Button>
                          </div>
                        ))}
                        <Button type="primary" onClick={handleAddRow}>
                          Add Lot
                        </Button>
                      </Form.Item>
                    </Card>
                  ) : (
                    <Card
                      bordered={true}
                      style={{ border: "1px solid lightgrey" }}
                    >
                      <Form.Item label="Itemwise">
                        {formData.fulfillment[formData.fulfillmentType].map((item, index) => (
                          <div key={index}>
                            <Row gutter={16}>
                              {/* Left side (4 columns) */}
                              <Col span={12}>
                                {/* 1 */}
                                <Form.Item
                                  name={`item_${index}`}
                                  rules={[
                                    { required: true, message: 'Please enter Item Name..!' },
                                  ]}
                                >
                                  <Select
                                    defaultValue={item.value || undefined}  // Adjust the property based on your data structure
                                    options={itemsList}
                                    onChange={(selectedItem) => {
                                      const selectedValue = selectedItem; // Use the correct property for the unique item identifier
                                      console.log(itemsList, selectedValue, "selevctedValue");
                                      const selectedQuantity = itemsList.find(item => item.value === selectedValue)?.quantity || 0;

                                      console.log("qty", selectedQuantity);
                                      // Update quantity value based on the selected item
                                      setSIQ(selectedQuantity)

                                      handleFulfillmentChange(
                                        index,
                                        formData.fulfillmentType,
                                        {
                                          ...item,
                                          item: selectedItem, // Adjust the property based on your data structure
                                        }
                                      );
                                      handlePOSelection(formData.PO_Number, index);
                                    }}
                                  />
                                </Form.Item>
                                {/* 2 */}
                                <Form.Item
                                  name={`itemNumber${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Item Name..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="text"
                                    placeholder="Item Number"
                                    value={item.itemNumber}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...item,
                                        itemNumber: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* 3 */}


                                {/* Add other left side inputs here */}
                              </Col>

                              {/* Right side (5 columns) */}
                              <Col span={12}>
                                {/* 4 */}
                                {/* <Form.Item
                                  name={`unitPrice${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Item Name..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="number"
                                    placeholder="Unit Price"
                                    value={item.unitPrice}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...item,
                                        unitPrice: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item> */}
                                {/* 5 */}
                                {/* <Form.Item
                                  name={`total${index}`}
                                // rules={[
                                //   { required: true, message: 'Please enter Item Name..!' },
                                // ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="number"
                                    placeholder="Total"
                                    value={item.total}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...item,
                                        total: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item> */}
                                <Form.Item
                                  name={`quantity${index}`}
                                  rules={[
                                    { required: true, message: 'Please enter Quantity..!' },
                                  ]}
                                >
                                  <Input
                                    // style={{ marginBottom: "1rem" }}
                                    type="number"
                                    placeholder="Quantity"
                                    value={item.quantity}
                                    maxLength={remainingQuantity}
                                    onChange={(e) => {
                                      console.log(e.target.value, remainingQuantity);
                                      const enteredValue = parseInt(e.target.value, 10);

                                      if (remainingQuantity === 0) {
                                        window.alert(`There is no quantity available for this item..!!`);
                                        // Optionally reset the input field or handle as needed
                                        form.setFieldValue(`quantity${index}`, formData.fulfillment.itemwise[index].quantity)
                                      } else if (enteredValue > remainingQuantity) {
                                        window.alert(`Available Quantity is ${remainingQuantity}..! Please enter a value under or equal to ${remainingQuantity}..!`);
                                        // Optionally reset the input field or handle as needed
                                        form.setFieldValue(`quantity${index}`, formData.fulfillment.itemwise[index].quantity)
                                      } else {
                                        console.log("justTest", e);
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...item,
                                            quantity: enteredValue,
                                            amount: enteredValue * item.price,
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </Form.Item>
                                {/* 6 */}
                                <Form.Item
                                  name={`scheduledDeliveryDate${index}`}
                                  rules={[
                                    { required: true, message: 'Please select Delivery Date..!' },
                                  ]}
                                >
                                  <DatePicker
                                    style={{ marginRight: "0.5rem" }}
                                    onChange={(date) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...item,
                                        scheduledDeliveryDate: date.$d.toString(),
                                      })
                                    }
                                  />
                                </Form.Item>
                                {/* Add other right side inputs here */}
                              </Col>
                              {/* 7 */}
                              <Col span={24}>
                                <Form.Item>
                                  <Input
                                    style={{ marginTop: "0.5rem" }}
                                    type="text"
                                    name={`description${index}`}
                                    placeholder="Description"
                                    value={item.description}
                                    onChange={(e) =>
                                      handleFulfillmentChange(index, formData.fulfillmentType, {
                                        ...item,
                                        description: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            {/* remove button */}
                            <Button type="primary" danger onClick={() => handleRemoveRow(index)} style={{ marginBottom: "1rem", marginTop: '0.5rem' }}>
                              Remove
                            </Button>
                          </div>
                        ))}
                        <Button type="primary" onClick={handleAddRow}>
                          Add Item
                        </Button>
                      </Form.Item>
                    </Card>
                  )}
                  <Button type="primary" htmlType="submit" style={{ marginTop: '1rem' }} loading={loading}>
                    Submit
                  </Button>
                  {/* <Button type="primary" onClick={() => {
                    form.resetFields()
               
                  }} style={{ marginTop: '1rem' }}>
                    Clear
                  </Button> */}
                </Form>
              </div>
            </section>
            {/* po lists table end here */}
          </section>
          {/* Wrapper ends here */}
        </div>
      </main>
    </>
  );
}

export default RawMaterialDS;

import React, { useState } from 'react';
import { InputText, InputTextarea } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';

const CreateDeliverySchedule = () => {
    const [fulfillmentType, setFulfillmentType] = useState(''); // State to track fulfillment type
    const [formData, setFormData] = useState({
        items: [
            {
                item: '',
                scheduledDeliveryDate: '',
                quantity: '',
            }
        ],
        poDate: '',
        poNumber: '',
        poStatus: '',
        itemwise: [
            {
                item: '',
                quantity: '',
                deliveryDate: '',
            }
        ],
        lotwise: [
            {
                lot: '',
                quantity: '',
                deliveryDate: '',
            }
        ],
    });

    const handleInputChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleArrayInputChange = (arrayField, index, field, value) => {
        const newArray = [...formData[arrayField]];
        newArray[index] = {
            ...newArray[index],
            [field]: value,
        };

        setFormData({
            ...formData,
            [arrayField]: newArray,
        });
    };

    const handleSubmit = () => {
        // Handle form submission logic here
        console.log('Form Data:', formData);
    };

    return (
        <>
            <main style={{ backgroundColor: "#80" }}>
                <div className='flex' style={{ backgroundColor: "#80" }}>
                    <Sidebar />
                    <section className="dashboard_wrapper" style={{ width: "80%", backgroundColor: "#80" }}>
                        <Navbar />
                        <div className="col-12">
                            <div className="card">
                                <h5>Delivery Schedule</h5>
                                <div className="p-fluid formgrid grid">
                                    {formData.items.map((item, index) => (
                                        <div key={index}>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor={`item${index}`}>Item</label>
                                                <InputText
                                                    id={`item${index}`}
                                                    type="text"
                                                    onChange={(e) => handleArrayInputChange('items', index, 'item', e.target.value)}
                                                    value={item.item}
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor={`deliveryDate${index}`}>Scheduled Delivery Date</label>
                                                <Calendar
                                                    id={`deliveryDate${index}`}
                                                    value={item.scheduledDeliveryDate}
                                                    onChange={(e) => handleArrayInputChange('items', index, 'scheduledDeliveryDate', e.value)}
                                                    showIcon
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor={`quantity${index}`}>Quantity</label>
                                                <InputText
                                                    id={`quantity${index}`}
                                                    type="number"
                                                    onChange={(e) => handleArrayInputChange('items', index, 'quantity', e.target.value)}
                                                    value={item.quantity}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {/* Add more items fields as needed */}

                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="poDate">PO Date</label>
                                        <Calendar
                                            id="poDate"
                                            value={formData.poDate}
                                            onChange={(e) => handleInputChange('poDate', e.value)}
                                            showIcon
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="poNumber">PO Number</label>
                                        <InputText
                                            id="poNumber"
                                            type="text"
                                            onChange={(e) => handleInputChange('poNumber', e.target.value)}
                                            value={formData.poNumber}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="poStatus">PO Status</label>
                                        <InputText
                                            id="poStatus"
                                            type="text"
                                            onChange={(e) => handleInputChange('poStatus', e.target.value)}
                                            value={formData.poStatus}
                                        />
                                    </div>

                                    <div className="field col-12 md:col-12">
                                        <label>Fulfillment Type:</label>
                                        <div>
                                            <label htmlFor="itemwise">
                                                <input
                                                    type="radio"
                                                    id="itemwise"
                                                    name="fulfillmentType"
                                                    value="itemwise"
                                                    checked={fulfillmentType === 'itemwise'}
                                                    onChange={() => setFulfillmentType('itemwise')}
                                                />
                                                Itemwise
                                            </label>
                                            <label htmlFor="lotwise">
                                                <input
                                                    type="radio"
                                                    id="lotwise"
                                                    name="fulfillmentType"
                                                    value="lotwise"
                                                    checked={fulfillmentType === 'lotwise'}
                                                    onChange={() => setFulfillmentType('lotwise')}
                                                />
                                                Lotwise
                                            </label>
                                        </div>
                                    </div>

                                    {/* Conditional rendering based on fulfillment type */}
                                    {fulfillmentType === 'itemwise' && (
                                        <>
                                            {formData.itemwise.map((itemwise, index) => (
                                                <div key={index}>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`itemwiseItem${index}`}>Item</label>
                                                        <InputText
                                                            id={`itemwiseItem${index}`}
                                                            type="text"
                                                            onChange={(e) => handleArrayInputChange('itemwise', index, 'item', e.target.value)}
                                                            value={itemwise.item}
                                                        />
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`itemwiseQty${index}`}>Quantity</label>
                                                        <InputText
                                                            id={`itemwiseQty${index}`}
                                                            type="number"
                                                            onChange={(e) => handleArrayInputChange('itemwise', index, 'quantity', e.target.value)}
                                                            value={itemwise.quantity}
                                                        />
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`itemwiseDate${index}`}>Delivery Date</label>
                                                        <Calendar
                                                            id={`itemwiseDate${index}`}
                                                            value={itemwise.deliveryDate}
                                                            onChange={(e) => handleArrayInputChange('itemwise', index, 'deliveryDate', e.value)}
                                                            showIcon
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {fulfillmentType === 'lotwise' && (
                                        <>
                                            {formData.lotwise.map((lotwise, index) => (
                                                <div key={index}>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`lotwiseLot${index}`}>Lot</label>
                                                        <InputText
                                                            id={`lotwiseLot${index}`}
                                                            type="text"
                                                            onChange={(e) => handleArrayInputChange('lotwise', index, 'lot', e.target.value)}
                                                            value={lotwise.lot}
                                                        />
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`lotwiseQty${index}`}>Quantity</label>
                                                        <InputText
                                                            id={`lotwiseQty${index}`}
                                                            type="number"
                                                            onChange={(e) => handleArrayInputChange('lotwise', index, 'quantity', e.target.value)}
                                                            value={lotwise.quantity}
                                                        />
                                                    </div>
                                                    <div className="field col-12 md:col-12">
                                                        <label htmlFor={`lotwiseDate${index}`}>Delivery Date</label>
                                                        <Calendar
                                                            id={`lotwiseDate${index}`}
                                                            value={lotwise.deliveryDate}
                                                            onChange={(e) => handleArrayInputChange('lotwise', index, 'deliveryDate', e.value)}
                                                            showIcon
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    {/* Submit Button */}
                                    <div className="field col-12 md:col-12">
                                        <button type="button" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}

export default CreateDeliverySchedule;

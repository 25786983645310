import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addItemInArray } from "../../app/slices/editItemsData";

const ItemsAddDialog = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const [data, setData] = useState({
    accountAssignmentCategory: "",
    itemCategory: "",
    material: "",
    plant: "",
    price: "",
    quantity: "",
    unitOfMeasurement: "",
  });
  const dispatch = useDispatch();

  //   when user change input
  const changeInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  // add item to backend
  const addItem = () => {
    dispatch(addItemInArray(data))
    setModal2Open(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setModal2Open(true)}>
        Add Item
      </Button>
      <Modal
        title="Add Item"
        centered
        open={modal2Open}
        onOk={() => addItem()}
        onCancel={() => setModal2Open(false)}
      >
        {/* row */}
        <div>
          <Input
            onChange={changeInput}
            placeholder="Account Assignment Category"
            name="accountAssignmentCategory"
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Item Category"
            name="itemCategory"
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Material"
            name="material"
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input onChange={changeInput} placeholder="Plant" name="plant" />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input onChange={changeInput} placeholder="Price" name="price" />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Quantity"
            name="quantity"
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Unit Of Measurement"
            name="unitOfMeasurement"
          />
        </div>
      </Modal>
    </>
  );
};
export default ItemsAddDialog;

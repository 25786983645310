import React, {useEffect, useState} from 'react';
// import './tabul.css'
import flattenObject from "../hx/FlattenObj";
import BaseTable from "./BaseTable";
import ASN from "./ASN";
import ENDDS from "./ENDDS";
import { Descriptions } from "antd"
import { useLocation } from 'react-router-dom';
import GRN from "./GRN";

const PurchaseOrderPDF = () => {
    const location = useLocation();
    const {
        PO_Number,
        currency,
        totalAmount,
        items,
        status,
        deliveryAddress,
    } = location.state.data;
    console.log(location.state.data)
    const [flattenedData, setFlattenedData] = useState([]);


    const humanReadableKeys = [
        { oldKey: '_id', newKey: 'ID' },
        { oldKey: 'status', newKey: 'Status' },
        { oldKey: 'itemCategory', newKey: 'Item Category' },
        { oldKey: 'material', newKey: 'Item' },
        { oldKey: 'quantity', newKey: 'Quantity' },
        { oldKey: 'plant', newKey: 'Plant' },
        { oldKey: 'price', newKey: 'Price' },
        { oldKey: 'GST', newKey: 'GST' },
        { oldKey: 'totalAmount', newKey: 'Total Amount' },
        { oldKey: 'accountAssignmentCategory', newKey: 'Account Assignment Category' },
        { oldKey: 'unitOfMeasurement', newKey: 'Unit of Measurement' },
        { oldKey: 'HSNCode', newKey: 'HSN Code' },
    ];
    useEffect(() => {
        // Flatten the object within the array
        console.log(items)
        const flattened = items.map(item => {
            item.status = Object.keys(item.status).map(key => `${key}: ${item.status[key]}`).join('\n')
            console.log(item)
            return flattenObject(item)
        });
        console.log("oneasdjfhakjshdfja", flattened)
        const flattened1 = flattened.map(item => {
            const convertedItem = {};
            for (const key in item) {
                const newKeyObj = humanReadableKeys.find(obj => obj.oldKey === key);
                const newKey = newKeyObj ? newKeyObj.newKey : key;
                convertedItem[newKey] = item[key];
            }
            return convertedItem;
        })
        setFlattenedData(flattened1);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            !location.state.print && window.print();
        }, 1000)
    }, []);

    return (
        <div className="purchase-order" id={"report"} style={{width: "11.7in", background: "white"}}>
            <h2>Purchase Order Details</h2>
            <Descriptions title="" bordered>
                <Descriptions.Item label="PO Number">{PO_Number}</Descriptions.Item>
                <Descriptions.Item label="Currency">{currency}</Descriptions.Item>
                <Descriptions.Item label="Total Amount">{totalAmount}</Descriptions.Item>
                <Descriptions.Item label="Delivery Address">{deliveryAddress}</Descriptions.Item>
            </Descriptions>

            <BaseTable data={flattenedData} title={"Items"}/>

            <hr/>
            <ENDDS PO_Number={PO_Number} />
            <hr/>
            <ASN PO_Number={PO_Number}/>
            <hr/>
            <GRN PO_Number={PO_Number}/>
            <hr/>
        </div>
    );
};

export default PurchaseOrderPDF;

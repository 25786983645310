const React = require("react");
const BaseTable = ({data, title}) => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        fontFamily: 'Arial, sans-serif',
        border: '1px solid #ddd',
    };

    const thStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        fontWeight: 'bold',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
    };

    const tdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    return (
        <div>
            <b>{title}</b>
            <table style={tableStyle}>
                <thead>
                <tr>
                    {Object.keys(data[0] || {}).map((key) => (
                        <th key={key} style={thStyle}>{key}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {Object.values(item).map((value, idx) => (
                            <td key={idx} style={tdStyle}>
                                    <pre>
                                        {value}
                                    </pre>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default BaseTable;

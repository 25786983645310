// Navbar.js

import React, {useEffect, useState} from 'react';
import './navbar.css'; // Import the stylesheet
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import DateDiv from "../hx/DateDiv";
import ls from "localstorage-slim";

const Navbar = ({title}) => {
    const [userRole, setUserRole] = useState('');
    const [username, setUsername] = useState('');
  const navigate = useNavigate();

    useEffect(() => {
        const storedUserRole = ls.get('userRole', { decrypt: true });
        console.log(storedUserRole, "storedUserRole")
        setUserRole(storedUserRole || '');
        setUsername(ls.get('username', { decrypt: true }) || '');
    }, [ls.get("userRole"), ls.get("username")]);
  // logout
  const logout = (e) => {
     ls.clear();
     navigate('/login')
  }

  return (
      <div className="navbar flex justify-between align-items-right" style={{flexDirection:'row-reverse'}}>

          {/*<DateDiv/>*/}
          {/* <div className="nav-item active">{title}</div> */}
          <Button type="primary" style={{marginRight: "1rem"}} onClick={logout}>Logout</Button>
      </div>
  );
};

export default Navbar;

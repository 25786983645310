import { Input, Form, Button, Spin, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import ls from 'localstorage-slim';

const Login = () => {
  // localstates...
  const [optionValue, setOptionValue] = useState("Password");
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSection, setIsOtpSection] = useState(false);
  const [LWPData, setLWPData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();

  const [messageApi, contextHolder] = message.useMessage();

  const info = () => {
    messageApi.info("Login Successful!");
  };

  const error = (msg) => {
    messageApi.error(msg);
  };

  // change input
  const changeInput = (e) => {
    setLWPData({ ...LWPData, [e.target.name]: e.target.value });
    console.log(LWPData);
  };

  const setUserData = (key, value, options) => {
    return new Promise((resolve, reject) => {
      try {
        ls.set(key, value, options);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
  // password wise login
  const loginPasswordWise = (e) => {
    setIsLoading(true);
    axios
      .post(
        "https://x1cslrnasf.execute-api.ap-south-1.amazonaws.com/dev/api/user/login",
        LWPData
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          info();
        }
        console.log(location)
        Promise.all([
          setUserData('userRole', res.data.user.role, { encrypt: true }),
          setUserData('username', res.data.user.username, { encrypt: true }),
          setUserData('token', res.data.token, { encrypt: true }),
          setUserData('companyname', res.data.user.companyname, { encrypt: true })
        ]).then(() => {
          console.log("Login-Response(PW)", res);
          console.log(navigate(-1), "check nev");
          // window.location.reload();
        }).catch((error) => {
          console.error("Error setting user data:", error);
        });
      })
      .catch((err) => {
        console.log("Login-Response(PW)", err);
        setIsLoading(false);
        error(err.response.data.message);
      });
  };


  // option change
  const secretOptionChange = (e) => {
    setOptionValue(e.target.value);
  };

  return (
    <>
      {contextHolder}
      {isLoading && <div style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        width: "100%",
        zIndex: 100,
        background: "#00000099"
      }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>}
      <main
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "white"
        }}
      >
        <section
          className="login_component form"
        >
          <div className="title">
            Welcome,<br /><span>sign in to continue</span>
          </div>

          <section className="email_wrp">
            <Form.Item
              name="email"
              onChange={changeInput}
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder={"Email"} rootClassName={"input"} name="email" />
            </Form.Item>
          </section>
          {/* email end here */}

          {/* password and contact goes here */}
          {optionValue !== "Contact" ? (
            <section className="password_wrp">
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                onChange={changeInput}
              >
                <Input.Password placeholder={"Password"} rootClassName={"input"} name="password" />
              </Form.Item>
            </section>
          ) : (
            <section className="contact_wrp" style={{ marginTop: "1rem" }}>
              <Form.Item
                label={optionValue}
                name="contact"
                rules={[
                  { required: true, message: "Please input your contact!" },
                ]}
              >
                <Input />
              </Form.Item>
            </section>
          )}
          {/* pasword and contact end here */}

          {optionValue !== "Contact" ? (
            <button
              style={{
                fontWeight: "600",
              }}
              className={"button-confirm"}
              id={"passwordWiseSignIn"}
              onClick={loginPasswordWise}
            >
              Sign in
              {isLoading ? (
                <Spin
                  size="small"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "white" }}
                      spin
                    />
                  }
                />
              ) : (
                ""
              )}
            </button>

          ) : (
            <Button
              type="primary"
              style={{
                fontWeight: "600",
              }}
            >
              Send OTP
            </Button>
          )}
          <span className={"forgot-pass"} onClick={() => { navigate("/forgotPassword") }}>Forgot Password?</span>
        </section>

        {/* otp wrapper goes here */}
        {isOtpSection ?
          <section
            className="otp_wrapper"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              padding: "1rem",
              width: "30%",
              borderRadius: "0.3rem",
              marginTop: "1rem",
              background: "white",
            }}
          >
            {/* otp goes here */}
            <section className="email_wrp" style={{ marginTop: "1rem" }}>
              <Form.Item
                label="OTP"
                name="mobileOtp"
                rules={[{ required: true, message: "Please input your OTP!" }]}
              >
                <Input />
              </Form.Item>
            </section>
            {/* otp end here */}

            <Button
              type="primary"
              style={{
                fontWeight: "600",
              }}
            >
              Verify OTP
            </Button>
          </section> : <></>}
        {/* otp wrapper end here */}

      </main>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import TableC from "../../components/Table/Table";
import axios from "axios";
import ViewDrawer from "./ViewDrawer"
import EditDraw from "./EditDraw"
import EditForm from "./EditForm"
import { AUTH_TOKEN } from "../../utils/authHeader";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Spin } from "antd";
import CSVUploader from "../../components/CSV/uploadPO";
import { useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import * as XLSX from 'xlsx';
import { getAllPO } from "../../Apis/po_apis";
import { useSelector } from "react-redux";
import { selectDateRange } from "../../app/slices/dateRangeSlice";


function List() {
  const navigate = useNavigate();
  // locals states
  const [listData, setListData] = useState([]);
  const [loadSet, setLoadSet] = useState([]);
  const getDateRange = useSelector(selectDateRange);
  const tableColumns = [
    {
      title: "PO Number",
      dataIndex: "PO_Number",
      key: "PO_Number",
    },
    {
      title: "Buyer-Id",
      dataIndex: "buyerID",
      key: "buyerID",
    },
    {
      title: "Vendor-Id",
      dataIndex: "vendorID",
      key: "vendorID",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <div>{JSON.stringify(record.status)}</div>,
    },
    {
      title: "",
      dataIndex: "op",
      key: "op",
    },
  ];

  const handleCreatePO = () => {
    navigate('/create/PO');
  };

  // get porders
  useEffect(() => {
    setLoadSet(true);
    axios.get(getAllPO + "?startDate=" + JSON.parse(getDateRange)[0] + "&endDate=" + JSON.parse(getDateRange)[1], { headers: { "Authorization": `Bearer ${AUTH_TOKEN()}` } })
      .then((res) => {
        console.log("GET:ALL-P-Order", res);
        setListData(res.data.data);
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      })
      .finally(() => {
        setLoadSet(false);
      });
  }, []);

  const flattenObject = (obj, prefix = '') => {
    let flatObject = {};

    for (const [key, value] of Object.entries(obj)) {
      if (key === 'vendorPlayer' || key === 'buyerPlayer' || key === 'status' || key === '__v') {
        continue; // Skip these keys
      }
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        flatObject = { ...flatObject, ...flattenObject(value, `${prefix}${key}.`) };
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          flatObject = {
            ...flatObject,
            ...flattenObject(item, `${prefix}${key}[${index}].`),
          };
        });
      } else {
        flatObject[`${prefix}${key}`] = value;
      }
    }

    return flatObject;
  };

  const exportToExcel = async () => {
    const flattenObjects = await Promise.all(listData.map(obj => flattenObject(obj)));

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(flattenObjects);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'allPO' + fileExtension;

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <main style={{ backgroundColor: "#80" }}>
        {loadSet && <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          width: "100%",
          zIndex: 100,
          background: "#00000099"
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>}

        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          {/* Wrapper goes here */}
          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >
            <Navbar title={"Dashboard / PurchaseOrders / All PO Orders"} />

            {/* po lists table goes here */}
            <section className="lists_table" style={{
              background
                : "white", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", height: "80vh", width: "98%", margin: "1rem auto", padding: "1rem"
            }}>
              <h4 style={{ textAlign: 'center' }}>Purchase Orders</h4>
              <Divider />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CSVUploader />
                <Button
                  style={{ marginLeft: '4px' }}
                  type="primary"
                  onClick={handleCreatePO}
                  disabled={ls.get('userRole', { decrypt: true }) === 'Vendor'}
                >
                  Create PO
                </Button>
                <Button
                  style={{ marginLeft: '4px' }}
                  type="primary"
                  onClick={exportToExcel}
                >
                  DOWNLOAD ALL PO
                </Button>
              </div>

              <TableC columns={tableColumns} pODatas={listData} />
            </section>
            {/* po lists table end here */}
          </section>
          {/* Wrapper ends here */}
        </div>
      </main>
      <EditDraw />

      <ViewDrawer />
      {/* <EditForm /> */}

    </>
  );
}

export default List;

async function flattenObjectAsync(obj, prefix = '') {
    let result = {};

    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            await Promise.all(obj[key].map(async (item, index) => {
                if (typeof item === 'object' && item !== null) {
                    const flattenedItem = await flattenObjectAsync(item, `${index}`);
                    Object.assign(result, flattenedItem);
                }
            }));
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            const flattenedItem = await flattenObjectAsync(obj[key], `${key}${prefix}`);
            Object.assign(result, flattenedItem);
        } else {
            result[`${key}${prefix}`] = obj[key];
        }
    }

    return result;
}

export default flattenObjectAsync;

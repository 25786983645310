import { Table } from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {AUTH_TOKEN} from "../../utils/authHeader";

const GRNTable = ({PO_Number, children}) => {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(
            `https://6o22xu4u61.execute-api.ap-south-1.amazonaws.com/dev/api/grn/viewAllGRNList/${encodeURIComponent(PO_Number)}`,
            {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN()}`,
                },
            }
        ).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [PO_Number])

    const columns = [
        {
            title: 'Purchase Number',
            dataIndex: 'PO_Number',
            key: 'PO_Number',
        },
        {
            title: 'GRN Number',
            dataIndex: 'GRN_Number',
            key: 'GRN_Number',
        },
        {
            title: 'ASN Number',
            dataIndex: 'ASN_Number',
            key: 'ASN_Number',
        },
    ];
  return (
    <>
      <Table
        style={{ marginTop: "1rem" }}
        dataSource={data}
        columns={columns}
        locale={{ emptyText: children }}
      />
    </>
  );
};

export default GRNTable;

import React, { useEffect, useState } from 'react';
import axios from "axios";
import { AUTH_TOKEN } from "../utils/authHeader";
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Layout } from 'antd';
const { Content } = Layout;


const Dashboard = () => {
  const navigate = useNavigate();
  const [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    axios.get("https://mzwv794329.execute-api.ap-south-1.amazonaws.com/dev/api/dashboard/vendorDetails", { headers: { "Authorization": `Bearer ${AUTH_TOKEN()}` } }).then((res) => {
      console.log("GET:ALL-vendor-details", res.data.dashboardData2);
      setVendorData(res.data.dashboardData2)
    }).catch((err) => {
      console.log("GET:ALL-vendor-details error", err);
    });
  }, []);
  return (
    <main >
      <div className='flex' >
        <Sidebar />

        {/* Wrapper goes here */}
        <section className="dashboard_wrapper" style={{ width: "80%" }}>
          <Navbar title={"Dashboard"} />

          {/* dashboard */}
          {/* <div className="dashboard_container flex justify-between" style={{width:"90%",margin:"1rem auto"}}>
             <div className='quick_link_box flex item-center' onClick={()=>{navigate("/p-order/lists")}}><AppstoreOutlined /><p style={{marginLeft:"1rem"}}>ALL PO ORDERS</p></div>
          </div> */}
          {/*<Layout>*/}

          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              backgroundColor: "white"
            }}
          >
            {/* 1st Column Start */}
            <Row gutter={16}>
              <Col span={5}>
                <Link to="/p-order/lists">
                  <Card title="Total PO" bordered={false} >
                    {/* Card content */}
                    {vendorData.poCount}<br />
                  </Card>
                </Link>
              </Col>

              <Col span={5}>
                <Link to="/asn/lists">
                  <Card title="Total ASN" bordered={false}>
                    {/* Card content */}
                    {vendorData.asnCount}<br />
                    {vendorData.asnPercentage} %
                  </Card>
                </Link>
              </Col>
              <Col span={5}>
                <Link to="/grn/lists">
                  <Card title="Total GRN" bordered={false}>
                    {/* Card content */}
                    {vendorData.grnCount}<br />
                    {vendorData.grnPercentage} %
                  </Card>
                </Link>
              </Col>
              <Col span={5}>
                <Link to="/list/creditnote">
                  <Card title="Total Credit Note" bordered={false}>
                    {/* Card content */}
                    {vendorData.totalCreditCount}<br />
                  </Card>
                </Link>
              </Col>
              <Col span={4}>
                <Link to="/list/debitnote">
                  <Card title="Total Debit Note" bordered={false}>
                    {/* Card content */}
                    {vendorData.totalDebitCount}<br />
                  </Card>
                </Link>
              </Col>
            </Row>
            {/* 1st Column End  */}

            {/* 2nd Column Start */}
            <Row gutter={16} style={{ marginTop: '3rem' }}>
              <Col span={6}>
                <Card title="Upcoming Raw DS" bordered={false}>
                  {/* Card content */}
                  {vendorData.rawUpcoming}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="Upcoming End DS" bordered={false}>
                  {/* Card content */}
                  {vendorData.upcomingDS}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="Completed End & Raw DS" bordered={false}>
                  {/* Card content */}
                  {vendorData.completedDS}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="Pending End & Raw DS" bordered={false}>
                  {/* Card content */}
                  {vendorData.partialDS}
                </Card>
              </Col>
            </Row>
            {/* 2nd column end */}

            {/* 3rd Column Start */}

            <Row gutter={16} style={{ marginTop: '3rem' }}>
              <Col span={8}>
                <Card title="Upcoming ASN" bordered={false}>
                  {/* Card content */}
                  {vendorData.upcomingASN}
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Partial ASN" bordered={false}>
                  {/* Card content */}
                  {vendorData.partialASN}
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Completed ASN" bordered={false}>
                  {/* Card content */}
                  {vendorData.completedASN}
                </Card>
              </Col>
            </Row>
            {/* 3rd Column End */}

            {/* 4rthColumn Start */}

            <Row gutter={16} style={{ marginTop: '3rem' }}>
              <Col span={8}>
                <Card title="Upcoming GRN" bordered={false}>
                  {/* Card content */}
                  {vendorData.upcomingGRN}
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Partial GRN" bordered={false}>
                  {/* Card content */}
                  {vendorData.partialGRN}
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Completed GRN" bordered={false}>
                  {/* Card content */}
                  {vendorData.completedGRN}
                </Card>
              </Col>
            </Row>
            {/* 4th Column End */}

            {/* 5th Column Start */}
            <Row gutter={16} style={{ marginTop: '3rem' }}>
              <Col span={12}>
                <Card title="Credit Note Amount" bordered={false}>
                  {/* Card content */}
                  {vendorData.totalCreditAmount}
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Debit Note Amount" bordered={false}>
                  {/* Card content */}
                  {vendorData.totalDebitAmount}
                </Card>
              </Col>
            </Row>
            {/* 5th Column End */}


          </Content>
          {/*</Layout>*/}

        </section>
        {/* Wrapper ends here */}
      </div>
    </main>
  );
}

export default Dashboard;

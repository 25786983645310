import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';

const AsnForm = () => {
    const [fulfillmentType, setFulfillmentType] = useState('itemwise'); // or 'lotwise' depending on your default
;
    const [asnData, setAsnData] = useState({
        PO_Number: '',
        shipmentId: '',
        expectedDateOfArrival: null,
        contents: [
            {
                itemCode: '',
                itemName: '',
                quantity: 0,
            },
        ],
        trackingLink: '',
        vendorDetails: {
            vendorName: '',
            vendorAddress: '',
        },
        shippingDetails: {
            logisticPartner: '',
            shipmentMethod: '',
        },
        itemizedList: [
            {
                itemCode: '',
                itemName: '',
                quantity: '',
                lotNumber: '',
            },
        ],
        documentAttachments: [
            {
                documentType: '',
                documentLink: '',
            },
        ],
    });

    const handleInputChange = (field, value) => {
        setAsnData({
            ...asnData,
            [field]: value,
        });
    };

    const handleArrayInputChange = (arrayField, index, field, value) => {
        const newArray = [...asnData[arrayField]];
        newArray[index] = {
            ...newArray[index],
            [field]: value,
        };

        setAsnData({
            ...asnData,
            [arrayField]: newArray,
        });
    };

    const handleSubmit = () => {
        // Handle form submission logic here
        console.log('ASN Data:', asnData);
        // You can send this data to your backend for saving to the database
    };

    return (
        <>
            <main style={{ backgroundColor: "#80" }}>
                <div className='flex' style={{ backgroundColor: "#80" }}>
                    <Sidebar />
                    <section className="dashboard_wrapper" style={{ width: "80%", backgroundColor: "#80" }}>
                        <Navbar />
                        <div className="col-12">
                            <div className="card">
                                <h5> Advanced shipping notifications </h5>
                                <div className="p-fluid formgrid grid">
                                    {/* Other input fields ... */}

                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="poNumber">PO Number</label>
                                        <InputTextarea
                                            id="poNumber"
                                            type="text"
                                            onChange={(e) => handleInputChange('PO_Number', e.target.value)}
                                            value={asnData.PO_Number}
                                        />
                                    </div>

                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="trackingLink">Tracking Link</label>
                                        <InputTextarea
                                            id="trackingLink"
                                            type="text"
                                            onChange={(e) => handleInputChange('trackingLink', e.target.value)}
                                            value={asnData.trackingLink}
                                        />
                                    </div>

                                    {/* Vendor Details */}
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="vendorName">Vendor Name</label>
                                        <InputTextarea
                                            id="vendorName"
                                            type="text"
                                            onChange={(e) => handleInputChange('vendorDetails.vendorName', e.target.value)}
                                            value={asnData.vendorDetails.vendorName}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="vendorAddress">Vendor Address</label>
                                        <InputTextarea
                                            id="vendorAddress"
                                            type="text"
                                            onChange={(e) => handleInputChange('vendorDetails.vendorAddress', e.target.value)}
                                            value={asnData.vendorDetails.vendorAddress}
                                        />
                                    </div>

                                    {/* Shipping Details */}
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="logisticPartner">Logistic Partner</label>
                                        <InputTextarea
                                            id="logisticPartner"
                                            type="text"
                                            onChange={(e) => handleInputChange('shippingDetails.logisticPartner', e.target.value)}
                                            value={asnData.shippingDetails.logisticPartner}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="shipmentMethod">Shipment Method</label>
                                        <InputTextarea
                                            id="shipmentMethod"
                                            type="text"
                                            onChange={(e) => handleInputChange('shippingDetails.shipmentMethod', e.target.value)}
                                            value={asnData.shippingDetails.shipmentMethod}
                                        />
                                    </div>

                                    {/* Itemized List */}
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="itemizedItemCode">Item Code</label>
                                        <InputTextarea
                                            id="itemizedItemCode"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('itemizedList', 0, 'itemCode', e.target.value)}
                                            value={asnData.itemizedList[0].itemCode}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="itemizedItemName">Item Name</label>
                                        <InputTextarea
                                            id="itemizedItemName"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('itemizedList', 0, 'itemName', e.target.value)}
                                            value={asnData.itemizedList[0].itemName}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="itemizedQuantity">Quantity</label>
                                        <InputTextarea
                                            id="itemizedQuantity"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('itemizedList', 0, 'quantity', e.target.value)}
                                            value={asnData.itemizedList[0].quantity}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="itemizedLotNumber">Lot Number</label>
                                        <InputTextarea
                                            id="itemizedLotNumber"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('itemizedList', 0, 'lotNumber', e.target.value)}
                                            value={asnData.itemizedList[0].lotNumber}
                                        />
                                    </div>

                                    {/* Document Attachments */}
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="docType">Document Type</label>
                                        <InputTextarea
                                            id="docType"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('documentAttachments', 0, 'documentType', e.target.value)}
                                            value={asnData.documentAttachments[0].documentType}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label htmlFor="docLink">Document Link</label>
                                        <InputTextarea
                                            id="docLink"
                                            type="text"
                                            onChange={(e) => handleArrayInputChange('documentAttachments', 0, 'documentLink', e.target.value)}
                                            value={asnData.documentAttachments[0].documentLink}
                                        />
                                    </div>

                                    {/* Fulfillment Type */}
                                    {/* <div className="field col-12 md:col-12">
                                        <label>Fulfillment Type:</label>
                                        <div>
                                            <label htmlFor="itemwise">
                                                <input
                                                    type="radio"
                                                    id="itemwise"
                                                    name="fulfillmentType"
                                                    value="itemwise"
                                                    checked={asnData.fulfillmentType === 'itemwise'}
                                                    onChange={() => setFulfillmentType('itemwise')}
                                                />
                                                Itemwise
                                            </label>
                                            <label htmlFor="lotwise">
                                                <input
                                                    type="radio"
                                                    id="lotwise"
                                                    name="fulfillmentType"
                                                    value="lotwise"
                                                    checked={asnData.fulfillmentType === 'lotwise'}
                                                    onChange={() => setFulfillmentType('lotwise')}
                                                />
                                                Lotwise
                                            </label>
                                        </div>
                                    </div>

                                    {/* Conditional rendering based on fulfillment type */}
                                    {/* {asnData.fulfillmentType === 'itemwise' && (
                                        <>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="itemwiseItem">Item</label>
                                                <InputText
                                                    id="itemwiseItem"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('itemwise', e.target.value)}
                                                    value={asnData.itemwise}
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="itemwiseQuantity">Quantity</label>
                                                <InputText
                                                    id="itemwiseQuantity"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('itemQuantity', e.target.value)}
                                                    value={asnData.itemQuantity}
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="itemwiseDate">Delivery Date</label>
                                                <InputText
                                                    id="itemwiseDate"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('itemDate', e.target.value)}
                                                    value={asnData.itemDate}
                                                />
                                            </div>
                                        </>
                                    )} */}

                                    {/* {asnData.fulfillmentType === 'lotwise' && (
                                        <>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="lot">Lot</label>
                                                <InputText
                                                    id="lot"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('lot', e.target.value)}
                                                    value={asnData.lot}
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="lotQuantity">Quantity</label>
                                                <InputText
                                                    id="lotQuantity"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('lotQuantity', e.target.value)}
                                                    value={asnData.lotQuantity}
                                                />
                                            </div>
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="lotDate">Delivery Date</label>
                                                <InputText
                                                    id="lotDate"
                                                    type="text"
                                                    onChange={(e) => handleInputChange('lotDate', e.target.value)}
                                                    value={asnData.lotDate}
                                                />
                                            </div>
                                        </>
                                    )} */} 

                                    {/* Submit Button */}
                                    <div className="field col-12 md:col-12">
                                        <button type="button" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}

export default AsnForm;

import axios from "axios";
import ls from "localstorage-slim";
const baseUrl = "https://x1cslrnasf.execute-api.ap-south-1.amazonaws.com/dev"
// const baseUrl = "http://localhost:5000/dev"

const forgotPasswordApi = {
    generateOtp: async (email, showMessage) => {
        try {
            const response = await axios.post(baseUrl+'/api/user/forgot/generateOtp', { email });
            // showMessage('info', response.data.message);
            return {_id: response.data._id, error: false}
        } catch (error) {
            showMessage('error', error.response.data.message);
            return {error: true}
        }
    },

    verify: async (_id, otp, showMessage) => {
        try {
            const response = await axios.post(baseUrl+'/api/user/forgot/verify', { _id, otp });
            // showMessage('info', "Now you can Enter your new Password");
            return {error: false}
        } catch (error) {
            showMessage('error', error.response.data.message);
            return {error: true}
        }
    },

    changePsw: async (_id, password, confirmPassword, showMessage) => {
        try {
            const res = await axios.post(baseUrl+'/api/user/forgot/changePsw', { _id, password, confirmPassword });
            await setUserData('userRole', res.data.user.role, {encrypt: true})
            await setUserData('username', res.data.user.username, { encrypt: true })
            await setUserData('token', res.data.token, { encrypt: true })
            await setUserData('companyname', res.data.user.companyname, { encrypt: true })
            showMessage('info', res.data.message);
            return {error: false}
        } catch (error) {
            showMessage('error', error.response.data.message);
            return {error: true}
        }
    },
};
const setUserData = (key, value, options) => {
    return new Promise((resolve, reject) => {
        try {
            ls.set(key, value, options);
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export default forgotPasswordApi;

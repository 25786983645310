import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addPOItemEditStatus } from "../../app/slices/statusSlice";
import { editChange } from "../../app/slices/editItemsData";

const EditItemDialog = () => {
  const [data, setData] = useState({
    accountAssignmentCategory: "",
    itemCategory: "",
    material: "",
    plant: "",
    price: "",
    quantity: "",
    unitOfMeasurement: "",
  });
  const toggleStatus = useSelector((state) => state.statusSlice.editPoItems);
  const editPoIndex = useSelector((state) => state.statusSlice.editPoIndex);
  const specificItem = useSelector((state) => state.editItemsData.specificItem);
  const dispatch = useDispatch();

  useEffect(()=>{
     if(specificItem){
      console.log("specificItem",specificItem)
      const {accountAssignmentCategory,itemCategory,material,plant,price,quantity,unitOfMeasurement} = specificItem;
      setData({...data,accountAssignmentCategory,itemCategory,material,plant,price,quantity,unitOfMeasurement})
     }
  },[specificItem])

  //   when user change input
  const changeInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  // add item to backend
  const editItem = () => {
    dispatch(editChange({editPoIndex,data}))
    dispatch(addPOItemEditStatus(false))
  };

  return (
    <>
      <Modal
        title="Edit Item"
        centered
        open={toggleStatus}
        onOk={() => editItem()}
        onCancel={() => {dispatch(addPOItemEditStatus(false))}}
        className="po-edit-class"
      >
        {/* row */}
        <div>
          <Input
            onChange={changeInput}
            placeholder="Account Assignment Category"
            name="accountAssignmentCategory"
            value={data?.accountAssignmentCategory}
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Item Category"
            name="itemCategory"
            value={data?.itemCategory}
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Material"
            name="material"
            value={data?.material}
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
            
          <Input onChange={changeInput} placeholder="Plant" name="plant" value={data?.plant}/>
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input onChange={changeInput} placeholder="Price" name="price" value={data?.price}/>
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Quantity"
            name="quantity"
            value={data?.quantity}
          />
        </div>

        {/* row */}
        <div style={{ marginTop: "0.5rem" }}>
          <Input
            onChange={changeInput}
            placeholder="Unit Of Measurement"
            name="unitOfMeasurement"
            value={data?.unitOfMeasurement}
          />
        </div>
      </Modal>
    </>
  );
};
export default EditItemDialog;

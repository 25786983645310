import React, { useEffect, useState } from "react";
import ls from "localstorage-slim";
import { CaretDownOutlined, CaretRightOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import { Map, Marker } from 'pigeon-maps';
import axios from "axios";
import { AUTH_TOKEN } from '../../utils/authHeader';
import { Table, Steps, Divider, Spin } from "antd";
import { getAllCreditNote } from "../../Apis/CreditNote_apis";
import { getAllASN } from "../../Apis/Asn_apis";
import truckIcon from "../../Assets/icons8-truck.gif";


const View_ASN = ({ PO_Number }) => {
    const [getDebitNoteList, setDebitNoteList] = useState([]);
    const [showSteps, setShowSteps] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [trackingData, setTrackingData] = useState(null);
    const [currentExpandedRowKey, setCurrentExpandedRowKey] = useState(null);
    const [lastGeoCode, setLastGeoCode] = useState(null);
    const [hue, setHue] = useState(0)
    const color = "rgb(0,25,200)"; // Adjust the hue (210deg) as needed

    useEffect(() => {
        if (lastGeoCode) {
            console.log('Last GeoCode Updated:', lastGeoCode);
            // Perform actions or rendering based on the updated lastGeoCode
        }
    }, [lastGeoCode]);


    const expandIcon = ({ expanded, onExpand, record }) => (
        <span
            onClick={async (e) => {
                onExpand(record, e);
                const isExpanded = !expanded;
                console.log('isExpanded:', isExpanded);
                console.log('record.key:', record.key);
                setCurrentExpandedRowKey(isExpanded ? record.key : null);
                setShowSteps(isExpanded);

                if (isExpanded) {
                    // Call the tracking API only when expanding
                    try {
                        const MAX_RETRIES = 5; // Define the maximum number of retries

                        console.log(MAX_RETRIES);
                        const responseRetry = async (record, retries = 0) => {
                            console.log("init")
                            try {
                                const res = await axios.get(
                                    `https://weoxh23k82.execute-api.ap-south-1.amazonaws.com/dev/api/asn/trackVehicleNo/${record.vehicleNumber}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${AUTH_TOKEN()}`,
                                        },
                                    }
                                );
                                console.log("yeee", res)
                                return res; // Return the data received from the API
                            } catch (error) {
                                // Log the error or handle it accordingly
                                console.error("Error occurred while fetching data:", error);
                                if (retries < MAX_RETRIES) {
                                    // Retry with an incremented retry count
                                    console.log(`Retrying... Retry count: ${retries + 1}`);
                                    return responseRetry(record, retries + 1);
                                } else {
                                    // Max retries reached, throw the error or handle it accordingly
                                    console.error("Max retries reached. Unable to fetch data.");
                                    throw error;
                                }
                            }
                        };
                        let response = await responseRetry(record, 0)

                        console.log('GET: Tracking Data expand', response.data.data);

                        // Set the last index geoCode in state
                        if (response.data.data && response.data.data.txn.length > 0) {
                            const lastTransaction = response.data.data.txn[response.data.data.txn.length - 1];
                            const lastGeoCode = lastTransaction.geoCode.split(',').map(coord => parseFloat(coord));
                            setTrackingData(response.data.data);
                            setLastGeoCode(lastGeoCode);
                        } else {
                            setTrackingData(null);
                            setLastGeoCode(null);
                        }
                    } catch (error) {
                        console.error('Error fetching tracking data', error);
                        setTrackingData(null);
                        setLastGeoCode(null);
                    }
                } else {
                    // Clear tracking data when collapsing
                    setTrackingData(null);
                    setLastGeoCode(null);
                }
            }}
            style={{ cursor: 'pointer' }}
        >
            {expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            <span style={{ marginLeft: 8 }}>Track</span>
        </span>
    );

    const expandedRowRender = (record) => {
        console.log(record, "record");

        if (currentExpandedRowKey === record.key) {
            return (
                <>
                    {showSteps && (
                        <div style={{ display: 'flex' }}>
                            {/* Steps component */}
                            <div style={{ flex: '0 0 30%', marginRight: '1rem' }}>
                                <Steps
                                    progressDot
                                    direction="vertical"
                                    items={
                                        trackingData &&
                                        trackingData.txn.map((txn, index) => ({
                                            title: (
                                                <div style={{ marginTop: '1rem' }}>
                                                    <p>
                                                        <strong>Location:</strong> {txn.plaza}
                                                    </p>
                                                    <p>
                                                        <strong>Date:</strong> {txn.time}
                                                    </p>
                                                    <p>
                                                        <strong>Time:</strong> {txn.date}
                                                    </p>
                                                    <p>
                                                        <strong>City:</strong> {txn.city}
                                                    </p>
                                                </div>
                                            ),
                                        }))
                                    }
                                    current={trackingData ? trackingData.txn.length : 0}
                                />
                            </div>

                            {/* Pigeon Maps for marker */}
                            {lastGeoCode ? (
                                <Map
                                    zoom={11}
                                    height={500}
                                    center={lastGeoCode}
                                    width={'70%'} // Set the width as needed
                                >
                                    {trackingData &&
                                        trackingData.txn.map((txn, index) => {
                                            const geoCodeArray = txn.geoCode
                                                .split(',')
                                                .map((coord) => parseFloat(coord));
                                            console.log('GeoCode:', geoCodeArray);
                                            return (
                                                <Marker
                                                    width={50}
                                                    key={index}
                                                    anchor={geoCodeArray}
                                                    color={color} // Adjust the color variable according to your needs
                                                    onClick={() => setHue(hue + 20)}
                                                />
                                            );
                                        })}
                                </Map>
                            ) : (<img style={{ marginLeft: "16vw" }} src={truckIcon} />)}
                        </div>
                    )}

                    <Divider />
                </>
            );
        } else {
            return <div>"error"</div>;
        }
    };

    useEffect(() => {
        axios.get(getAllASN + encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(res => {
            console.log("res2debit", res);

            // Adding index to the key property
            const debitNoteListWithKey = res.data.map((item, index) => ({ ...item, key: index.toString() }));

            setDebitNoteList(debitNoteListWithKey);
        }).catch((err) => {
            setDebitNoteList([]);
            console.error("res2debit", err);
        })
    }, [PO_Number]);

    return (
        <>
            {PO_Number}
            
            <div key={"ASNVIEW"}>
                <Table
                    style={{ marginTop: "1rem" }}
                    dataSource={getDebitNoteList}
                    columns={columns}
                    expandable={{
                        expandedRowRender,
                        expandIcon,
                        expandedRowKeys,
                        onExpand: (expanded, record) => {
                            setExpandedRowKeys(expanded ? [record.key] : []);
                            setShowSteps(expanded);
                        },
                    }}
                />
            </div>
        </>
    )
}

const columns = [
    {
        title: 'ASN Number',
        dataIndex: 'ASN_Number',
        key: 'ASN_Number',
    },
    {
        title: 'PO Number',
        dataIndex: 'PO_Number',
        key: 'PO_Number',
    },
    {
        title: 'eWayBill No',
        dataIndex: 'eWayBillNo',
        key: 'eWayBillNo',
    },
    {
        title: 'Vehicle Number',
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber',
    },
    {
        title: 'Expected Date Of Arrival',
        dataIndex: 'expectedDateOfArrival',
        key: 'expectedDateOfArrival',
    },
    {
        title: 'Vendor Contact Person',
        dataIndex: 'vendorDetails.contactperson',
        key: 'vendorDetails.contactperson',
        render: (_, record) => record.vendorDetails.contactperson,
    },
    {
        title: 'Carrier',
        dataIndex: 'shippingDetails.carrier',
        key: 'shippingDetails.carrier',
        render: (_, record) => record.shippingDetails.carrier,
    },
    {
        title: 'Shipping Address',
        dataIndex: 'shippingDetails.shippingAddress',
        key: 'shippingDetails.shippingAddress',
        render: (_, record) => record.shippingDetails.shippingAddress,
    },
    {
        title: 'GRN Status',
        dataIndex: 'GRNstatus',
        key: 'GRNstatus',
    },
];


export default View_ASN;

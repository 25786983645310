import ls from "localstorage-slim";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const ProtectedRoute = ({children}) => {
    const token = ls.get("token");
    console.log(token)
    let navigate = useNavigate()


    useEffect(() => {
        if(token === null) {
            navigate('/login')
        }
    }, []);
    return token ? children : <></>
}

export default ProtectedRoute
import React, { useEffect, useState } from "react";
import { Button, Drawer, Table,message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addEditDrawerStatus,
  addEditPoIndex,
  addPOItemEditStatus,
  addViewDrawerStatus,
} from "../../app/slices/statusSlice";
import axios from "axios";
import { Input } from "antd";
import {
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import ItemsAddDialog from "./ItemsAddDialog";
import EditItemDialog from "./EditItemDialog";
import { addAccountAssignmentCategory, addSpecific, deleteItemInArray } from "../../app/slices/editItemsData";
import { AUTH_TOKEN } from "../../utils/authHeader";

const EditForm = () => {
  const drawStatus = useSelector((state) => state.statusSlice.editDraw);
  const viewId = useSelector((state) => state.statusSlice.POViewId);
  const dispatch = useDispatch();
  const [getApiData, setGetApiData] = useState({});
  let [getApiItems, setGetApiItems] = useState([]);
  const [getApiStatus, setApiStatus] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [pONumber,setpONumber] = useState();
  const [currency,setCurrency] = useState();
  const [deliveryAddress,setDeliveryAddress] = useState();
  const itemsData = useSelector((state)=>state.editItemsData.itemsDatas)

  console.log("itemsData",itemsData)


  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const editSave = () => {
    axios.put(
   "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/updatePurchaseOrder",{
     poNumber:pONumber,
     currency:currency,
     deliveryAddress:deliveryAddress,
     items:itemsData
    },{
     headers: {
       Authorization:
         `Bearer ${AUTH_TOKEN()}`,
     },
   }).then((res)=>{console.log(res);if(res.data.message){info(res.data.message);fetchData()}}).catch((err)=>{console.log("editDataSave",err)})
 }

  const columns = [
    {
      title: "accountAssignmentCategory",
      dataIndex: "accountAssignmentCategory",
      key: "accountAssignmentCategory",
    },
    {
      title: "itemCategory",
      dataIndex: "itemCategory",
      key: "itemCategory",
    },
    {
      title: "material",
      dataIndex: "material",
      key: "material",
    },
    {
      title: "plant",
      dataIndex: "plant",
      key: "plant",
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "unitOfMeasurement",
      dataIndex: "unitOfMeasurement",
      key: "unitOfMeasurement",
    },
    {
      title: "",
      dataIndex: "op",
      key: "op",
    },
  ];

  const info = (message) => {
    messageApi.info(message);
  };

  const error = () => {
    messageApi.error("Error !");
  };

  function fetchData(){
    axios
    .get(
      `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getPurchaseOrders/${viewId}`,
      {
        headers: {
          Authorization:
            `Bearer ${AUTH_TOKEN()}`,
        },
      }
    )
    .then((res) => {
      console.log("GET:-Particular-PO", res);
      setGetApiData(res.data.data);
      setpONumber(res.data.data._id);
      setCurrency(res.data.data.currency);
      setDeliveryAddress(res.data.data.deliveryAddress)
      setApiStatus(
        res.data.data.status[res.data.data.status.length - 1].status
      );
      if (res.data.data.items) {
        const dataSources = res.data.data.items.map((items, ind) => ({
          accountAssignmentCategory: items.accountAssignmentCategory,
          itemCategory: items.itemCategory,
          material: items.material,
          plant: items.plant,
          price: items.price,
          quantity: items.quantity,
          unitOfMeasurement: items.unitOfMeasurement,
          op: (
            <div className="flex">
              <EditFilled
                style={{
                  color: "green",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
                onClick={()=>{dispatch(addPOItemEditStatus(true));dispatch(addEditPoIndex(ind));dispatch(addSpecific(items))}}
              />
              <DeleteFilled
                style={{
                  color: "red",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
                onClick={()=>{
                  dispatch(deleteItemInArray(ind));
                  editSave();
                }}
              />
            </div>
          ),
        }));

        const itemsList = res.data.data.items.map((items, ind) => ({
          accountAssignmentCategory: items.accountAssignmentCategory,
          itemCategory: items.itemCategory,
          material: items.material,
          plant: items.plant,
          price: items.price,
          quantity: items.quantity,
          unitOfMeasurement: items.unitOfMeasurement,
        }));

        setGetApiItems(dataSources);
        dispatch(addAccountAssignmentCategory(itemsList))
        // setSendDataObject(itemsList)
      }
    })
    .catch((err) => {
      console.log("GET:-Particular-PO", err);
    });
  }

  useEffect(() => {
    fetchData();
  }, [viewId]);

  
  

  return (
    <>
    {contextHolder}
      <Drawer
        size="large"
        title="Purchase Order Detail"
        onClose={() => {
          dispatch(addEditDrawerStatus(false));
        }}
        placement="right"
        open={drawStatus}
        width= "100rem" 
      >
        {viewId != "" ? (
          <div>
            {/* row1 does start*/}
            <div className="flex item-center justify-between">
              <div>
                <label htmlFor="buyerID">Buyer ID</label>
                <Input value={getApiData?.buyerID} id="buyerID" disabled />
              </div>
              <div>
                <label htmlFor="currency">Currency</label>
                <Input value={currency} id="currency" onChange={(e)=>{setCurrency(e.target.value)}}/>
              </div>
              <div>
                <label htmlFor="deliveryAddress">Delivery Address</label>
                <Input
                  value={deliveryAddress}
                  id="deliveryAddress"
                  onChange={(e)=>{setDeliveryAddress(e.target.value)}}
                />
              </div>
            </div>
            {/* row1 end here */}

            {/* row2 does start*/}
            <div
              className="flex item-center justify-between"
              style={{ marginTop: "1rem" }}
            >
              <div>
                <label htmlFor="documentDate">Document Date</label>
                <Input
                  value={getApiData?.documentDate}
                  id="documentDate"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="totalAmount">Total Amount</label>
                <Input
                  value={getApiData?.totalAmount}
                  id="totalAmount"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="vendorID">Vendor ID</label>
                <Input value={getApiData?.vendorID} id="vendorID" disabled />
              </div>
            </div>
            {/* row2 end here */}

            {/* row3 does start*/}
            <div
              className="flex item-center justify-between"
              style={{ marginTop: "1rem" }}
            >
              <div style={{ width: "100%" }}>
                <label htmlFor="status">Status</label>
                <Input value={getApiStatus} id="status" disabled />
              </div>
            </div>
            {/* row3 end here */}

            {/* items table */}
            <Table
              style={{ marginTop: "1rem" }}
              dataSource={getApiItems}
              columns={columns}
            />

            {/* add items button */}
            <div>
              <ItemsAddDialog/>
              <EditItemDialog/>
              <Button style={{marginLeft:"1rem"}} onClick={(e)=>{editSave()}}>Save</Button>
            </div>

          </div>
        ) : (
          <>dsd</>
        )}
      </Drawer>
    </>
  );
};
export default EditForm;

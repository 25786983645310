import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
// import { LayoutProvider } from "./layout/context/layoutcontext";
import Dashboard from "./pages/Dashboard";
import "./index.css";
import Test from "./pages/Test";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "./styles/layout/layout.scss";
import "./styles/demo/Demos.scss";
import CreateDeliverySchedule from "./pages/DeliverySchedule";
import CreateASNForm from "./pages/AsnForm";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import List from "./pages/POrder/List";
import List_ASN from "./pages/ASN/List_ASN";
import List_GRN from "./pages/GRN/List_GRN";
import store from "./app/store";
import { Provider } from "react-redux";
import RawMaterialDS from "./pages/DS/RawMaterialDS";
import Create_ASN from "./pages/ASN/createAsn";
import Create_GRN from "./pages/GRN/createGRN";
import CreditNote from "./pages/CreditNote/CreditNote";
import ListCreditNote from "./pages/CreditNote/List_CreditNote";
import DebitNote from "./pages/DebitNote/DebitNote";
import EndMaterialDS from './pages/DS/EndMaterialDS'
import ListDebitNote from "./pages/DebitNote/List_DebitNote";
import List_End_Ds from "./pages/DS/listEndDs";
import CreatePO from "./pages/POrder/CreatePO";
import ProtectedRoute from "./components/ProtectedRoute";
import RegisterVENDOR from "./components/CSV/RegisterVENDOR";
import PO from "./components/PDF/PO";
import {ConfigProvider} from "antd";
import EditForm from "./pages/POrder/EditForm";
import List_Raw_Ds from "./pages/DS/listRawDS";
import SharePO from "./pages/POrder/SharePO";


const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
    ),
  },
  {
    path: "/test",
    element: (
        <ProtectedRoute>
          <Test />
        </ProtectedRoute>
    ),
  },
  {
    path: "about",
    element: (
        <ProtectedRoute>
          <div>About</div>
        </ProtectedRoute>
    ),
  },
  {
    path: "/deliverySchedule",
    element: (
        <ProtectedRoute>
          <CreateDeliverySchedule />
        </ProtectedRoute>
    ),
  },
  {
    path: "/list/EndDs",
    element: (
        <ProtectedRoute>
          <List_End_Ds />
        </ProtectedRoute>
    ),
  },
  {
    path: "/list/RawDs",
    element: (
        <ProtectedRoute>
          <List_Raw_Ds />
        </ProtectedRoute>
    ),
  },
  {
    path: "/asn",
    element: (
        <ProtectedRoute>
          <CreateASNForm />
        </ProtectedRoute>
    ),
  },
  {
    path: "/Createasn",
    element: (
        <ProtectedRoute>
          <Create_ASN />
        </ProtectedRoute>
    ),
  },
  {
    path: "/CreateGRN",
    element: (<ProtectedRoute> <Create_GRN /> </ProtectedRoute>),
  },
  {
    path: "/login",
    element: <Login />,
  }, {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/p-order/lists",
    element: (
        <ProtectedRoute>
          <List />
        </ProtectedRoute>
    ),
  },
  {
    path: "/p-order/edit",
    element: (
        <ProtectedRoute>
          <EditForm />
        </ProtectedRoute>
    ),
  },
  {
    path: "/asn/lists",
    element: (
        <ProtectedRoute>
          <List_ASN />
        </ProtectedRoute>
    ),
  },
  {
    path: "/grn/lists",
    element: (
        <ProtectedRoute>
          <List_GRN />
        </ProtectedRoute>
    ),
  },
  {
    path: "/ds/raw-material-delivery-schedule",
    element: (
        <ProtectedRoute>
          <RawMaterialDS />
        </ProtectedRoute>
    ),
  },
  {
    path: "/ds/end-material-delivery-schedule",
    element: (
        <ProtectedRoute>
          <EndMaterialDS />
        </ProtectedRoute>
    ),
  },
  {
    path: "/create/PO",
    element: (
        <ProtectedRoute>
          <CreatePO />
        </ProtectedRoute>
    ),
  },
  {
    path: "/create/creditnote",
    element: (
        <ProtectedRoute>
          <CreditNote />
        </ProtectedRoute>
    ),
  },
  {
    path: "/list/creditnote",
    element: (
        <ProtectedRoute>
          <ListCreditNote />
        </ProtectedRoute>
    ),
  },
  {
    path: "/create/debitnote",
    element: (
        <ProtectedRoute>
          <DebitNote />
        </ProtectedRoute>
    ),
  },
  {
    path: "/list/debitnote",
    element: (
        <ProtectedRoute>
          <ListDebitNote />
        </ProtectedRoute>
    ),
  },
  {
    path: "/registerVendor",
    element: (
        <ProtectedRoute>
            <RegisterVENDOR />
        </ProtectedRoute>
    )
  },{
        path: "/podownload",
        element: (
            <ProtectedRoute>
                <PO />
            </ProtectedRoute>
        )
    },{
        path: "/share/:id",
        element: (
            <ProtectedRoute>
                <SharePO />
            </ProtectedRoute>
        )
    }
]);

createRoot(document.getElementById("root")).render(
  <html lang="en" suppressHydrationWarning>
    <head>
      <link
        id="theme-css"
        href={`./themes/lara-light-teal/theme.css`}
        rel="stylesheet"
      ></link>
    </head>
    <body>
    <ConfigProvider
        theme={{
            token: {
                // Seed Token
                colorPrimary: '#000',
                borderRadius: 2,
                colorTextDisabled: '#000'
            },
        }}
    >
      <Provider store={store}>
        <PrimeReactProvider>
          <RouterProvider router={router} />
        </PrimeReactProvider>
      </Provider>
    </ConfigProvider>
    </body>
  </html>
);

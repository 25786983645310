import React, {useEffect, useMemo, useState} from "react";
import { Table } from 'antd';
import {DeleteFilled, DownloadOutlined, EditFilled, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addEditDrawerStatus, addPOViewId, addViewDrawerStatus } from "../../app/slices/statusSlice";
import { AUTH_TOKEN } from "../../utils/authHeader";
import {useNavigate} from "react-router-dom";
import ls from "localstorage-slim";

const TableC = ({columns,pODatas}) => {
  let dataSources = [];
  const [tableData,setTableData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const role = useMemo(() => ls.get("userRole", { decrypt: true }), []);

    // deletes
  // 1.PO Order delete
  const deletePOrder = (pONumber) => {
     axios.delete("https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/deletePurchaseOrder",{headers:{"Authorization":`Bearer ${AUTH_TOKEN()}`},data:{poNumber:pONumber}}).then((res)=>{
      console.log("DELETE:-PO-Order",res);
      if(res.data.message){
         window.alert(res.data.message)
         window.location.reload();
      }
     }).catch((err)=>{console.log("DELETE:-PO-Order",err);})
  }

  useEffect(() => {
    if (pODatas) {
      const dataSources = pODatas.map((PO, ind) => ({
        key: PO._id,
        PO_Number: PO.PO_Number,
        buyerID: PO.buyerID,
        vendorID: PO.vendorID,
        deliveryAddress: PO.deliveryAddress,
        status: PO?.status[PO?.status?.length - 1].status,
        op: (
          <>
            <EyeOutlined onClick={()=>{dispatch(addViewDrawerStatus(true));dispatch(addPOViewId(PO._id))}} style={{ marginRight: "0.4rem", cursor: "pointer" }} />
              {role === "Buyer" && <EditFilled onClick={(e)=>{
                e.preventDefault()
                console.log(PO)
                navigate("/create/PO", {state: {PO_Number: PO._id}})
            }} />}
            <DeleteFilled
              onClick={(e) => {
                e.preventDefault()
                deletePOrder(PO._id);
              }}
              style={{ marginRight: "0.4rem", cursor: "pointer", color: "red" }}
            />

          </>
        ),
      }));

      setTableData(dataSources);
    }
  }, [pODatas]);

  return (<><Table dataSource={tableData} columns={columns} onRow={(record) => ({

    onClick: (e) => {
        const target = e.target;
        if (target.tagName.toLowerCase() === "svg" || target.tagName.toLowerCase() === "path") {
            // If the click is on an icon (Edit, Delete), prevent further action
            return;
        }
      console.log(record,'recordnmmmm')
      dispatch(addPOViewId(record.key));
      dispatch(addViewDrawerStatus(true));
       // Assuming PO_Number is the correct property to use
    },
  })
}
 /></>)

};

export default TableC;

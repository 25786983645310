// dateRangeSlice.js

import { createSlice } from '@reduxjs/toolkit';
import ls from "localstorage-slim";

function getWeekDates() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startDate = new Date(today); // Copy today's date
    startDate.setDate(today.getDate() - dayOfWeek); // Calculate the start date of the week
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6); // Calculate the end date of the week

    return JSON.stringify([ startDate, endDate ]);
}

const initialState = {
    dateRange: ls.get("dateRange") || getWeekDates(),
};

export const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
    },
});

export const { setDateRange } = dateRangeSlice.actions;

export const selectDateRange = (state) => state.dateRange.dateRange;

export default dateRangeSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Table, Steps, Divider, Tooltip, Spin } from 'antd';
import { CaretDownOutlined, CaretRightOutlined, ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AUTH_TOKEN } from '../../utils/authHeader';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Map, Marker } from 'pigeon-maps';
import truckIcon from "../../Assets/icons8-truck.gif";
import { getAllPO } from "../../Apis/po_apis";
import { useSelector } from "react-redux";
import { selectDateRange } from "../../app/slices/dateRangeSlice";


const List_ASN = () => {
  const [listData, setListData] = useState([]);
  const [creditNoteData, setCreditNoteData] = useState([]);
  const [showSteps, setShowSteps] = useState(false);
  const [loadSet, setLoadSet] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [currentExpandedRowKey, setCurrentExpandedRowKey] = useState(null);
  const [trackingData, setTrackingData] = useState(null);
  const [lastGeoCode, setLastGeoCode] = useState(null);
  const [txnCityData, setTxnCityData] = useState(null);
  const [markerTooltipText, setMarkerTooltipText] = useState('');
  const [hue, setHue] = useState(0)
  const color = "rgb(0,25,200)"; // Adjust the hue (210deg) as needed
  const getDateRange = useSelector(selectDateRange);

  const columns = [
    {
      title: 'Purchase Number',
      dataIndex: 'PO_Number',
      key: 'PO_Number',
    },
    {
      title: 'ASN Number',
      dataIndex: 'ASN_Number',
      key: 'ASN_Number',
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'vehicleNumber',
      key: 'vehicleNumber',
    },
    {
      title: 'EWayBill No.',
      dataIndex: 'eWayBillNo',
      key: 'eWayBillNo',
    },
    {
      title: 'Contact Person',
      dataIndex: 'vendorDetails',
      key: 'contactperson',
      render: (vendorDetails) => vendorDetails?.contactperson || 'N/A',
    },
    {
      title: 'GRN Status',
      dataIndex: 'GRNstatus',
      key: 'GRNstatus',
    },
  ];

  useEffect(() => {
    if (lastGeoCode) {
      console.log('Last GeoCode Updated:', lastGeoCode);
      // Perform actions or rendering based on the updated lastGeoCode
    }
  }, [lastGeoCode]);


  const expandIcon = ({ expanded, onExpand, record }) => (
    <span
      onClick={async (e) => {
        onExpand(record, e);
        const isExpanded = !expanded;
        console.log('isExpanded:', isExpanded);
        console.log('record.key:', record.key);
        setCurrentExpandedRowKey(isExpanded ? record.key : null);
        setShowSteps(isExpanded);
        setTrackingData(null);
        setLastGeoCode(null);

        if (isExpanded) {
          // Call the tracking API only when expanding
          try {
            setTrackingData(null);
            setLastGeoCode(null);
            const MAX_RETRIES = 5; // Define the maximum number of retries

            console.log(MAX_RETRIES);
            const responseRetry = async (record, retries = 0) => {
              console.log("init")
              try {
                const res = await axios.get(
                  `https://weoxh23k82.execute-api.ap-south-1.amazonaws.com/dev/api/asn/trackVehicleNo/${record.vehicleNumber}`,
                  {
                    headers: {
                      Authorization: `Bearer ${AUTH_TOKEN()}`,
                    },
                  }
                );
                console.log("yeee", res)
                return res; // Return the data received from the API
              } catch (error) {
                // Log the error or handle it accordingly
                console.error("Error occurred while fetching data:", error);
                if (retries < MAX_RETRIES) {
                  // Retry with an incremented retry count
                  console.log(`Retrying... Retry count: ${retries + 1}`);
                  return responseRetry(record, retries + 1);
                } else {
                  // Max retries reached, throw the error or handle it accordingly
                  console.error("Max retries reached. Unable to fetch data.");
                  throw error;
                }
              }
            };
            let response = await responseRetry(record, 0)

            console.log('GET: Tracking Data expand', response.data.data);

            // Set the last index geoCode in state
            if (response.data.data && response.data.data.txn.length > 0) {
              const lastTransaction = response.data.data.txn[response.data.data.txn.length - 1];
              const lastGeoCode = lastTransaction.geoCode.split(',').map(coord => parseFloat(coord));
              setTrackingData(response.data.data);
              setLastGeoCode(lastGeoCode);
              setTxnCityData(response.data.data.txn.map(txn => txn.plaza));
            } else {
              setTrackingData(null);
              setLastGeoCode(null);
              setTxnCityData(null);
            }
          } catch (error) {
            console.error('Error fetching tracking data', error);
            setTrackingData(null);
            setLastGeoCode(null);
            setTxnCityData(null);
          }
        } else {
          // Clear tracking data when collapsing
          setTrackingData(null);
          setLastGeoCode(null);
          setTxnCityData(null);
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
      <span style={{ marginLeft: 8 }}>Track</span>
    </span>
  );
  const handleMarkerOver = (event, text) => {
    console.log('Marker Clicked:', text); // Add this line for debugging
    setMarkerTooltipText(text);

  };

  const expandedRowRender = (record) => {
    console.log(record, "record");

    if (currentExpandedRowKey === record.key) {
      return (
        <>
          {showSteps && (
            <div style={{ display: 'flex' }}>
              {/* Steps component */}
              <div style={{ flex: '0 0 30%', marginRight: '1rem' }}>
                <Steps
                  progressDot
                  direction="vertical"
                  items={
                    trackingData &&
                    trackingData.txn.map((txn, index) => ({
                      title: (
                        <div style={{ marginTop: '1rem' }}>
                          <p>
                            <strong>Location:</strong> {txn.plaza}
                          </p>
                          <p>
                            <strong>Date:</strong> {txn.time}
                          </p>
                          <p>
                            <strong>Time:</strong> {txn.date}
                          </p>
                          <p>
                            <strong>City:</strong> {txn.city}
                          </p>
                        </div>
                      ),
                    }))
                  }
                  current={trackingData ? trackingData.txn.length : 0}
                />
              </div>

              {/* Pigeon Maps for marker */}
              {lastGeoCode ? (
                <Map
                  zoom={11}
                  height={500}
                  center={lastGeoCode}

                  width={'70%'} // Set the width as needed
                >

                  {trackingData &&
                    trackingData.txn.map((txn, index) => {
                      const geoCodeArray = txn.geoCode
                        .split(',')
                        .map((coord) => parseFloat(coord));
                      console.log('GeoCode:', geoCodeArray);
                      const cityData = txnCityData ? txnCityData[index] : null;
                      return (
                        <Marker
                          width={50}
                          key={index}
                          anchor={geoCodeArray}
                          color={color}
                          onMouseOver={(event) => handleMarkerOver(event, cityData)}
                        >
                          {/*<img src="./g32asdf.tiff"/>*/}
                        </Marker>
                      );
                    })}
                </Map>
              ) : (<img style={{ marginLeft: "16vw" }} src={truckIcon} />)}
            </div>
          )}
          {/* Display the marker tooltip text in the UI */}
          {markerTooltipText && (
            <div style={{ marginTop: '1rem' }}>
              <strong>Marker Tooltip Text:</strong> {markerTooltipText}
            </div>
          )}

          <Divider />
        </>
      );
    } else {
      return <div>"error"</div>;
    }
  };

  useEffect(() => {
    axios
      .get(
        getAllPO + "?startDate=" + JSON.parse(getDateRange)[0] + "&endDate=" + JSON.parse(getDateRange)[1],
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN()}`,
          },
        }
      )
      .then((res) => {
        console.log('GET:ALL-P-Order', res);
        setListData(res.data.data);
      })
      .catch((err) => {
        console.error('GET:ALL-P-Order', err);
      });
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      setLoadSet(true); // Set loading to true before making requests

      const poNumbers = listData.map((po) => encodeURIComponent(po.PO_Number));

      const promises = poNumbers.map((poNumber) =>
        axios.get(
          `https://weoxh23k82.execute-api.ap-south-1.amazonaws.com/dev/api/asn/getAllASNDetails/${poNumber}`,
          {
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN()}`,
            },
          }
        )
      );

      Promise.allSettled(promises)
        .then((results) => {
          const creditNoteData = results
            .flatMap((result) => (result.status === 'fulfilled' ? result.value.data : []))
            .map((item, index) => ({ ...item, key: index.toString() }));

          console.log('ASN Data:', creditNoteData);
          setCreditNoteData(creditNoteData);
        })
        .catch((error) => {
          console.log('Error fetching ASN', error);
          setCreditNoteData([]);
        })
        .finally(() => {
          setLoadSet(false); // Set loading to false after requests complete
        });
    }
  }, [listData]);


  return (
    <>
      <main style={{ backgroundColor: '#80' }}>
        {loadSet && <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          width: "100%",
          zIndex: 100,
          background: "#00000099"
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>}
        <div className="flex" style={{ backgroundColor: '#80' }}>
          <Sidebar />

          <section
            className="dashboard_wrapper"
            style={{ width: '80%', backgroundColor: '#80' }}
          >
            <Navbar title={'ASN /View All ASN list'} />

            {/* Static Steps component */}

            <section
              className="lists_table"
              style={{
                background: 'white',
                boxShadow:
                  'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                height: '80vh',
                width: '98%',
                margin: '1rem auto',
                padding: '1rem',
              }}
            >
              <h4 style={{ textAlign: 'center' }}>Advanced Shipping Notice</h4>
              <Divider />
              <Table
                dataSource={creditNoteData}
                columns={columns}
                expandable={{
                  expandedRowRender,
                  expandIcon,
                  expandedRowKeys,
                  onExpand: (expanded, record) => {
                    setExpandedRowKeys(expanded ? [record.key] : []);
                    setShowSteps(expanded);
                  },
                }}
              />
            </section>
          </section>
        </div>
      </main>
    </>
  );
};

export default List_ASN;

import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import axios from 'axios';
import { AUTH_TOKEN } from '../../utils/authHeader';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import flattenObject from "../../components/hx/FlattenObj";
import ViewEndDS from "./ViewEndDS";

const List_End_Ds = () => {
    const [listData, setListData] = useState([]);
    const [loadSet, setLoadSet] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState([]);


    useEffect(() => {
        setLoadSet(true); // Set loading to true before the request

        axios
            .get(
                'https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders',
                {
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN()}`,
                    },
                }
            )
            .then((res) => {
                console.log('GET:ALL-P-Order', res);
                setListData(res.data.data);
            })
            .catch((err) => {
                console.error('GET:ALL-P-Order', err);
            })
            .finally(() => {
                setLoadSet(false); // Set loading to false after the request completes
            });
    }, []);


    return (
        <>
            <main style={{ backgroundColor: '#80' }}>
                {loadSet && <div style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: "absolute",
                    width: "100%",
                    zIndex: 100,
                    background: "#00000099"
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>}
                <div className="flex" style={{ backgroundColor: '#80' }}>
                    <Sidebar />

                    <section
                        className="dashboard_wrapper"
                        style={{ width: '80%', backgroundColor: '#80' }}
                    >
                        <Navbar title={'End DS / View All End DS'} />

                        <section
                            className="lists_table"
                            style={{
                                background:
                                    'white',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                                height: '80vh',
                                width: '98%',
                                margin: '1rem auto',
                                padding: '1rem',
                            }}
                        >
                            {listData.map((po) => {
                                console.log(po)
                                return <><ViewEndDS PO_Number={po.PO_Number} /><hr /></>
                            })}
                        </section>
                    </section>
                </div>
            </main>
        </>
    );
};

export default List_End_Ds;

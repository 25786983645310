import React, { useEffect, useState } from 'react';

import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { AUTH_TOKEN } from '../../utils/authHeader';
import ls from 'localstorage-slim';
import axios from "axios";
import { getAllCompanyNAME } from "../../Apis/apis";
import { Input, Button, Radio, notification, Select, Form, Spin } from 'antd';

import currencyData from '../currency.json';
import { useLocation, useNavigate } from "react-router-dom";
import flattenObject from "../../helperFuncitons/flattenObj";
import { LoadingOutlined } from "@ant-design/icons";
const numWords = require('num-words')

const { TextArea } = Input;

const { Option } = Select;
function CreatePO() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [api, contextHolder] = notification.useNotification();
    const [vendorList, setVendorList] = useState([{ _id: "loading..." }]);
    const [loading, setLoading] = useState(false);
    const [loadSet, setLoadSet] = useState(false);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message,
            description
        });
    };
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        PO_Number: '',
        vendorID: '',
        currency: '',
        items: [
            {
                price: 0,
                itemCategory: '',
                material: '',
                quantity: 0,
                plant: '',
                accountAssignmentCategory: '',
                unitOfMeasurement: '',
                GST: "0",
                totalAmount: "",
                HSNCode: ""
            },
        ],
        deliveryAddress: '',
        raw: {
            rawStatus: false,
            rawItems: [
                {
                    itemName: '',
                    itemQuantity: 0,
                },
            ],
        },
    })

    async function removeYourOwnCompany(vList) {
        const companyName = await ls.get("companyname", {decrypt: true});
        console.log(vList, companyName, "cname");
        const notYouVendors = vList.filter((vendor) => vendor._id !== companyName);
        console.log(notYouVendors)
        setVendorList(notYouVendors);
    }

    async function getPODetails() {
        await axios
            .get(
                `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getPurchaseOrders/${state.PO_Number}`,
                {
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN()}`,
                    },
                }
            )
            .then(async (res) => {
                console.log("GET:-Particular-PO", res.data.data);
                // setGetApiData(res.data.data);
                res.data.data.raw = res.data.data.rawmaterial
                setFormData(res.data.data)
                setLoading(false)
                const flat = await flattenObject(res.data.data)
                console.log(flat)
                form.setFieldsValue(flat)
                form.setFieldValue("rawStatusraw", true)
            })
            .catch((err) => {
                console.log("GET:-Particular-PO", err);
            });
    }
    useEffect(() => {
        if (state?.PO_Number) {
            setLoading(true)
            getPODetails()
        }
        axios.get(getAllCompanyNAME + encodeURIComponent(formData.vendorID), { headers: { "Authorization": `Bearer ${AUTH_TOKEN()}` } }).then(resp => {
            removeYourOwnCompany(resp.data)
        }).catch(err => {
            console.error(err, "oopsie")
        })
    }, []);





    const initializeAAFormData = () => {
        setFormData({
            PO_Number: '',
            vendorID: '',
            currency: '',
            items: [
                {
                    price: 0,
                    itemCategory: '',
                    material: '',
                    quantity: 0,
                    plant: '',
                    accountAssignmentCategory: '',
                    unitOfMeasurement: '',
                    GST: "",
                    totalAmount: "",
                    HSNCode: ""
                },
            ],
            deliveryAddress: '',
            raw: {
                rawStatus: false,
                rawItems: [
                    {
                        itemName: '',
                        itemQuantity: 0,
                    },
                ],
            },
        });
    };

    const handleRemoveItem = (index) => {
        const newItems = [...formData.items];
        newItems.splice(index, 1);
        setFormData({
            ...formData,
            items: newItems,
        });
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (index !== undefined) {
            const newItems = [...formData.items];
            newItems[index][name] = value;

            // Log to check values
            console.log('Price:', newItems[index].price);
            console.log('Quantity:', newItems[index].quantity);
            console.log('GST:', newItems[index].GST);

            setFormData({ ...formData, items: newItems });
            form.setFieldValue("totalAmount" + index, calculateTotalAmount(newItems[index]));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    const calculateTotalAmount = (item) => {
        const totalAmount = (item.price * item.quantity) + (item.GST * item.price * item.quantity / 100);
        console.log('Total Amount:', totalAmount); // Log the total amount to the console
        return totalAmount;
    };


    const handleAddItem = () => {
        setFormData({
            ...formData,
            items: [
                ...formData.items,
                {
                    price: 0,
                    itemCategory: '',
                    material: '',
                    quantity: 0,
                    plant: '',
                    accountAssignmentCategory: '',
                    unitOfMeasurement: '',
                    GST: "",
                    totalAmount: "",
                    HSNCode: ""
                },
            ],
        });
    };

    const handleAddRawItem = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            raw: {
                ...prevFormData.raw,
                rawItems: [
                    ...prevFormData.raw.rawItems,
                    {
                        itemName: '',
                        itemQuantity: 0,
                    },
                ],
            },
        }));
    };

  const handleSubmit = async () => {
    try {
        setLoading(true);
        const response = await submitFormData();

        if (response.status === 200) {
            handleSuccess(response.data.PO_Number);
            form.resetFields();
            form.setFieldsValue({
                PO_Number: '', // Reset PO_Number field
                vendorID: '', // Reset vendorID field
                currency: '', // Reset currency field
            });
            navigate("/p-order/lists");
        } else {
            handleError(response);
        }
    } catch (error) {
        handleSubmissionError(error);
    } finally {
        setLoading(false); // Reset loading state
    }
};


    const handleRemoveRawItem = (index) => {
        const newRawItems = [...formData.raw.rawItems];
        newRawItems.splice(index, 1);
        setFormData((prevFormData) => ({
            ...prevFormData,
            raw: {
                ...prevFormData.raw,
                rawItems: newRawItems,
            },
        }));
    };
    const filterOption = (inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;


    const submitFormData = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + ls.get('token', { decrypt: true }),
            },
        };
        try {
            const url = state?.PO_Number
                ? `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/updatePurchaseOrder`
                : 'https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/addPurchaseOrder';
            const method = state?.PO_Number ? 'put' : 'post';
            const response = await axios[method](url, formData, config);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleSuccess = (PO_Number) => {
        openNotificationWithIcon(
            'success',
            'SUBMITTED Successfully',
            'PO created: ' + PO_Number
        );
        initializeAAFormData();
        console.log('Form submitted successfully!');
    };

    const handleError = (response) => {
        openNotificationWithIcon(
            'error',
            'PO not created',
            'error: ' + JSON.stringify(response)
        );
        console.error('Error submitting form.');
    };

    const handleSubmissionError = (error) => {
        openNotificationWithIcon(
            'error',
            'PO not created',
            'error: ' + JSON.stringify(error.response?.data || error.message)
        );
        console.error('Error submitting form:', error);
    };


    // if (loading)
    //     return (<div style={{
    //         height: '100vh',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         position: "absolute",
    //         width: "100%",
    //         zIndex: 100,
    //         background: "#00000099"
    //     }}>
    //         <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    //     </div>)
    // else
        return (
            <>
                <main style={{ backgroundColor: '#80' }}>
                    {contextHolder}
                    {loadSet && <div style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: "absolute",
                        width: "100%",
                        zIndex: 100,
                        background: "#00000099"
                    }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>}
                    <div className='flex' style={{ backgroundColor: '#80' }}>
                        <Sidebar />
                        <section className='dashboard_wrapper' style={{ width: '80%', backgroundColor: '#80' }}>
                            <Navbar title={'Purchase Order / Create Purchase Order'} />
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className='p-fluid formgrid grid'>
                                            <Form
                                                form={form}
                                                initialValues={formData}
                                                onFinish={handleSubmit}
                                                layout="vertical"
                                            >
                                                <div className='field col-12 md:col-6'>

                                                    <Form.Item
                                                        label="PO_Number"
                                                        name="PO_Number"
                                                        rules={[
                                                            { required: true, message: 'Please enter PO_Number' },
                                                        ]}
                                                    >
                                                        <Input id='PO_Number' value={formData.PO_Number}
                                                            onChange={(e) => handleChange(e)} name='PO_Number' />
                                                    </Form.Item>

                                                </div>

                                                <div className='field col-12 md:col-6'>

                                                    <Form.Item
                                                        label="vendorID"
                                                        name="vendorID"
                                                        rules={[
                                                            { required: true, message: 'Please select Vendor ID' },
                                                        ]}
                                                    >

                                                        <Select
                                                            filterOption={filterOption}
                                                            id="vendor-select"
                                                            showSearch
                                                            style={{ width: "75vw" }}
                                                            defaultValue={formData.vendorID || 'Select Vendor'}
                                                            value={formData.vendorID}
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, vendorID: e });
                                                            }}
                                                        >
                                                            <Option value="Select Vendor" disabled>Select Vendor</Option>
                                                            {vendorList.map((vendor) => (
                                                                <Option key={vendor._id} value={vendor._id}>
                                                                    {vendor._id} {vendor.gstNumber !== undefined ? ` - ${vendor.gstNumber}` : ""}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                {/* Other fields */}
                                                <div className='field col-12 md:col-6'>

                                                    <Form.Item
                                                        label="Currency"
                                                        name="currency"
                                                        rules={[
                                                            { required: true, message: 'Please enter Currency' },
                                                        ]}
                                                    >
                                                        {/* <Input id='currency' value={formData.currency}
                                                        onChange={(e) => handleChange(e)} name='currency' /> */}
                                                        <Select
                                                            filterOption={filterOption}
                                                            id="currency-select"
                                                            showSearch
                                                            style={{ width: "75vw" }}
                                                            defaultValue={formData.currency || 'Select Currency'}
                                                            value={formData.currency}
                                                            onChange={(e) => {
                                                                console.log(e, "currency")
                                                                setFormData({ ...formData, currency: e });
                                                            }}
                                                            options={currencyData.map((currency) => ({
                                                                value: `${currency.cc} - ${currency.name}`,
                                                                label: `${currency.cc} - ${currency.name}`,
                                                            }))}
                                                        />

                                                    </Form.Item>
                                                    {/* // options={vendorList.map((vendor) => ({ value: vendor._id, label: vendor._id + (vendor.gstNumber !== undefined ? "  -  " + vendor.gstNumber : "") }))}  */}
                                                </div>

                                                {/* Items */}
                                                <div className='field col-12 md:col-12'>
                                                    <h3>Items:</h3>
                                                </div>

                                                {formData.items.map((item, index) => (
                                                    <div key={index} className='card grid col-12 md:col-5 m-4'>
                                                        {/* Name */}
                                                        <div className='field col-12 md:col-12 pt-5'>
                                                            {/* <label htmlFor={`material${index}`}>Name</label> */}
                                                            <Form.Item
                                                                label="Name"
                                                                name={`material${index}`}
                                                                rules={[
                                                                    { required: true, message: 'Please enter Name' },
                                                                ]}
                                                            >
                                                                <Input
                                                                    id={`material${index}`}
                                                                    value={item.material}
                                                                    name='material'
                                                                    onChange={(e) => {
                                                                        handleChange(e, index)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* HSN Code */}
                                                        <div className='field col-12 md:col-12 pt-5'>
                                                            {/* <label htmlFor={`HSNCode${index}`}>HSNCode</label> */}
                                                            <Form.Item
                                                                label="HSNCode"
                                                                name={`HSNCode${index}`}
                                                            >
                                                                <Input
                                                                    id={`HSNCode${index}`}
                                                                    value={item.HSNCode}
                                                                    name='HSNCode'
                                                                    onChange={(e) => {
                                                                        handleChange(e, index)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* Price */}
                                                        <div className='field col-12 md:col-6 pt-5'>
                                                            {/* <label htmlFor={`price${index}`}>Price</label> */}
                                                            <Form.Item label="Price" name={`price${index}`}>
                                                                <Input
                                                                    type="number"
                                                                    value={item.price}
                                                                    onChange={(e) => {
                                                                        handleChange(e, index);
                                                                    }}
                                                                    name='price'
                                                                />
                                                            </Form.Item>
                                                            {/* <label htmlFor={`quantity${index}`}>Quantity</label> */}
                                                            <Form.Item
                                                                label="Quantity"
                                                                name={`quantity${index}`}
                                                                rules={[
                                                                    { required: true, message: 'Please enter Quantity' },
                                                                ]}
                                                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                                            >
                                                                <Input
                                                                    id={`quantity${index}`}
                                                                    type='text'
                                                                    // style={{ width: 240 }}
                                                                    value={item.quantity}
                                                                    onChange={(e) => {
                                                                        handleChange(e, index)
                                                                        const newItems = [...formData.items];
                                                                        newItems[index]["totalAmount"] = (item.price * item.quantity) + (item.GST * item.price * item.quantity / 100);
                                                                        setFormData({ ...formData, items: newItems });
                                                                    }}
                                                                    name='quantity'
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* Total */}
                                                        <div className='field col-12 md:col-12 pt-5'>
                                                            {/* <label htmlFor={`totalAmount${index}`}>Total</label> */}
                                                            <Form.Item label="Total" name={`totalAmount${index}`}>
                                                                <Input
                                                                    value={item.totalAmount}
                                                                    disabled
                                                                    name={`totalAmount${index}`}
                                                                />
                                                            </Form.Item>


                                                            <Form.Item
                                                                label="GST Slab"
                                                                name={`GST${index}`}  // Adjust this line to match the name used in handleChange
                                                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                                                                rules={[
                                                                    { required: true, message: 'Please Select GST Slab' },
                                                                ]}
                                                            >
                                                                <Select
                                                                    defaultValue="0"
                                                                    onChange={(e) => {
                                                                        console.log(e);
                                                                        const newItems = [...formData.items];
                                                                        newItems[index]["GST"] = e;
                                                                        newItems[index]["totalAmount"] = (item.price * item.quantity) + (e * item.price * item.quantity / 100);
                                                                        setFormData({ ...formData, items: newItems });
                                                                        handleChange({ target: { name: `GST${index}`, value: e } }, index);  // Call handleChange with the correct name
                                                                    }}
                                                                    options={[
                                                                        { value: '0', label: '0%' },
                                                                        { value: '5', label: '5%' },
                                                                        { value: '12', label: '12%' },
                                                                        { value: '18', label: '18%' },
                                                                        { value: '28', label: '28%' },
                                                                    ]}
                                                                />
                                                            </Form.Item>

                                                        </div>
                                                        {/* Total In Words */}
                                                        <div className='field col-12 md:col-12 pt-5'>
                                                            {/* <label htmlFor={`totalinwords${index}`}>Total In Words</label> */}
                                                            <Form.Item label="Total In Words">
                                                                <Input
                                                                    value={item.totalAmount < 99999999 ? numWords(item.totalAmount) : "अम्बानी जी आप"}
                                                                    disabled
                                                                    name={`totalinwords${index}`}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* GST Slab */}
                                                        {/* <div className='field col-12 md:col-6'>

                                                        <Form.Item
                                                            label="GST Slab"
                                                            name={`GST${index}`}
                                                        >
                                                            <Select
                                                                defaultValue="0"
                                                                style={{ width: 120 }}
                                                                name='GST'
                                                                onChange={(e) => {
                                                                    console.log(e)
                                                                    const newItems = [...formData.items];
                                                                    newItems[index]["GST"] = e;
                                                                    newItems[index]["totalAmount"] = (item.price * item.quantity) + (e * item.price * item.quantity / 100);
                                                                    setFormData({ ...formData, items: newItems });
                                                                }}
                                                                options={[
                                                                    { value: '0', label: '0%' },
                                                                    { value: '5', label: '5%' },
                                                                    { value: '12', label: '12%' },
                                                                    { value: '18', label: '18%' },
                                                                    { value: '28', label: '28%' },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </div> */}
                                                        {/* Item Category */}
                                                        <div className='field col-12 md:col-6'>
                                                            {/* <label htmlFor={`itemCategory${index}`}>Item Category</label> */}
                                                            <Form.Item
                                                                label="Item Category"
                                                                name={`itemCategory${index}`}
                                                            >
                                                                <Input
                                                                    id={`itemCategory${index}`}
                                                                    type='text'
                                                                    value={item.itemCategory}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    name='itemCategory'
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* Plant */}
                                                        <div className='field col-12 md:col-6'>
                                                            {/* <label htmlFor={`plant${index}`}>plant</label> */}
                                                            <Form.Item
                                                                label="plant"
                                                                name={`plant${index}`}
                                                            >
                                                                <Input
                                                                    id={`plant${index}`}
                                                                    type='text'
                                                                    value={item.plant}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    name='plant'
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* Account Assignment Category */}
                                                        <div className='field col-12 md:col-6'>
                                                            {/* <label htmlFor={`accountAssignmentCategory${index}`}>Account
                                                            Assignment Category</label> */}
                                                            <Form.Item
                                                                label="Account
                                                            Assignment Category"
                                                                name={`accountAssignmentCategory${index}`}
                                                            >
                                                                <Input
                                                                    id={`accountAssignmentCategory${index}`}
                                                                    type='text'
                                                                    value={item.accountAssignmentCategory}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    name='accountAssignmentCategory'
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/*unitOfMeasurement  */}
                                                        <div className='field col-12 md:col-6'>
                                                            {/* <label
                                                            htmlFor={`unitOfMeasurement${index}`}>unitOfMeasurement</label> */}
                                                            <Form.Item
                                                                label="unitOfMeasurement"
                                                                name={`unitOfMeasurement${index}`}
                                                            >
                                                                <Input
                                                                    id={`unitOfMeasurement${index}`}
                                                                    type='text'
                                                                    value={item.unitOfMeasurement}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    name='unitOfMeasurement'
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <Button onClick={() => handleRemoveItem(index)}
                                                            style={{ marginTop: '0.5rem' }}>Remove Item</Button>

                                                        {/* Add other item fields similar to the above */}
                                                    </div>
                                                ))}

                                                <Button onClick={handleAddItem}>Add Item</Button><br /><br />

                                                Raw Material Status: <Form.Item name="rawStatusraw" ><Radio.Group
                                                    onChange={(e) =>
                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            raw: {
                                                                ...prevFormData.raw,
                                                                rawStatus: e.target.value,
                                                            },
                                                        }))
                                                    }

                                                    buttonStyle='solid'>
                                                    <Radio.Button value={false}>false</Radio.Button>
                                                    <Radio.Button value={true}>true</Radio.Button>
                                                </Radio.Group></Form.Item>

                                                {form.getFieldValue("rawStatusraw")}
                                                {formData.raw.rawStatus ? (
                                                    <>
                                                        {formData.raw.rawItems.map((item, index) => (
                                                            <div key={index} className='card grid col-12 md:col-5 m-4' style={{ marginTop: '0.5rem' }}>
                                                                <div className='field col-12 md:col-12 pt-5'>
                                                                    <label htmlFor={`itemName${index}`}>Item Name</label>
                                                                    <Input
                                                                        id={`itemName${index}`}
                                                                        value={item.itemName}
                                                                        onChange={(e) => {
                                                                            const newItems = [...formData.raw.rawItems];
                                                                            newItems[index].itemName = e.target.value;
                                                                            setFormData((prevFormData) => ({
                                                                                ...prevFormData,
                                                                                raw: {
                                                                                    ...prevFormData.raw,
                                                                                    rawItems: newItems,
                                                                                },
                                                                            }));
                                                                        }}
                                                                        name='itemName'
                                                                    />
                                                                    <div className='field col-12 md:col-6'>
                                                                        <label htmlFor={`itemQuantity${index}`}>Item
                                                                            Quantity</label>
                                                                        <Input
                                                                            id={`itemQuantity${index}`}
                                                                            type='number'
                                                                            value={item.itemQuantity}
                                                                            onChange={(e) => {
                                                                                const newItems = [...formData.raw.rawItems];
                                                                                newItems[index].itemQuantity = e.target.value;
                                                                                setFormData((prevFormData) => ({
                                                                                    ...prevFormData,
                                                                                    raw: {
                                                                                        ...prevFormData.raw,
                                                                                        rawItems: newItems,
                                                                                    },
                                                                                }));
                                                                            }}
                                                                            name='itemQuantity'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Button onClick={() => handleRemoveRawItem(index)}
                                                                    style={{ marginTop: '0.5rem' }}>Remove Raw Item</Button>

                                                                {/* Add other raw item fields similar to the above */}
                                                            </div>
                                                        ))}

                                                        <Button onClick={handleAddRawItem}>Add Raw Item</Button>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {/* Delivery Address */}
                                                <div className='field'>
                                                    <label htmlFor='deliveryAddress'>Delivery Address</label>
                                                    <TextArea
                                                        id='deliveryAddress'
                                                        rows={4}
                                                        value={formData.deliveryAddress}
                                                        onChange={(e) => handleChange(e)}
                                                        name='deliveryAddress'
                                                    />
                                                </div>

                                                {/* Submit button */}
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" style={{ marginTop: '0.5rem' }} loading={loading}>
                                                        {state?.PO_Number ? "Update" : "Submit"}
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </div>
                                </div>

                                {/* Display form data (for debugging) */}
                                {/*<pre>{JSON.stringify(formData, null, 2)}</pre>*/}
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
}


export default CreatePO;

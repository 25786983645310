import React, { useEffect, useState } from "react";
import { Button, Drawer, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addViewDrawerStatus } from "../../app/slices/statusSlice";
import axios from "axios";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "../../utils/authHeader";
import EndDSTable from "../../components/Table/EndDSTable";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import ASNTable from "../../components/Table/ASNTable";
import GRNTable from "../../components/Table/GRNTable";
import CNTable from "../../components/Table/CNTable";
import ls from 'localstorage-slim';
import ViewRawDS from "../DS/ViewRawDS";
import ViewEndDS from "../DS/ViewEndDS";
import ViewDebitNote from "../DebitNote/ViewDebitNote";
import ViewCreditNote from "../CreditNote/ViewCreditNote";
import View_ASN from "../ASN/View_ASN";
import JsPDF from 'jspdf';
import PurchaseOrderPDF from "../../components/PDF/PO";
const ViewDrawer = () => {
  const drawStatus = useSelector((state) => state.statusSlice.viewDraw);
  const viewId = useSelector((state) => state.statusSlice.POViewId);
  const dispatch = useDispatch();
  const [getApiData, setGetApiData] = useState({});
  const [getApiItems, setGetApiItems] = useState([]);
  const [getRawApiItems, setRawGetApiItems] = useState([]);
  const [getApiStatus, setApiStatus] = useState("");
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();


  const columns = [
    {
      title: "itemCategory",
      dataIndex: "itemCategory",
      key: "itemCategory",
    },
    {
      title: "Item",
      dataIndex: "material",
      key: "material",
    },
    {
      title: "plant",
      dataIndex: "plant",
      key: "plant",
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Unit Of Measurement",
      dataIndex: "unitOfMeasurement",
      key: "unitOfMeasurement",
    },
    {
      title: "Account Assignment Category",
      dataIndex: "accountAssignmentCategory",
      key: "accountAssignmentCategory",
    },
    {
      title: "GST (in %)",
      dataIndex: "GST",
      key: "GST",
      render: (text, record) => record.GST + "%"
    },
    {
      title: "HSN Code",
      dataIndex: "HSNCode",
      key: "HSNCode",
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <pre>{Object.keys(record.status).map(key => `${key}: ${record.status[key]}`).join('\n')}</pre>
    },
  ];

  const rawItemTable = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Quantity",
      dataIndex: "itemQuantity",
      key: "itemQuantity",
    },
  ];
  console.log(getRawApiItems, "getRawApiItems")


  const generatePDF = () => {

    const report = new JsPDF('landscape', 'px');
    report.html(document.querySelector('#report')).then((value) => {
      console.log(value, "find")
      report.save('report.pdf');
    });
  }


  useEffect( () => {
    axios
        .get(
            `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getPurchaseOrders/${viewId}`,
            {
              headers: {
                Authorization: `Bearer ${AUTH_TOKEN()}`,
              },
            }
        )
        .then((res) => {
          console.log("GET:-Particular-PO", res);
          setGetApiData(res.data.data);
          setGetApiItems(res.data.data.items);
          setRawGetApiItems(res.data.data.rawmaterial.rawItems)
          setApiStatus(
              res.data.data.status[res.data.data.status.length - 1].status
          );
        })
        .catch((err) => {
          console.log("GET:-Particular-PO", err);
        });
    const storedUserRole = ls.get('userRole', {decrypt: true});
    console.log(storedUserRole, "storedUserRole")
    setUserRole(storedUserRole || ''); // Use an empty string if userRole is not present in local storage

  }, [viewId]);

  const addRMDS = (e) => {
    navigate("/ds/raw-material-delivery-schedule", { state: { viewId: getApiData?.PO_Number } });
  };
  const addEMDS = (e) => {
    navigate("/ds/end-material-delivery-schedule", { state: { viewId: getApiData?.PO_Number } });
  };
  const addASN = (e) => {
    navigate("/CreateAsn", { state: { viewId: getApiData?.PO_Number } });
  };
  const addGRN = (e) => {
    navigate("/CreateGRN", { state: { viewId: getApiData?.PO_Number } });
  };
  const addCreditNote = (e) => {
    navigate("/create/creditnote", { state: { viewId: getApiData?.PO_Number } });
  };
  const addDebitNote = (e) => {
    navigate("/create/debitnote", { state: { viewId: getApiData?.PO_Number } });
  };


  // Conditionally render the "ADD ASN" button based on the user's role
  // const renderAddASNButton = () => {
  //   if (userRole === "Vendor") {
  //     return (
  //       <div>
  //         <Button type="primary" onClick={addASN}>
  //           ADD ASN
  //         </Button>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  // const renderAddGRNButton = () => {
  //   if (userRole === "Buyer") {
  //     return (
  //       <div>
  //         <Button type="primary" onClick={addGRN}>
  //           ADD GRN
  //         </Button>
  //       </div>
  //     );
  //   }
  //   return null;
  // };


  return (
    <>
      <Drawer
        size="large"
        title="Purchase Order Detail"
        onClose={() => {
          dispatch(addViewDrawerStatus(false));
        }}

        placement="right"
        open={drawStatus}
        width="100rem"
      >

        {/*<PurchaseOrderPDF data={getApiData}/>*/}
        {viewId != "" ? (
          <div>
            {/* row1 does start*/}
            <Button value={"download PDF"} onClick={() => {
              navigate("/podownload", { state: { data: getApiData } });
            }}>
              <DownloadOutlined />
            </Button>

            <div
              className="flex item-center justify-between"
              style={{ marginTop: "1rem" }}
            >
              <div style={{ width: "100%" }}>
                <label htmlFor="PO_Number">PO Number</label>
                <Input value={getApiData?.PO_Number} id="PO_Number" disabled />
              </div>
            </div>
            <div className="flex item-center justify-between">

              <div>
                <label htmlFor="buyerID">Buyer ID</label>
                <Input value={getApiData?.buyerID} id="buyerID" disabled />
              </div>
              <div>
                <label htmlFor="currency">Currency</label>
                <Input value={getApiData?.currency} id="currency" disabled />
              </div>
              <div>
                <label htmlFor="deliveryAddress">Delivery Address</label>
                <Input
                  value={getApiData?.deliveryAddress}
                  id="deliveryAddress"
                  disabled
                />
              </div>
            </div>
            {/* row1 end here */}

            {/* row2 does start*/}
            <div
              className="flex item-center justify-between"
              style={{ marginTop: "1rem" }}
            >
              <div>
                <label htmlFor="documentDate">Document Date</label>
                <Input
                  value={getApiData?.documentDate}
                  id="documentDate"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="totalAmount">Total Amount</label>
                <Input
                  value={getApiData?.totalAmount}
                  id="totalAmount"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="vendorID">Vendor ID</label>
                <Input value={getApiData?.vendorID} id="vendorID" disabled />
              </div>
            </div>
            {/* row2 end here */}

            {/* row3 does start*/}
            <div
              className="flex item-center justify-between"
              style={{ marginTop: "1rem" }}
            >
              <div style={{ width: "50%" }}>
                <label htmlFor="status">Status</label>
                <Input value={getApiStatus} id="status" disabled />
              </div>
              <div style={{ width: "50%" }}>
                <label htmlFor="status">Raw DS</label>
                <Input value={getApiData?.rawmaterial?.rawStatus} id="status" disabled />
              </div>
            </div>
            {/* row3 end here */}

            {/* items table */}
            <h3  style={{ marginTop: "1rem" }}>Items</h3>
            <Table
              style={{ marginTop: "1rem" }}
              dataSource={getApiItems}
              columns={columns}
            />
            <h3  style={{ marginTop: "1rem" }}>Raw Items</h3>
            <Table
              style={{ marginTop: "1rem" }}
              dataSource={getRawApiItems}
              columns={rawItemTable}
            />
            {/* raw material section goes here */}
            <section>
              <h3>Raw Material Delivery Schedule</h3>
              <ViewRawDS PO_Number={getApiData?.PO_Number} />

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button type="primary" onClick={addRMDS} disabled={userRole === 'Vendor'}>
                  <PlusOutlined />
                  ADD Raw Delivery Schedule
                </Button>
              </div>
            </section>
            {/* raw material section end here */}

            {/* end material section goes here */}
            <section style={{ marginTop: "2rem" }}>
              <h3>End Material Delivery Schedule</h3>
              <ViewEndDS PO_Number={getApiData?.PO_Number} />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button type="primary" onClick={addEMDS} >
                  <PlusOutlined />
                  Add End Delivery Schedule
                </Button>
              </div>
            </section>
            {/* end material section end here */}

            {/* asn  section goes here */}
            <section style={{ marginTop: '2rem' }}>
              <h3>ASN</h3>
              <View_ASN PO_Number={getApiData?.PO_Number} />
              <Button type="primary" onClick={addASN} disabled={userRole === 'Buyer'}>
                <PlusOutlined />
                Add
              </Button>
            </section>

            {/* GRN section goes here */}
            <section style={{ marginTop: '2rem' }}>
              <h3>GRN</h3>
              <GRNTable PO_Number={getApiData?.PO_Number}>

              </GRNTable>
              <Button type="primary" onClick={addGRN} disabled={userRole === 'Vendor'}>
                <PlusOutlined />
                Add
              </Button>
            </section>
            {/* grn section end here */}

            {/* credit note section goes here */}
            <section style={{ marginTop: "2rem" }}>
              <h3>Credit Notes</h3>

              <ViewCreditNote PO_Number={getApiData?.PO_Number} />
              <Button type="primary" onClick={addCreditNote} disabled={userRole === 'Buyer'}>
                <PlusOutlined />
                Add
              </Button>

            </section>
            {/* credit note section end here */}

            {/* debit note section goes here */}
            <section style={{ marginTop: "2rem" }}>
              <h3>Debit Notes</h3>
              <ViewDebitNote PO_Number={getApiData?.PO_Number} />
              <Button type="primary" onClick={addDebitNote} disabled={userRole === 'Vendor'}>
                <PlusOutlined />
                Add
              </Button>
            </section>
            {/* debit note section end here */}
          </div>
        ) : (
          <>dsd</>
        )}
      </Drawer>
    </>
  );
};
export default ViewDrawer;

import React from 'react';
import "./DateDiv.css";
import ls from "localstorage-slim";

class DateDiv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            role: ls.get("userRole", { decrypt: true })
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                date: new Date(),
                role: ls.get("userRole", { decrypt: true })
            });
        }, 1);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let millisecondsClass = "";

        // Conditionally set the class for milliseconds based on the user's role
        if (this.state.role === "Vendor") {
            millisecondsClass = "milliseconds-blue";
        } else if (this.state.role === "Buyer") {
            millisecondsClass = "milliseconds-green";
        }

        return (
            <div className="date-div">
                <div>{this.state.date.toLocaleString()}</div>
                <span className={millisecondsClass}>.{this.state.date.getMilliseconds()}</span>
            </div>
        );
    }
}

export default DateDiv;

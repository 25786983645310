import React, { useEffect, useState } from "react";
import { getRawDSByPo } from "../../Apis/DS_apis";
import ls from "localstorage-slim";
import axios from "axios";
import { Table, Divider } from "antd";

const ViewRawDS = ({ PO_Number }) => {
    const [getRawDsList, setRawDsList] = useState([]);
    const [getTableData, setTableData] = useState([]);

    useEffect(() => {
        axios.get(getRawDSByPo + encodeURIComponent(PO_Number), {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(res => {
            console.log("res", res);
            setRawDsList(res.data);
        }).catch((err) => {
            console.error("pubgmobile", err);
        })
    }, [PO_Number])

    useEffect(() => {
        // Assuming getRawDsList is a state variable holding your data
        const formattedData = [];

        getRawDsList.forEach((rawDs) => {
            if (rawDs.rawmaterial.some(item => item.fulfillment.lotwise.length > 0)) {
                // If lotwise data exists, create a table for it
                const lotwiseData = [];
                rawDs.rawmaterial.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.lotwise.forEach((lot) => {
                        lotwiseData.push({
                            itemName: lot.itemName,
                            key: lot._id,
                            lot_code: lot.lot_code,
                            packing_size: lot.packing_size,
                            lot_number_from: lot.lot_number_from,
                            lot_number_end: lot.lot_number_end,
                            price: lot.price,
                            quantity: lot.quantity,
                            totalAmount: lot.totalAmount,
                            delivery_date: lot.delivery_date,
                            status: rawDs.status[0].status // Assuming you want the status from rawDs
                        });
                    });
                });

                formattedData.push({
                    type: 'lotwise',
                    data: lotwiseData,
                    title: `Lotwise Table - ${rawDs.PO_Number}`
                });
            }

            if (rawDs.rawmaterial.some(item => item.fulfillment.itemwise.length > 0)) {
                // If itemwise data exists, create a table for it
                const itemwiseData = [];
                rawDs.rawmaterial.forEach((rawmaterial) => {
                    rawmaterial.fulfillment.itemwise.forEach((item) => {
                        itemwiseData.push({
                            key: item._id,
                            itemNumber: item.itemNumber,
                            itemName: item.itemName,
                            price: item.price,
                            quantity: item.quantity,
                            totalAmount: item.totalAmount,
                            delivery_date: item.delivery_date,
                            status: rawDs.status[0].status // Assuming you want the status from rawDs
                        });
                    });
                });

                formattedData.push({
                    type: 'itemwise',
                    data: itemwiseData,
                    title: `Itemwise Table - ${rawDs.PO_Number}`
                });
            }
        });

        setTableData(formattedData);
    }, [getRawDsList]);
    return (
        <>
            <h4 style={{ textAlign: 'center' }}> Raw Material Delivery Schedule </h4>
            <Divider />
            {getTableData.map((table, index) => (
                <div key={index}>
                    <h5>{table.title}</h5>
                    <Table
                        style={{ marginTop: "1rem" }}
                        dataSource={table.data}
                        columns={table.type === 'lotwise' ? lotwiseColumns : itemwiseColumns}
                    />
                </div>
            ))}
        </>
    )
}

const lotwiseColumns = [
    {
        title: 'Item',
        dataIndex: 'itemName',
        key: 'itemName',
    },
    {
        title: 'Lot Code',
        dataIndex: 'lot_code',
        key: 'lot_code',
    },
    {
        title: 'Packing Size',
        dataIndex: 'packing_size',
        key: 'packing_size',
    },
    {
        title: 'lot_number_from',
        dataIndex: 'lot_number_from',
        key: 'lot_number_from',
    },
    {
        title: 'Lot number_end',
        dataIndex: 'lot_number_end',
        key: 'lot_number_end',
    },
    // {
    //     title: 'price',
    //     dataIndex: 'price',
    //     key: 'price',
    // },
    {
        title: 'quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    // {
    //     title: 'totalAmount',
    //     dataIndex: 'totalAmount',
    //     key: 'totalAmount',
    // },
    {
        title: 'ScheduleDeliveryDate',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const itemwiseColumns = [
    {
        title: 'Item Number',
        dataIndex: 'itemNumber',
        key: 'itemNumber',
    },
    {
        title: 'Item Name',
        dataIndex: 'itemName',
        key: 'itemName',
    },
    // {
    //     title: 'Price',
    //     dataIndex: 'price',
    //     key: 'price',
    // },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    // {
    //     title: 'Total Amount',
    //     dataIndex: 'totalAmount',
    //     key: 'totalAmount',
    // },
    {
        title: 'Delivery Date',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];


export default ViewRawDS;
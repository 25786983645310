import React from 'react';
import {CloudUploadOutlined} from "@ant-design/icons";
import {Button, notification} from "antd";
import ls from "localstorage-slim";
import axios from "axios";
class CSVUploader extends React.Component {
    state = {
        api: notification,
        contextHolder: null,
    };

    openNotificationWithIcon = (type, message, description) => {
        this.state.api[type]({
            message,
            description,
        });
    };
    downloadCSVFile = () => {
        const csvData = `PO_Number,vendorID,currency,deliveryAddress,price,itemCategory,material,quantity,plant,accountAssignmentCategory,unitOfMeasurement,rawStatus
PO_ABCD81,tarunInfotech,USD,Ahmedabad,100,Grocery,material,10,plant,accountAssignmentCategory,KGS,false
PO_ABCD81,tarunInfotech,EUR,Ahmedabad,75,Clothing,fabric,5,factory,accountCategory1,meters,false,
PO_00AA,raone,GBP,Ahmedabad,120,Electronics,plastic,8,warehouse,accountCategory2,pieces,true
PO_00AA,raone,USD,Ahmedabad,200,Stationery,paper,15,office,accountCategory3,reams,false,`;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'demo.csv';
        link.click();
    };
    handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const csv = event.target.result;
            const lines = csv.split('\n');
            const headers = lines[0].split(',');

            const jsonData = {};

            for (let i = 1; i < lines.length; i++) {
                const data = lines[i].split(',');
                const entry = {};
                const poNumber = data[0]; // Get PO_Number
                const vendorID = data[1]; // Get vendorID
                const currency = data[2]
                const deliveryAddress = data[data.length - 1].trim(); // Get deliveryAddress from the last column
                for (let j = 3; j < headers.length - 1; j++) {
                    entry[headers[j]] = isNaN(data[j]) ? data[j] : parseFloat(data[j]);
                }
                const rawStatus = entry['rawStatus']; // Get rawStatus
                delete entry['PO_Number']; // Remove PO_Number from item object
                delete entry['vendorID']; // Remove vendorID from item object
                delete entry['rawStatus'];
                delete entry['currency']// Remove rawStatus from item object

                if (!jsonData[poNumber]) {
                    jsonData[poNumber] = { PO_Number: poNumber, vendorID: vendorID, currency:currency, createdAt: new Date(), deliveryAddress: deliveryAddress, items: [] };
                }

                if (!jsonData[poNumber].raw) {
                    jsonData[poNumber].raw = { rawStatus: rawStatus };
                }

                jsonData[poNumber].items.push(entry);
            }

            const jsonOutput = Object.values(jsonData);
            console.log(jsonOutput)
            jsonOutput.map(async (po) => {
                await axios.post("https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/addPurchaseOrder", po, {
                    headers: {
                        Authorization:
                            "Bearer " + ls.get("token", {decrypt: true}),
                    },
                }).then((res) => {
                    this.openNotificationWithIcon(
                        "success",
                        "SUBMITTED Successfully",
                        "PO created: " + res.data.PO_Number
                    );
                    console.log(res, "ressssofPO")
                }).catch((err) => {
                    this.openNotificationWithIcon(
                        "error",
                        "PO not created",
                        "error: " + JSON.stringify(err.response.data)
                    );
                    console.error("err", err)
                })
            })
            console.log(JSON.stringify(jsonOutput, null, 2));
        };

        reader.readAsText(file);
    };

    render() {
        return (
            <div>
                {this.state.contextHolder}
                <Button type="primary" htmlType=""
                    disabled={ls.get('userRole', { decrypt: true }) === 'Vendor'}
                    onClick={() => {
                        console.log(document.getElementById('getFile'));
                        document.getElementById('getFile').click();
                }}>
                    <CloudUploadOutlined />
                    Bulk Upload PO
                    <input type="file" accept=".csv" id="getFile" style={{display: "none"}} onChange={this.handleFileUpload}  />
                </Button>
                <Button
                    disabled={ls.get('userRole', { decrypt: true }) === 'Vendor'}
                    type="primary" onClick={this.downloadCSVFile}>
                    Download DEMO CSV
                </Button>
            </div>
        );
    }
}

export default CSVUploader;

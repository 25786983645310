import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { AUTH_TOKEN } from "../../utils/authHeader";
import flattenObject from "../../components/hx/FlattenObj";
import axios from "axios";
import {
  Select,
  Input,
  Button,
  DatePicker,
  notification,
  Form,
  Radio,
  Card,
  Row,
  Col,
  Divider,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { createEndDS } from "../../Apis/DS_apis";
const numWords = require('num-words')

function EndMaterialDS() {
  const [listData, setListData] = useState([]);
  const { state } = useLocation();
  const [poList, setPoList] = useState([]);
  const [endDSDetails, setEndDSDetails] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemQuatity, setSIQ] = useState(0);
  const [loadSet, setLoadSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    PO_Number: "",
    fulfillmentType: "lotwise", // Default to "lotwise"
    fulfillment: {
      lotwise: [
        {
          item: "",
          description: "",
          quantity: "",
          scheduledDeliveryDate: null,
          lot: "",
          lot_number_from: "",
          lot_number_end: "",
          lot_code: "",
          packing_size: "",
          price: "",
          amount: "",
        },
      ],
      itemwise: [
        {
          item: "",
          quantity: "",
          itemNumber: "",
          scheduledDeliveryDate: null,
          description: "",
          unitPrice: "",
          total: "",
        },
      ],
    },
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const allFields = form.getFieldsValue();
    console.log('All Form fields and values:', allFields);
  }, [formData])

  useEffect(() => {
    console.log(location, "location");
    if (location.state && location.state.viewId) {
      // Update the PO_Number in the form data
      console.log("location.state.viewId", location.state.viewId);
      setFormData((prevState) => ({
        ...prevState,
        PO_Number: location.state.viewId,
      }));
    }
    axios
      .get(
        "https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders",
        {
          headers: {
            Authorization: "Bearer " + ls.get("token", { decrypt: true }),
          },
        }
      )
      .then((res) => {
        console.log("GET:ALL-P-Order-ha", res.data.data);
        setListData(res.data.data);
        if (res.data.data.length !== 0) {
          const dropdownData = res.data.data.map((po) => ({
            value: po.PO_Number,
            label: `${po.buyerID}---${po.PO_Number}---${po.currency}---${po.deliveryAddress}---${po.documentDate}---${po.totalAmount}---${po.vendorID}`,
          }));
          setPoList(dropdownData);
        }
      })
      .catch((err) => {
        console.log("GET:ALL-P-Order", err);
      });
    form.resetFields();
  }, [form]);


  async function getPODetails() {
    try {
      await axios
        .get(
          `https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/getDeliverySchedule/${state.PO_Number}`,
          {
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN}`,
            },
          }
        )
        .then(async (res) => {
          console.log("GET: Particular PO Response", res);
          // setGetApiData(res.data.data);
          // res.data.data.raw = res.data.data.rawmaterial;
          // setFormData(res.data.data);
          // setLoading(false);
          // const flat = await flattenObject(res.data.data);
          // console.log("Flattened Data:", flat);
          // form.setFieldsValue(flat);
          // form.setFieldValue("rawStatusraw", true);
        })
        .catch((err) => {
          console.log("GET: Particular PO Error", err);
        });
    } catch (error) {
      console.error("Error in getPODetails:", error);
    }
  }


  useEffect(() => {
    if (state?.PO_Number) {
      setLoading(true)
      getPODetails()
    }

  }, []);
  useEffect(() => {
    const itemList = listData?.filter((data) => {
      console.log("ffData", data)
      console.log("fffffff", formData.PO_Number, data.PO_Number)
      return formData.PO_Number == data.PO_Number ? data : "";
    })

    setItemsList(itemList);
    const datas = itemList?.map((asd) => (
      asd?.items?.map((itemn) => {
        console.log("nmb", itemn)
        return {
          label: itemn?.material,
          quantity: itemn?.quantity,
          value: itemn?.material,
        }
      })
    ))
    setItemsList(datas[0])
  }, [formData.PO_Number, poList])

  const sendRawMaterialAPI = async (sendData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + ls.get('token', { decrypt: true }),
      },
    };
    try {
      const url = state?.PO_Number
        ? `https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/updateDeliverySchedules`
        : 'https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/createDeliverySchedules';
      const method = state?.PO_Number ? 'put' : 'post';
      const response = await axios[method](url, sendData, config);

      return response.data; // Return the response data
    } catch (err) {
      throw err; // Throw error for handling in the caller function
    }
  };

  const sendRawMaterial = async () => {
    try {
      setLoading(true);
      let sendData = {
        PO_Number: formData.PO_Number,
        items: [],
        certificate: "CertificateXYZ",
      };

      if (formData.fulfillmentType === "lotwise") {
        for (let i = 0; i < formData.fulfillment.lotwise.length; i++) {
          let item = formData.fulfillment.lotwise[i];
          sendData.items.push({
            item: item.item,
            scheduledDeliveryDate: item.scheduledDeliveryDate,
            fulfillment: {
              lotwise: [
                {
                  lot_number_from: item.lot_number_from,
                  lot_number_end: item.lot_number_end,
                  lot_code: item.lot_code,
                  packing: item.packing_size,
                  packing_size: item.packing_size,
                  delivery_date: item.scheduledDeliveryDate,
                  quantity: item.quantity,
                  price: item.price,
                  description: item.description,
                },
              ],
            },
          });
        }
      } else if (formData.fulfillmentType === "itemwise") {
        for (let i = 0; i < formData.fulfillment.itemwise.length; i++) {
          let item = formData.fulfillment.itemwise[i];
          sendData.items.push({
            item: item.item,
            scheduledDeliveryDate: item.scheduledDeliveryDate,
            fulfillment: {
              itemwise: [
                {
                  itemNumber: item.itemNumber,
                  itemName: item.item,
                  delivery_date: item.scheduledDeliveryDate,
                  price: item.unitPrice,
                  quantity: item.quantity,
                  description: item.description,
                },
              ],
            },
          });
        }
      }

      console.log(sendData, "send data end ds");
      console.log("Form Data end ds:", formData);

      // Call the API function and wait for the response
      const data = await sendRawMaterialAPI(sendData);

      openNotificationWithIcon(
        "success",
        "SUBMITTED Successfully",
        "END deliver schedule saved in database for PO: " + data.deliverySchedule.PO_Number
      );

      form.resetFields();
      data.deliverySchedule.PO_Number && navigate("/list/EndDs");
    } catch (err) {
      console.error(err);
      openNotificationWithIcon(
        "error",
        "ERROR",
        "Error Submitting EndDS Form"
      );
    } finally {
      setLoading(false); // Set loading back to false after API call completes
    }
  };

  // const sendRawMaterial = async () => {
  //   // Log the form data for testing purposes
  //   try {
  //     setLoading(true);
  //     let sendData = {
  //       PO_Number: formData.PO_Number,
  //       items: [],
  //       certificate: "CertificateXYZ",
  //     };

  //     if (formData.fulfillmentType === "lotwise") {
  //       for (let i = 0; i < formData.fulfillment.lotwise.length; i++) {
  //         let item = formData.fulfillment.lotwise[i];
  //         sendData.items.push({
  //           item: item.item,
  //           scheduledDeliveryDate: item.scheduledDeliveryDate,
  //           fulfillment: {
  //             lotwise: [
  //               {
  //                 lot_number_from: item.lot_number_from,
  //                 lot_number_end: item.lot_number_end,
  //                 lot_code: item.lot_code,
  //                 packing: item.packing_size,
  //                 packing_size: item.packing_size,
  //                 delivery_date: item.scheduledDeliveryDate,
  //                 quantity: item.quantity,
  //                 price: item.price,
  //                 description: item.description,
  //               },
  //             ],
  //           },
  //         });
  //       }
  //     } else if (formData.fulfillmentType === "itemwise") {
  //       for (let i = 0; i < formData.fulfillment.itemwise.length; i++) {
  //         let item = formData.fulfillment.itemwise[i];
  //         sendData.items.push({
  //           item: item.item,
  //           scheduledDeliveryDate: item.scheduledDeliveryDate,
  //           fulfillment: {
  //             itemwise: [
  //               {
  //                 itemNumber: item.itemNumber,
  //                 itemName: item.item,
  //                 delivery_date: item.scheduledDeliveryDate,
  //                 price: item.unitPrice,
  //                 quantity: item.quantity,
  //                 description: item.description,
  //               },
  //             ],
  //           },
  //         });
  //       }
  //     }

  //     console.log(sendData, "send data end ds");
  //     console.log("Form Data end ds:", formData);

  //     const response = await axios
  //       .post(createEndDS, sendData, {
  //         headers: {
  //           Authorization: "Bearer " + ls.get("token", { decrypt: true }),
  //         },
  //       })
  //       .then((res) => {
  //         openNotificationWithIcon(
  //           "success",
  //           "SUBMITTED Successfully",
  //           "END deliver schedule saved in database for PO: " + res.data.deliverySchedule.PO_Number
  //         );


  //         form.resetFields();
  //         res.data.deliverySchedule.PO_Number && navigate("/list/EndDs")
  //       })
  //   }


  //   catch (err) {
  //     console.error(err);
  //     openNotificationWithIcon(
  //       "error",
  //       "could not submit form",
  //       "could not END Deliveryschedule IN DATABASE"
  //     );
  //   } finally {
  //     setLoading(false); // Set loading back to false after API call completes
  //   }
  // };

  const handlePOSelection = async (selectedPO, index) => {
    const headers = {
      Authorization: "Bearer " + ls.get("token", { decrypt: true }),
    };

    try {
      // Fetch details for the selected purchase order
      console.log('Fetching purchase order details...');
      const purchaseOrderResponse = await fetch(
        `https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders`,
        { headers }
      );

      if (purchaseOrderResponse.ok) {
        // Purchase order details fetched successfully
        const purchaseOrderDetails = await purchaseOrderResponse.json();
        console.log('Purchase Order details:', purchaseOrderDetails);

        // Check if purchaseOrderDetails.data is an array
        if (Array.isArray(purchaseOrderDetails.data)) {
          // Fetch end delivery schedule details for the selected purchase order
          console.log('Fetching end delivery schedule details...');
          const endDSResponse = await fetch(
            `https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/getDeliverySchedule/${selectedPO}`,
            { headers }
          );


          if (endDSResponse.ok) {
            // End delivery schedule details fetched successfully
            const endDSDetails = await endDSResponse.json();
            console.log('End Delivery Schedule details:', endDSDetails, endDSDetails.length);


            // Log purchase order items for reference
            console.log('Purchase Order Items details:', purchaseOrderDetails.data);

            if (endDSDetails.length === 0) {
              // No end delivery schedule details, handle separately
              const matchingPOItem = purchaseOrderDetails.data.find(poItem => poItem.PO_Number === selectedPO);
              console.log("matchingPOItem endDSDetails.length === 0", matchingPOItem);
              if (matchingPOItem && Array.isArray(matchingPOItem.items)) {
                console.log('Matching PO Items details endDSDetails.length === 0:', matchingPOItem.items);
                const selectedItem = formData.fulfillment[formData.fulfillmentType][index];
                console.log("selectedItem endDSDetails.length === 0", selectedItem.item, formData.fulfillmentType, index);
                if (!selectedItem) {
                  console.error('Error: selectedItem is undefined. Cannot proceed.endDSDetails.length === 0)');
                  return;
                }

                const selectedPOItem = matchingPOItem.items.find(poItem => poItem.material === selectedItem.item);
                console.log("selectedPOItem endDSDetails.length === 0", selectedPOItem);
                if (!selectedPOItem) {
                  console.error('Error: Selected item not found in the purchase order details.endDSDetails.length === 0');
                  return;
                }
                console.log("selectedItem endDSDetails.length === 0", selectedItem, formData.fulfillmentType, index);
                console.log("matchingPOItem endDSDetails.length === 0", matchingPOItem);
                console.log('Matching PO Items details endDSDetails.length === 0:', matchingPOItem.items);


                const totalQuantityInPO = selectedPOItem.quantity;
                console.log("totalQuantityInPO endDSDetails.length === 0", totalQuantityInPO);
                const remainingQuantity = totalQuantityInPO;
                console.log("remainingQuantity endDSDetails.length === 0", remainingQuantity)
                setRemainingQuantity(remainingQuantity);


              } else {
                console.error('Error: Purchase order items or quantity not found in the response details.endDSDetails.length === 0');
                return;
              }
            }

            // Calculate remaining quantity for each item in the purchase order
            const updatedEndDSDetails = endDSDetails.map((endDSItem, index) => { // <-- index passed here
              const matchingPOItem = purchaseOrderDetails.data.find(poItem => poItem.PO_Number === selectedPO);

              // Log details for each iteration
              console.log('End DS Item details:', endDSItem);
              console.log('Matching PO Item details:', matchingPOItem);

              if (matchingPOItem && Array.isArray(matchingPOItem.items)) {
                console.log('Matching PO Items details:', matchingPOItem.items);

                // Find the selected item from the form (you need to adjust this based on your data structure)
                const selectedItem = formData.fulfillment[formData.fulfillmentType][index].item;
                console.log("selectedItem detail:", selectedItem)
                // Check if selectedItem is present in the purchase order items
                const selectedPOItem = matchingPOItem.items.find(poItem => poItem.material === selectedItem);
                console.log("selectedPOItem detail:", selectedPOItem)
                if (!selectedPOItem) {
                  console.error('Error: Selected item not found in the purchase order details.');
                  return endDSItem;
                }

                // Calculate totalQuantityInPO and remainingQuantity for the selected item separately
                let totalQuantityInPO = selectedPOItem.quantity;
                console.log("totalQuantityInPO detail:", totalQuantityInPO)
                if (endDSDetails.length === 0) {
                  // No end delivery schedule details, set remainingQuantity to totalQuantityInPO
                  const remainingQuantity = totalQuantityInPO;
                  setRemainingQuantity(remainingQuantity);

                  // Assign totalQuantityInPO the value of remainingQuantity
                  totalQuantityInPO = remainingQuantity;

                  return endDSItem; // Prevent further processing
                }

                // Calculate totalEndDSQuantity for the selected item
                const totalEndDSQuantity = endDSDetails.reduce((total, endDSItem) => {
                  const itemTotal = endDSItem.items.reduce((totalItems, currentItem) => {
                    if (currentItem.item === selectedItem) { // Check if the item matches the selected item
                      const fulfillmentItemwiseQuantity = currentItem.fulfillment?.itemwise?.reduce((total, item) => total + item.quantity, 0) || 0;
                      const lotwiseQuantity = currentItem.fulfillment?.lotwise?.reduce((total, item) => total + item.quantity, 0) || 0;
                      const itemTotal = fulfillmentItemwiseQuantity + lotwiseQuantity;
                      console.log(`End DS Item ${currentItem.item} Quantity details:`, itemTotal);
                      return totalItems + itemTotal;
                    }
                    return totalItems;
                  }, 0);
                  console.log(`Total endDS Quantity for ${selectedItem} details:`, itemTotal);
                  return total + itemTotal;
                }, 0);


                console.log(`Total endDS Quantity for detail${endDSItem.PO_Number} details:`, totalEndDSQuantity);

                const remainingQuantity = totalQuantityInPO - totalEndDSQuantity;
                console.log("Remaining Quantity details:", remainingQuantity);

                // Set remainingQuantity in the component state for the selected item
                setRemainingQuantity(remainingQuantity);

                const enteredValue = form.getFieldValue(`quantity${index}`);
                if (enteredValue > remainingQuantity) {
                  // Display an alert and prevent further processing
                  alert(`Please enter a value less than or equal to the remaining quantity: ${remainingQuantity}`);
                  form.setFieldValue(`quantity${index}`, remainingQuantity > 0 ? remainingQuantity : 0);
                  return endDSItem; // Prevent further processing
                }

                // Update the input field value for the selected item
                return { ...endDSItem, quantity: remainingQuantity > 0 ? remainingQuantity : 0 };
              } else {
                // Log an error if items or quantity are undefined
                console.error('Error: Purchase order items or quantity not found in the response details.');
              }
              return endDSItem;
            });

            // Update state with the updated end delivery schedule details
            console.log('Updated End Delivery Schedule details:', updatedEndDSDetails);
            setEndDSDetails(updatedEndDSDetails);
          } else {
            // Handle error response for end delivery schedule
            console.error('Error fetching end delivery schedule details. Status:', endDSResponse.status);
            // Additional error handling logic if needed
          }
        } else {
          // Handle the case where purchaseOrderDetails.data is not an array
          console.error('Error: Purchase order details are not in the expected format.');
        }
      } else {
        // Handle error response for purchase order
        console.error('Error fetching purchase order details. Status:', purchaseOrderResponse.status);
        // Additional error handling logic if needed
      }
    } catch (error) {
      // Handle fetch error (e.g., network issues)
      console.error('Error fetching data details:', error);
      // Additional error handling logic if needed
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleFulfillmentTypeChange = (e, index) => {
    // Reset the form fields
    form.resetFields([`item_${index}`]);

    // Update the fulfillmentType after resetting fields
    setFormData((prevData) => ({
      ...prevData,
      fulfillmentType: e.target.value,
    }));
  };

  // Function to update fulfillment data with the form field changes
  const handleFulfillmentChange = (index, field, value) => {
    if (value.quantity <= remainingQuantity) {
      console.log("why change?", value);

      console.log(form.getFieldsValue(), "form.values");

      // Calculate the amount based on quantity and price
      const amount = value.quantity * value.price;
      const total = value.quantity * value.unitPrice;
      // Update the rest of the form data
      setFormData((prevData) => {
        const newFulfillment = { ...prevData.fulfillment };
        newFulfillment[field][index] = { ...value, amount }; // Include the calculated amount
        newFulfillment[field][index] = { ...value, total };
        // Update the form fields
        form.setFieldsValue({
          [`amount${index}`]: amount,
        });
        form.setFieldsValue({
          [`amountWord${index}`]: numWords(amount),
        });
        form.setFieldsValue({
          [`total${index}`]: total,
        });
        form.setFieldsValue({
          [`totalWords${index}`]: numWords(total),
        });

        return {
          ...prevData,
          fulfillment: newFulfillment,
        };
      });
    } else {
      console.log(form.getFieldsValue());
      // window.alert(`Please select under or equal to ${remainingQuantity}`);
    }
  };

  // Function to add a new row for lotwise or itemwise
  const handleAddRow = () => {
    setFormData((prevData) => {
      const newFulfillment = { ...prevData.fulfillment };
      newFulfillment[formData.fulfillmentType].push({
        [formData.fulfillmentType === "lotwise" ? "lot" : "item"]: "",
        quantity: "",
        scheduledDeliveryDate: null,
      });
      return {
        ...prevData,
        fulfillment: newFulfillment,
      };
    });
  };

  // Function to remove a row for lotwise or itemwise
  const handleRemoveRow = (index) => {
    setFormData((prevData) => {
      const newFulfillment = { ...prevData.fulfillment };
      newFulfillment[formData.fulfillmentType].splice(index, 1);
      return {
        ...prevData,
        fulfillment: newFulfillment,
      };
    });
  };

  return (
    <>
      {contextHolder}
      <main style={{ backgroundColor: "#80" }}>
        {/* {loadSet && <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          width: "100%",
          zIndex: 100,
          background: "#00000099"
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>} */}
        <div className="flex" style={{ backgroundColor: "#80" }}>
          <Sidebar />

          {/* Wrapper goes here */}
          <section
            className="dashboard_wrapper"
            style={{ width: "80%", backgroundColor: "#80" }}
          >

            <Navbar title={'End DS / Create End DS'} />

            {/* po lists table goes here */}
            <section
              className="lists_table"
              style={{
                background: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                width: "98%",
                margin: "1rem auto",
                padding: "1rem",
              }}
            >
              <h4 style={{ textAlign: 'center' }}>End Material Deliverey Schedule</h4>
              <Divider />
              {/* dropdown */}
              <div>
                {/* <label htmlFor="po-list" style={{ marginLeft: "1rem" }}>
                  Purchase Order:
                </label> */}
                <Form.Item htmlFor="po-list" label="Purchase Order">
                  <Select
                    filterOption={filterOption}
                    id="po-list"
                    showSearch
                    defaultValue="Search PO Number"
                    value={formData.PO_Number}
                    onChange={(e) => {
                      setFormData((prevState) => ({ ...prevState, PO_Number: e }));
                      handlePOSelection(e);
                    }}
                    options={poList}
                  />
                </Form.Item>
              </div>

              {/* end material */}
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                  marginTop: "2rem",
                  textAlign: "center",
                }}
              >
                {/* <h1>End Material Deliverey Schedule</h1> */}

                {/* Itemwise or Lotwise Fields */}

                <Form.Item label="Fulfillment Type">
                  <Radio.Group
                    value={formData.fulfillmentType}
                    onChange={handleFulfillmentTypeChange}
                  >
                    <Radio.Button value="lotwise">Lotwise</Radio.Button>
                    <Radio.Button value="itemwise">Itemwise</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form
                  form={form}
                  onFinish={sendRawMaterial}
                >

                  {formData.fulfillmentType === "lotwise" ? (
                    <Card
                      bordered={true}
                      style={{ border: "1px solid lightgrey" }}
                    >
                      <Form.Item label="Lotwise">
                        <Button type="primary" style={{ marginBottom: "1rem", marginTop: "-1rem", marginLeft: "90%" }} onClick={handleAddRow}>
                          Add Lot
                        </Button>
                        {formData.fulfillment[formData.fulfillmentType].map(
                          (lot, index) => (
                            <div key={index}>
                              <Row gutter={16}>
                                {/* Left side (4 columns) */}
                                <Col span={12}>
                                  {/* 1 */}
                                  <Form.Item
                                    name={`item_${index}`}
                                    rules={[
                                      { required: true, message: 'Please select Item..!' },
                                    ]}
                                  >
                                    <Select
                                      defaultValue={lot.item || undefined}
                                      options={itemsList}
                                      onChange={(selectedItem) => {
                                        const selectedValue = selectedItem;
                                        console.log(itemsList, selectedValue);
                                        const selectedQuantity = itemsList.find(item => item.value === selectedValue)?.quantity || 0;

                                        console.log("qty", selectedQuantity);

                                        // Update quantity value based on the selected item
                                        setSIQ(selectedQuantity);

                                        // Update the form state with the selected item
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            item: selectedItem,
                                          }
                                        );

                                        // Call handlePOSelection to set remaining quantity logic
                                        handlePOSelection(formData.PO_Number, index);
                                      }}
                                    />
                                  </Form.Item>
                                  {/* 2 */}
                                  <Form.Item
                                    name={`packing_size${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Pack/Size"
                                      value={lot.packing_size}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            packing_size: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* 3 */}
                                  <Form.Item
                                    name={`lot_number_from${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Lot Number From"
                                      value={lot.lot_number_from}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            lot_number_from: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* 4 */}
                                  <Form.Item
                                    name={`lot_number_end${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Lot Number To"
                                      value={lot.lot_number_end}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            lot_number_end: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* 5 */}
                                  <Form.Item
                                    name={`lot_code${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Lot Code/Batch Number"
                                      value={lot.lot_code}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            lot_code: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>

                                  {/* Add other left side inputs here */}
                                </Col>

                                {/* Right side (5 columns) */}
                                <Col span={12} style={{ marginBottom: "1rem" }}>
                                  {/* 6 */}
                                  <Form.Item
                                    name={`quantity${index}`}
                                    rules={[
                                      { required: true, message: 'Please select Quantity..!' },
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      placeholder="Total Quantity"
                                      value={lot.quantity}
                                      maxLength={remainingQuantity}
                                      onChange={(e) => {
                                        console.log(e.target.value, remainingQuantity);
                                        const enteredValue = parseInt(e.target.value, 10);

                                        if (remainingQuantity === 0) {
                                          window.alert(`There is no quantity available for this item..!!`);
                                          // Optionally reset the input field or handle as needed
                                          form.setFieldValue(`quantity${index}`, formData.fulfillment.lotwise[index].quantity)
                                        } else if (enteredValue > remainingQuantity) {
                                          window.alert(`Available Quantity is ${remainingQuantity}..! Please enter a value under or equal to ${remainingQuantity}..!`);
                                          // Optionally reset the input field or handle as needed
                                          form.setFieldValue(`quantity${index}`, formData.fulfillment.lotwise[index].quantity)
                                          // form.setFieldValue(`quantity${index}`, remainingQuantity > 0 ? remainingQuantity : 0);
                                        } else {
                                          console.log("justTest", e);
                                          handleFulfillmentChange(
                                            index,
                                            formData.fulfillmentType,
                                            {
                                              ...lot,
                                              quantity: enteredValue,
                                              amount: enteredValue * lot.price,
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </Form.Item>

                                  {/* 7 */}
                                  <Form.Item
                                    name={`price${index}`}
                                  >
                                    <Input
                                      type="number"
                                      placeholder="Rate"
                                      value={lot.price}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            price: e.target.value,
                                            amount: e.target.value * lot.quantity,  // Automatically calculate amount
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>

                                  {/* 8 */}
                                  <Form.Item
                                    name={`amount${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="number"
                                      placeholder="Amount"
                                      value={lot.amount}
                                      disabled
                                    />
                                  </Form.Item>
                                  {/* 9 */}
                                  <Form.Item
                                    name={`amountWord${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Amount in words"
                                      value={numWords(lot.amount)}
                                      disabled
                                    />
                                  </Form.Item>
                                  {/* 10 */}
                                  <Form.Item
                                    name={`scheduledDeliveryDate${index}`}
                                    rules={[
                                      { required: true, message: 'Please select Delivery Date..!' },
                                    ]}
                                  >
                                    <DatePicker
                                      style={{ marginRight: "0.5rem" }}
                                      onChange={(date) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...lot,
                                            scheduledDeliveryDate:
                                              date.$d.toString(),
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* Add other right side inputs here */}
                                </Col>
                                <Input
                                  style={{
                                    marginLeft: "0.5vw",
                                    marginRight: "0.5vw",
                                  }}
                                  type="text"
                                  placeholder="description"
                                  value={lot.description}
                                  onChange={(e) =>
                                    handleFulfillmentChange(
                                      index,
                                      formData.fulfillmentType,
                                      {
                                        ...lot,
                                        description: e.target.value,
                                      }
                                    )
                                  }
                                />
                              </Row>

                              {/* remove button */}
                              <Button
                                type="primary"
                                danger
                                onClick={() => handleRemoveRow(index)}
                                style={{ marginBottom: "1rem" }}
                              >
                                Remove
                              </Button>
                            </div>
                          )
                        )}

                      </Form.Item>
                    </Card>
                  ) : (
                    <Card
                      bordered={true}
                      style={{ border: "1px solid lightgrey" }}
                    >
                      <Form.Item label="Itemwise">
                        <Button type="primary" style={{ marginBottom: "1rem", marginTop: "-1rem", marginLeft: "90%" }} onClick={handleAddRow}>
                          Add Item
                        </Button>
                        {formData.fulfillment[formData.fulfillmentType].map(
                          (item, index) => (
                            <div key={index}>
                              <Row gutter={16}>
                                {/* Left side (4 columns) */}
                                <Col span={12}>
                                  {/* 1 */}
                                  <Form.Item
                                    name={`item_${index}`}
                                    validateFirst
                                    rules={[
                                      { required: true, message: 'Please select Item..!' },
                                    ]}
                                  >
                                    <Select
                                      // style={{ marginBottom: "1rem" }}
                                      defaultValue={item.item || undefined}  // Set defaultValue based on lot.item
                                      options={itemsList}
                                      onChange={(selectedItem) => {
                                        const selectedValue = selectedItem; // Assuming value property contains unique item identifier
                                        console.log(itemsList, selectedValue);
                                        const selectedQuantity = itemsList.find(item => item.value === selectedValue)?.quantity || 0;

                                        console.log("qty", selectedQuantity);
                                        // Update quantity value based on the selected item
                                        setSIQ(selectedQuantity); // Assuming setSIQ is a state updater function

                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...item,
                                            item: selectedItem,
                                          }
                                        );
                                        handlePOSelection(formData.PO_Number, index);
                                      }}
                                    />
                                  </Form.Item>
                                  {/* 2 */}
                                  <Form.Item
                                    name={`itemNumber${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Item Number"
                                      value={item.itemNumber}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...item,
                                            itemNumber: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>

                                  {/* 3 */}
                                  <Form.Item
                                    name={`scheduledDeliveryDate${index}`}
                                    rules={[
                                      { required: true, message: 'Please select Delivery Date.!' },
                                    ]}
                                  >
                                    <DatePicker
                                      style={{ marginRight: "0.5rem" }}
                                      onChange={(date) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...item,
                                            scheduledDeliveryDate:
                                              date.$d.toString(),
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* Add other left side inputs here */}
                                </Col>

                                <Col span={12} style={{ marginBottom: "1rem" }}>
                                  {/* 4 */}
                                  <Form.Item
                                    name={`quantity${index}`}
                                    rules={[
                                      { required: true, message: 'Please select Quantity..!' },
                                    ]}
                                  >

                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="number"
                                      placeholder="Quantity"
                                      value={item.quantity}
                                      maxLength={remainingQuantity}
                                      onChange={(e) => {
                                        const enteredValue = parseInt(e.target.value, 10);

                                        if (remainingQuantity === 0) {
                                          window.alert(`There is no quantity available for this item..!!`);
                                          // Optionally reset the input field or handle as needed
                                          form.setFieldValue(`quantity${index}`, formData.fulfillment.itemwise[index].quantity)
                                        } else if (enteredValue > remainingQuantity) {
                                          window.alert(`Available Quantity is ${remainingQuantity}..! Please enter a value under or equal to ${remainingQuantity}..!`);
                                          // Optionally reset the input field or handle as needed
                                          form.setFieldValue(`quantity${index}`, formData.fulfillment.itemwise[index].quantity)
                                        } else {
                                          console.log("justTest", e);
                                          handleFulfillmentChange(
                                            index,
                                            formData.fulfillmentType,
                                            {
                                              ...item,
                                              quantity: enteredValue,
                                              total: enteredValue * item.unitPrice,
                                            }
                                          );
                                        }
                                      }
                                      }
                                    />
                                  </Form.Item>
                                  {/* 5 */}
                                  <Form.Item
                                    name={`unitPrice${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      style={{ marginBottom: "1rem" }}
                                      type="number"
                                      placeholder="Unit Price"
                                      value={item.unitPrice}
                                      onChange={(e) =>
                                        handleFulfillmentChange(
                                          index,
                                          formData.fulfillmentType,
                                          {
                                            ...item,
                                            unitPrice: e.target.value,
                                            total: e.target.value * item.quantity,
                                          }
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  {/* 6 */}
                                  <Form.Item
                                    name={`total${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="number"
                                      placeholder="Total"
                                      value={item.total}
                                      disabled
                                    />
                                  </Form.Item>
                                  {/* 7 */}
                                  <Form.Item
                                    name={`totalWords${index}`}
                                  // rules={[
                                  //   { required: true, message: 'Please select Item..!' },
                                  // ]}
                                  >
                                    <Input
                                      // style={{ marginBottom: "1rem" }}
                                      type="text"
                                      placeholder="Amount in words"
                                      value={numWords(item.total)}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                                {/* 8 */}
                                <Input
                                  style={{
                                    marginLeft: "0.5vw",
                                    marginRight: "0.5vw",
                                    marginBottom: "1rem"
                                  }}
                                  type="text"
                                  placeholder="description"
                                  value={item.description}
                                  onChange={(e) =>
                                    handleFulfillmentChange(
                                      index,
                                      formData.fulfillmentType,
                                      {
                                        ...item,
                                        description: e.target.value,
                                      }
                                    )
                                  }
                                />
                              </Row>

                              {/* remove button */}
                              <Button
                                type="primary"
                                danger
                                onClick={() => handleRemoveRow(index)}
                                style={{ marginBottom: "1rem", mraginTop: "1rem" }}
                              >
                                Remove
                              </Button>
                            </div>
                          )
                        )}

                      </Form.Item>
                    </Card>
                  )}
                  <Button type="primary" htmlType="submit" style={{ marginTop: '0.5rem' }} loading={loading}>
                    {state?.PO_Number ? "Update" : "Submit"}
                  </Button>
                </Form>
              </div>
            </section>
            {/* po lists table end here */}
          </section>
          {/* Wrapper ends here */}
        </div>
      </main>
    </>
  );
}

export default EndMaterialDS;

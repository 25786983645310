import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Input, Modal, Table, notification, Row, Col, Spin, Divider } from 'antd';
import ls from "localstorage-slim";
import { CloudUploadOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { editUserInfo, geRegisteredUserInfo, geRegisteredUsers, getAllCompanyNAME } from "../../Apis/apis";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { AUTH_TOKEN } from "../../utils/authHeader";

const { Option } = Select;
const departmentSubRoles = [
    { _id: "Sales", subRole: ['manager/dy.manager', 'category lead', 'officer'] },
    { _id: "Production", subRole: ['manager/dy.manager', 'category lead', 'officer'] },
    { _id: "HR", subRole: ['manager/dy.manager', 'category lead', 'officer'] },
    { _id: "Accounts", subRole: ['manager/dy.manager', 'category lead', 'officer'] },
];
const subRole = ['manager/dy.manager', 'category lead', 'officer']
const RegisterVENDOR = () => {
    const [successfulUsers, setSuccessfulUsers] = useState([]);
    const [failedUsers, setFailedUsers] = useState([]);
    const [RegisteredUsers, setRegisteredUsers] = useState([]);
    const [RegisteredUsersInfo, setRegisteredUsersInfo] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formObj, setFormObj] = useState({});
    const [editForm, setEditForm] = useState({});
    const [companyNames, setCompanyNames] = useState([]);
    const [modalAddCompany, setModalAddCompany] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState("");
    const [isEditMode, setIsEditMode] = useState(false); // Step 1
    const [vendorList, setVendorList] = useState([]); // Step 1


    const userRole = ls.get('userRole', { decrypt: true });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadSet, setLoadSet] = useState(false);

    const openNotification = (type, message, description) => {
        notification[type]({
            message,
            description,
        });
    };
    useEffect(() => {
        getCompanies()
    }, [])

    const getCompanies = () => {
        axios.get(getAllCompanyNAME, { headers: { "Authorization": `Bearer ${AUTH_TOKEN()}` } }).then(resp => {
            setCompanyNames(resp.data)
            console.log("who me?", resp.data)
        }).catch(err => {
            console.error(err, "oopsie")
        })
    }

    const onFinish = async (values) => {
        try {
            setLoading(true);

            if (isEditMode) {
                // Handle update logic here
                await axios.put(editUserInfo + editForm._id, values, {
                    headers: {
                        Authorization: "Bearer " + ls.get("token", { decrypt: true }),
                    },
                });
                openNotification('success', 'Update Successful', 'User has been updated successfully.');
            } else {
                // Handle registration logic here
                const response = await axios.post('https://x1cslrnasf.execute-api.ap-south-1.amazonaws.com/dev/api/user/register', values);
                console.log('Registration successful:', response.data);
                openNotification('success', 'Registration Successful', 'User has been registered successfully.');
            }
            form.resetFields();
            setIsEditMode(false)
            fetchUsers()
            getCompanies()
        } catch (error) {
            // Handle error
            console.error('Registration failed:', error);
            openNotification('error', 'Registration Failed', error.response.data.message || 'An error occurred during registration. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchUsers = async () => {
        setLoadSet(true)
        try {
            setRegisteredUsersInfo([])
            const token = ls.get("token", { decrypt: true });
            const response = await axios.get(geRegisteredUsers, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            setRegisteredUsers(response.data.userIds);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = ls.get("token", { decrypt: true });
                const updatedUserInfos = [];

                for (const value of RegisteredUsers) {
                    try {
                        const response = await axios.get(geRegisteredUserInfo + value, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        });
                        if (response.data === null) {
                            continue;
                        }
                        try {
                            const response2 = await axios.get(getAllCompanyNAME + response.data.companyname, {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            });
                            if (response2.data && response2.data.length >  0 && response2.data[0].gstNumber) {
                                response.data.gstNumber = response2.data[0].gstNumber;
                            } else {
                                response.data.gstNumber = "";
                            }
                            updatedUserInfos.push(response.data);
                            setRegisteredUsersInfo(prevUserInfos => [...prevUserInfos, response.data]);
                        } catch (error) {
                            console.error("Error fetching company name:", error);
                            // Optionally, handle the error for the second API call here
                        }
                    } catch (error) {
                        console.error("Error fetching user info:", error);
                        // Optionally, handle the error for the first API call here
                    }
                }
                // Move setLoadSet(false) outside of the loop and after the loop's try-catch block
                setLoadSet(false);
            } catch (error) {
                console.error("Unexpected error:", error);
                // Optionally, handle the error here
                setLoadSet(false); // Ensure to set loadSet to false even if an unexpected error occurs
            }
        };

        fetchUserInfo();
    }, [RegisteredUsers]);





    const csvJSON = (csv) => {
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        return result;
    };

    const downloadSampleCSV = () => {
        const sampleCSV = `username,email,password,confirm_password,role,status,profile,companyname,department,departmentRole,pin,confirmPin\ntarun1,tarun.grabthesite1@gmail.com,one,one,Vendor,Active,HR,tarunInfotech,Production,officer,123456,123456\ntarun3,tarun.grabthesite3@gmail.com,one,one,Buyer,Active,HR,tarunInfotech,Production,officer,123456,123456`;
        const blob = new Blob([sampleCSV], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'sampleVendor.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    function editUser(record) {
        console.log(record);
        delete record.__v;
        delete record.gstNumber;
        delete record.createdAt;

        setOpen(true);
        setFormObj(record)
    }
    const handleInputChange = (e, key) => {
        const { value } = e.target;
        setFormObj(prevData => ({
            ...prevData,
            [key]: value,
        }));
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        await axios.put(editUserInfo + formObj._id, formObj, {
            headers: {
                Authorization: "Bearer " + ls.get("token", { decrypt: true }),
            },
        }).then(async resp => {
            await fetchUsers();
            setOpen(false);
            setConfirmLoading(false);
        }).catch(err => {
            console.error("erredit", err)
            setConfirmLoading(false);
        })
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    const handleAddCompany = () => {
        setConfirmLoading(true);

        setCompanyNames((prevNames) => [...prevNames, { _id: newCompanyName, gstNumber: "" }]);

        form.setFieldValue("companyname", newCompanyName)
        setNewCompanyName('');
        setConfirmLoading(false);
        setModalAddCompany(false);
    };
    const handleEdit = async (record) => {
        try {
            setLoadSet(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setEditForm(record);
            await form.setFieldsValue(record);
            setIsEditMode(true);
        } catch (error) {
            console.error('Error in handleEdit:', error);
        } finally {
            setTimeout(() => {
                setLoadSet(false);
            }, 500)
        }
    };

    const columnsmain = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            fixed: 'left',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Company Name',
            dataIndex: 'companyname',
            key: 'companyname'
        },
        {
            title: 'GST Number',
            dataIndex: 'gstNumber',
            key: 'gstNumber',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'Department Role',
            dataIndex: 'departmentRole',
            key: 'departmentRole',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            // render: (_, record) => <Button onClick={() => editUser(record)}><EditOutlined /></Button>
            render: (_, record) => <Button onClick={() => handleEdit(record)}><EditOutlined /></Button>
        },
    ];
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Error Message',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
        },
    ];

    const renderFormFields = (data, parentKey = '') => {
        return Object.entries(data).map(([key, value]) => {
            const currentKey = parentKey ? `${parentKey}.${key}` : key;

            if (typeof value === 'object' && value !== null) {
                return renderFormFields(value, currentKey);
            }

            return (
                <Form.Item key={currentKey} label={currentKey}>
                    {currentKey === "_id" ? <Input value={value} readOnly /> : <Input value={value} onChange={(e) => handleInputChange(e, currentKey)} />}
                </Form.Item>
            );
        });
    };

    return (
        <main style={{ backgroundColor: "#80" }}>
            {loadSet && (
                <div style={{
                    position: "fixed", // Changed from "absolute" to "fixed"
                    top:  0, // Ensures the overlay starts from the top of the viewport
                    left:  0, // Ensures the overlay starts from the left of the viewport
                    right:  0, // Ensures the overlay extends to the right edge of the viewport
                    bottom:  0, // Ensures the overlay extends to the bottom edge of the viewport
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    height: "100%", // Changed from "100vh" to "100%" to cover the entire viewport
                    zIndex:  100,
                    background: "#00000099"
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize:  24 }} spin />} />
                </div>
            )}
            <div className="flex" style={{ backgroundColor: "#80" }}>
                <Sidebar />

                {/* Wrapper goes here */}
                <section
                    className="dashboard_wrapper"
                    style={{ backgroundColor: "#80", height: "100vh", width: "80vw" }}
                >

                    <Navbar title={'Register Vendor / Buyer'} />
                    <h4 style={{ textAlign: 'center' }}> Register Users </h4>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                        <Form
                            form={form}
                            name="registration"
                            onFinish={onFinish}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            style={{ width: "50vw" }}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="username"
                                        label="Username"
                                        rules={[{ required: true, message: 'Please enter your username!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            { required: true, message: 'Please enter your email!' },
                                            { type: 'email', message: 'Please enter a valid email address!' },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {!isEditMode && <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[{ required: true, message: 'Please enter your password!' }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="confirm_password"
                                        label="Confirm Password"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('The two passwords do not match!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>}


                            {/* Add other form items in pairs for role, status, profile, companyname, department, departmentRole, gstNumber, registrar */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="role"
                                        label="Role"
                                        rules={[{ required: true, message: 'Please select a role!' }]}
                                    >
                                        <Select>
                                            <Option value="Buyer">Buyer</Option>
                                            <Option value="Vendor">Vendor</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[{ required: true, message: 'Please select a status!' }]}
                                    >
                                        <Select>
                                            <Option value="Active">Active</Option>
                                            <Option value="Suspended">Suspended</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="companyname"
                                        label="Company Name"
                                        rules={[{ required: true, message: 'Please enter your company name!' }]}
                                    >
                                        <Select
                                            onChange={(value) => {
                                                if (value === "NEW_COMPANY") {
                                                    setModalAddCompany(true);
                                                } else {
                                                    console.log("recognize me", companyNames.filter((company) => value === company._id));
                                                    const selectedCompany = companyNames.find((company) => value === company._id);

                                                    if (selectedCompany) {
                                                        form.setFieldValue("gstNumber", selectedCompany.gstNumber);
                                                    }
                                                }
                                            }}
                                        >
                                            {companyNames.map((company) => (
                                                <Option key={company._id} value={company._id}>
                                                    {company._id}
                                                </Option>
                                            ))}
                                            <Option value={"NEW_COMPANY"}>+ New Company Name</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="gstNumber"
                                        label="GST Number"
                                        rules={[{ required: true, message: 'Please enter your GST number!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="department"
                                        label="Department"
                                        rules={[{ required: true, message: 'Please select a department!' }]}
                                    >
                                        <Select>
                                            {departmentSubRoles.map((department) => (
                                                <Option key={department._id} value={department._id}>
                                                    {department._id}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="departmentRole"
                                        label="Department Role"
                                        rules={[{ required: true, message: 'Please select a department role!' }]}
                                    >
                                        <Select>
                                            {subRole.map((role) => (
                                                <Option key={role} value={role}>
                                                    {role}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>

                                <Col span={12} style={{ display: "none" }}>
                                    <Form.Item
                                        name="registrar"
                                        label="Registrar"
                                        rules={[{ required: true, message: 'Please enter your registrar!' }]}
                                        initialValue={ls.get("companyname", { decrypt: true })}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    {isEditMode ? 'Update' : 'Register'}
                                </Button>
                                <Button style={{ marginLeft: "1vw" }} type="primary" danger onClick={() => {
                                    form.resetFields()
                                    setIsEditMode(false)
                                }}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table scroll={{ x: 1300 }}
                        style={{ width: "90vw", marginLeft: "auto", marginRight: "auto", marginTop: "12px" }}
                        dataSource={RegisteredUsersInfo} columns={columnsmain} />
                    <Modal
                        title="Edit user"
                        open={open}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Return
                            </Button>,
                            <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                                Submit
                            </Button>]}
                    >
                        <Form layout="horizontal">
                            {renderFormFields(formObj)}
                        </Form>
                    </Modal>
                    <Modal
                        title="Add New Company Name"
                        centered
                        open={modalAddCompany}
                        onCancel={() => setModalAddCompany(false)}
                        footer={[
                            <Button key="back" onClick={() => setModalAddCompany(false)}>
                                Return
                            </Button>,
                            <Button key="submit" type="primary" loading={confirmLoading} onClick={handleAddCompany}>
                                Submit
                            </Button>]}
                    >
                        <Form.Item label="New Company Name">
                            <Input
                                placeholder="Enter new company name"
                                value={newCompanyName}
                                onChange={(e) => setNewCompanyName(e.target.value)}
                            />
                        </Form.Item>
                    </Modal>
                </section>
            </div>
        </main>
    );
};

export default RegisterVENDOR;

import { configureStore } from '@reduxjs/toolkit'
import statusSlice from './slices/statusSlice'
import editItemsData from "./slices/editItemsData"
import dateRangeReducer from "./slices/dateRangeSlice"

export default configureStore({
  reducer: {
    statusSlice,
    editItemsData,
    dateRange: dateRangeReducer,
  },
})
import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import axios from 'axios';
import { AUTH_TOKEN } from '../../utils/authHeader';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import flattenObject from "../../components/hx/FlattenObj";
import ViewEndDS from "./ViewEndDS";
import ViewRawDS from './ViewRawDS';

const List_Raw_Ds = () => {
    const [listData, setListData] = useState([]);
    const [loadSet, setLoadSet] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState([]);

    const columns = [
        {
            title: 'Purchase Number',
            dataIndex: 'PO_Number',
            key: 'PO_Number',
        },
        {
            title: 'Item Name',
            dataIndex: 'items',
            key: 'items',
            render: (items) => items.map(item => item.item),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (items) => items.map(item => item.status),
        },

    ];

    useEffect(() => {
        setLoadSet(true); // Set loading to true before the request

        axios
            .get(
                'https://vo1urbmb5k.execute-api.ap-south-1.amazonaws.com/dev/api/purchaseOrder/getAllPurchaseOrders',
                {
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN()}`,
                    },
                }
            )
            .then((res) => {
                console.log('GET:ALL-P-Order', res);
                setListData(res.data.data);
            })
            .catch((err) => {
                console.error('GET:ALL-P-Order', err);
            })
            .finally(() => {
                setLoadSet(false); // Set loading to false after the request completes
            });
    }, []);


    // useEffect(() => {
    //     if (listData.length > 0) {
    //         const poNumbers = listData.map((po) => po.PO_Number);
    //
    //         const promises = poNumbers.map((poNumber) =>
    //             axios.get(
    //                 `https://22gxiwdppa.execute-api.ap-south-1.amazonaws.com/dev/api/ds/getDeliverySchedule/${poNumber}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${AUTH_TOKEN()}`,
    //                     },
    //                 }
    //             )
    //         );
    //
    //         Promise.allSettled(promises)
    //             .then((results) => {
    //                 const fulfilledResponses = results
    //                     .filter((result) => result.status === 'fulfilled')
    //                     .map((result) => result.value.data);
    //
    //                 const creditNoteData = fulfilledResponses.flat();
    //
    //                 console.log('END DS Data:', flattenObject(creditNoteData[0]));
    //                 setCreditNoteData(creditNoteData);
    //             })
    //             .catch((error) => {
    //                 console.log('Error fetching END DS', error);
    //                 setCreditNoteData([]);
    //             });
    //     }
    // }, [listData]);

    return (
        <>
            <main style={{ backgroundColor: '#80' }}>
                {loadSet && <div style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: "absolute",
                    width: "100%",
                    zIndex: 100,
                    background: "#00000099"
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>}
                <div className="flex" style={{ backgroundColor: '#80' }}>
                    <Sidebar />

                    <section
                        className="dashboard_wrapper"
                        style={{ width: '80%', backgroundColor: '#80' }}
                    >
                        <Navbar title={'End DS / View All End DS'} />

                        <section
                            className="lists_table"
                            style={{
                                background:
                                    'white',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                                height: '80vh',
                                width: '98%',
                                margin: '1rem auto',
                                padding: '1rem',
                            }}
                        >
                            {listData.map((po) => {
                                console.log(po)
                                return <><ViewRawDS PO_Number={po.PO_Number} /><hr /></>
                            })}
                        </section>
                    </section>
                </div>
            </main>
        </>
    );
};

export default List_Raw_Ds;

let flattenObject = (obj, parentKey = '') => {
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(acc, flattenObject(obj[key], newKey));
            console.log("one", acc)
        } else {
            acc[newKey] = obj[key];
        }
        return acc;
    }, {});
};

module.exports = flattenObject;
import React, { useEffect, useState } from 'react';
import { AppstoreOutlined, ExclamationCircleFilled, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import ls from "localstorage-slim";
import { DatePicker, Space, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange, selectDateRange } from '../../app/slices/dateRangeSlice';
import dayjs from "dayjs"; // Import your slice actions and selectors
import logo from "../../Assets/logo.png"

const { confirm } = Modal;
const { RangePicker } = DatePicker;
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}


// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
const Sidebar = () => {
    const [userRole, setUserRole] = useState(ls.get('userRole', { decrypt: true }) || '');
    const [username, setUsername] = useState(ls.get('username', { decrypt: true }) || '');
    const dispatch = useDispatch();
    const getDateRange = useSelector(selectDateRange);

    const [items, setitems] = useState([
        getItem(<Link to="/">Dashboard</Link>, 'sub1', <MailOutlined />),


        getItem('Purchase Orders', 'sub2', <AppstoreOutlined />, [
            getItem(<Link to="/p-order/lists">All PO Orders</Link>, '1'),
            userRole === 'Buyer' && getItem(
                <Link to="/list/RawDs">All Raw Material DS</Link>, '2_1'
            ),
            getItem(<Link to="/ds/raw-material-delivery-schedule">Raw Material Delivery Schedule</Link>, '2_2'),
        ]),

        getItem('Delivery Schedule', 'sub5', <SettingOutlined />, [
            getItem(<Link to="/ds/end-material-delivery-schedule">End Material Delivery Schedule</Link>, '2_2'),
            getItem(<Link to="/list/EndDs">All End Material DS</Link>, '2_3'),
        ]),

        getItem('ASN', 'sub3', <SettingOutlined />, [
            getItem(<Link to="/CreateAsn">Create ASN</Link>, '3'),
            getItem(<Link to="/asn/lists">ASN List</Link>, '4'),
        ]),

        getItem('GRN', 'sub4', <SettingOutlined />, [
            getItem(<Link to="/CreateGRN">Create GRN</Link>, '5'),
            getItem(<Link to="/grn/lists">GRN List</Link>, '6'),
        ]),

        getItem('Credit Notes', 'sub6', <SettingOutlined />, [
            getItem(<Link to="/create/creditnote">Create Credit Note</Link>, '6_1'),
            getItem(<Link to="/list/creditnote">All Credit Notes</Link>, '6_2'),
        ]),

        getItem('Debit Notes', 'sub7', <SettingOutlined />, [
            getItem(<Link to="/create/debitnote">Create Debit Note</Link>, '7_1'),
            getItem(<Link to="/list/debitnote">All Debit Notes</Link>, '7_2'),
        ]),

        getItem('Settings', 'sub8', <SettingOutlined />, [
        ]),
    ]);
    const showPromiseConfirm = (v) => {
        confirm({
            title: 'Date Range Changed?',
            icon: <ExclamationCircleFilled />,
            content: '',
            onOk() {
                ls.set("dateRange", JSON.stringify(v))
                window.location.reload()
            },
            onCancel() {

            },
        });
    };

    useEffect(() => {
        // dispatch(setDateRange(getDateRange));
        console.log("v", getDateRange)
        console.log("oops", dayjs(JSON.parse(getDateRange)[0]), dayjs(JSON.parse(getDateRange)[0]));
    }, [getDateRange, dispatch]);

    const [openKeys, setOpenKeys] = useState(['sub1']);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useEffect(() => {
        const storedUserRole = ls.get('userRole', { decrypt: true });
        console.log(storedUserRole, "storedUserRole", getDateRange)
        setUserRole(storedUserRole || '');
        setUsername(ls.get('username', { decrypt: true }) || '');

        setitems([
            getItem(<Link to="/">
                <div>{userRole} Dashboard</div>
            </Link>, 'sub1', <MailOutlined />),

            getItem('Purchase Orders', 'sub2', <AppstoreOutlined />, [
                getItem(<Link to="/p-order/lists">All PO Orders</Link>, '1'),
                userRole === 'Buyer' && getItem(
                    <Link to="/list/RawDs">All Raw Material DS</Link>, '2_1'
                ),
                getItem(<Link to="/ds/raw-material-delivery-schedule">Raw Material Delivery Schedule</Link>, '2_2'),
            ]),


            getItem('Delivery Schedule', 'sub5', <SettingOutlined />, [
                getItem(<Link to="/ds/end-material-delivery-schedule">End Material Delivery Schedule</Link>, '2_2'),
                getItem(<Link to="/list/EndDs">All End Material DS</Link>, '2_3'),
            ]),

            getItem('ASN', 'sub3', <SettingOutlined />, [
                userRole !== 'Buyer' && getItem(<Link to="/CreateAsn">Create ASN</Link>, '3'),
                getItem(<Link to="/asn/lists">ASN List</Link>, '4'),
            ]),

            getItem('GRN', 'sub4', <SettingOutlined />, [
                userRole !== 'Vendor' && getItem(<Link to="/CreateGRN">Create GRN</Link>, '5'),
                getItem(<Link to="/grn/lists">GRN List</Link>, '6'),
            ]),

            getItem('Credit Notes', 'sub6', <SettingOutlined />, [
                userRole !== 'Buyer' && getItem(<Link to="/create/creditnote">Create Credit Note</Link>, '6_1'),
                getItem(<Link to="/list/creditnote">All Credit Notes</Link>, '6_2'),
            ]),

            getItem('Debit Notes', 'sub7', <SettingOutlined />, [
                userRole !== 'Vendor' && getItem(<Link to="/create/debitnote">Create Debit Note</Link>, '7_1'),
                getItem(<Link to="/list/debitnote">All Debit Notes</Link>, '7_2'),
            ]),
            userRole === 'Superadmin' && getItem( <Link to="/registerVendor">Register Vendor / Buyer</Link>, 'sub12', <MailOutlined />),
        ])
        console.log(userRole)
    }, [ls.get('userRole'), ls.get('username')]);
    return (
        <div style={{ display: "flex", backgroundColor: "white", height: "99vh", flexDirection: "column", width: "20vw", justifyContent: "flex-start" }}>
            <img src={logo} style={{
                width: "8vw",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
            }} />
            <hr />
            <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{
                    width: "100%",
                }}
                items={items}
            />
            <Space direction="vertical" size={12} style={{ marginTop: "auto" }}>
                <RangePicker
                    value={[dayjs(JSON.parse(getDateRange)[0]), dayjs(JSON.parse(getDateRange)[1])]}
                    onChange={(v) => {
                        dispatch(setDateRange(JSON.stringify(v)));
                        console.log("tabduk", v, getDateRange);
                    }}
                    onOpenChange={(state) => {
                        console.log(state)
                        !state && showPromiseConfirm(JSON.parse(getDateRange))
                    }}
                />
                <div style={{ backgroundColor: "white", padding: "0.2rem 1rem 0.2rem 1rem" }}>
                    <span style={{ backgroundColor: "black", color: "white", display: "inline-flex", justifyContent: "center", alignItems: "center", height: "1.4rem", width: "1.4rem" }}>
                        {username[0].toUpperCase()}
                    </span> {username}</div>
            </Space>
        </div>

    );
};
export default Sidebar;
